import React from "react";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const TableContentSum = (props) => {
  const ncfData = props?.ncfData;

  return (
    <>
      <section className="ncf-section">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                {/* <th className="numeration-transact">No.</th> */}
                <th className="ncf-width">Tipo NCF</th>
                <th className="ncf-width">NCF</th>
                <th className="nettotal-width">Neto</th>
                <th className="itbis-width">ITBIS</th>
                <th className="itbis-width">Ley</th>
                <th className="cash-width">Total</th>
              </tr>
            </thead>
            <tbody>
              {ncfData?.map((data, index) => {
                return (
                  <tr
                    className={data?.finaltotal !== 0 ? "" : " red"}
                    value={data?.transact}
                    key={index}
                  >
                    {/* <td className="numeration-transact">
                      {(index + 1)}
                    </td> */}
                    <td className="ncf-width">{data?.serie}</td>
                    <td className="ncf-width">{data?.ncf}</td>
                    <td className="nettotal-width">
                      {formatToDecimal(data?.nettotal)}
                    </td>
                    <td className="itbis-width">
                      {formatToDecimal(data?.tax1)}
                    </td>
                    <td className="itbis-width">
                      {formatToDecimal(data?.tax5)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.finaltotal)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="center">Total</th>
                <th className="ncf-width">
                  {props.total?.ncf}
                </th>
                <th className="nettotal-width">
                  {formatToDecimal(props.total?.nettotal)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.itbis)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.tips)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.finaltotal)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </>
  );
};

export default TableContentSum;
