import { useState } from "react";

const useToggles = () => {
  const [toggles, setToggles] = useState({
    date: false,
    membership: false
  });

  function HandleDateToggle(bool) {
    return setToggles({ ...toggles, date: bool });
  }

  function HandleToggle(name, value) {
    return setToggles({ ...toggles, [name]: value });
  }

  return { toggles, HandleToggle, HandleDateToggle };
};

export default useToggles;
