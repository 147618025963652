import React from "react";
import { formatDate, formatDateTime } from "../utils/formatDate";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";
const TableTransactions = (props) => {
  const transactionData = props?.transactionData;
  
  return (
    <>
      <section className="transactions-section">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th className="numeration-transact center">No.</th>
                <th className="transact-id-width center">Transacción #</th>
                <th className="table-with center">Mesa #</th>
                <th className="opendate-with center">Opendate</th>
                <th className="longdate-with center">Cuando Inicia</th>
                <th className="longdate-with center">Cuando Termina</th>
                <th className="employee-with center">Quién Inicia</th>
                <th className="employee-with center">Quién Cierra</th>
                <th className="total-with right">Net</th>
                <th className="total-with right">Final</th>
              </tr>
            </thead>
            <tbody>
              {transactionData?.map((data, index) => {
                return (
                  <tr
                    onClick={() => {
                      props.HandleSelectedTransact(
                        props?.transactionData,
                        index
                      );
                      props.HandleDetailOrderToggle();
                    }}
                    className={data?.total !== 0 ? "" : "red-values"}
                    value={data?.transact}
                    key={index}
                  >
                    <td className="numeration-transact center">{(index + 1)}</td>
                    <td className="transact-id-width center">{data?.transact}</td>
                    <td className="table-with center">{data?.tablenum}</td>
                    <td className="opendate-with center">
                      {formatDate(data?.opendate)}
                    </td>
                    <td className="longdate-with center">
                      {formatDateTime(data?.timestart?.toLocaleString("en"))}
                    </td>
                    <td className="longdate-with center">
                      {formatDateTime(data?.timeend?.toLocaleString("en"))}
                    </td>
                    <td className="employee-with center">{data?.empname1}</td>
                    <td className="employee-with center">
                      {data?.empname2?.toLocaleString("en")}
                    </td>
                    <td className="total-with right">
                      {formatToDecimal(data?.nettotal)}
                    </td>
                    <td className="total-with right">
                      {formatToDecimal(data?.total)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className="center">Total</th>
                <th className="right">
                  {formatToDecimal(props.total?.nettotal)}
                </th>
                <th className="right">
                  {formatToDecimal(props.total?.final)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </>
  );
};

export default TableTransactions;
