import { useState } from "react";

const useSelectConteo = () => {
  const [select, setSelect] = useState([]);

  //Only accept two selects
  function HandleSelect(id) {
    let localArray = select;
    if (localArray.includes(id)) {
      localArray = localArray.filter((item) => item !== id);
      return setSelect(localArray);
    }

    if (localArray.length > 1) {
      localArray.shift();
    }
    return setSelect((localArray) => [...localArray, id]);
  }

  return { select, HandleSelect };
};

export default useSelectConteo;
