export function getTotal(dataArray) {
  function sumTotal() {
    if (!dataArray) return;
    const total = {
        ncf: 0,
        itbis: 0,
        tips: 0,
        cash: 0,
        cheque: 0,
        card: 0,
        credit: 0,
        nettotal: 0, 
        finaltotal: 0 
    };

    dataArray.forEach((data) => {

      total.ncf += parseInt(data?.ncf);
      total.nettotal += data?.nettotal;
      total.itbis += data?.tax1;
      total.tips += data?.tax5;
      total.cash += data?.efectivo;
      total.cheque += data?.cheque;
      total.card += data?.tarjeta;
      total.credit += data?.credito;
      total.finaltotal += data?.finaltotal;
    });
    return total;
  }

  function availableNcf(initial, actual, final) {
    if(!actual) {
      return final - initial
    }

    return final - actual
  }

  return {
    total: sumTotal(),
    availableNcf
  };
}
