import { useEffect, useState } from "react";

export function useGetTotal(dataArray) {
  const [total, setTotal] = useState({
    subtotal: 0,
    descuento: 0,
    itbis: 0,
    propina: 0,
    total: 0,
  });
  function sumTotal() {
    if (!dataArray) return;
    const total = {
      subtotal: 0,
      descuento: 0,
      itbis: 0,
      propina: 0,
      total: 0,
    };

    dataArray.forEach((data) => {
      total.subtotal += parseFloat(data?.subtotal);
      total.descuento += parseFloat(data?.descuento);
      total.itbis += parseFloat(data?.itbis);
      total.propina += parseFloat(data?.propina);
      total.total += parseFloat(data?.total);
    });

    return total;
  }

  useEffect(() => {
    console.log("Ha cambiado");
    setTotal(sumTotal())
  }, [dataArray]);

  return {
    total
  };
}
