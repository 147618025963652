import { useQuery } from "react-query";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;

function PieSectionsGraph(props) {
  const { data: placesData } = useQuery("placesData", () => {
    return fetch(
      `${BASE_URL}/business/${props.businessId}/branch/${props.selectedBranch}/transactions/summary/places/firstdate/${props.from}/seconddate/${props.to}`
    ).then((res) => {
      return res.json();
    });
  });

  if (!placesData) return [];
  if (!placesData.length > 0) return [];

  const data = {
    labels: placesData ? placesData.map((data) => data?.descript1) : [0],
    datasets: [
      {
        data: placesData ? placesData.map((data) => data?.nettotal) : [0],
        backgroundColor: [
          "#84b6f4",
          "#fdfd96",
          "#77dd77",
          "#ff6961",
          "#fdcae1",
          "#ffda9e",
          "#fcb7af",
          "#b0c2f2",
          "#c5c6c8",
          "#b2e2f2",
        ],
      },
    ],
  };

  const getTotal = () => {
    let total = 0;

    placesData.forEach((data) => {
      total += data?.nettotal;
    });

    return total;
  };

  if (placesData) {
    if (placesData.length > 0) {
      return (
        <div className="pie-chart">
          <h4>
            Ventas por Zona del {dayAndMonthFormat(props.from)} al{" "}
            {dayAndMonthFormat(props.to)}
          </h4>
          <Pie className="chart" data={data} />
          <div className="dataDetail">
            {placesData.map((data, index) => {
              return (
                <div key={index}>
                  <label htmlFor="">{data?.descript1}:</label>
                  <p>{formatToDecimal(placesData[index]?.nettotal)}</p>
                </div>
              );
            })}
            <div className="total-chart">
              <label htmlFor="">Total:</label>
              <p>{formatToDecimal(getTotal())}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="no-data">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
        </div>
      );
    }
  } else {
    return (
      <div className="no-data">
        <FontAwesomeIcon className="icon" icon={faEyeSlash} />
      </div>
    );
  }
}

export default PieSectionsGraph;
