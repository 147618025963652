import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

function PieSectionsGraph({ sectionsData, date }) {
  const data = {
    labels: sectionsData ? sectionsData.map((data) => data?.descript1) : [0],
    datasets: [
      {
        data: sectionsData ? sectionsData.map((data) => data?.nettotal) : [0],
        backgroundColor: [
          "#84b6f4",
          "#fdfd96",
          "#77dd77",
          "#ff6961",
          "#fdcae1",
          "#ffda9e",
          "#fcb7af",
          "#b0c2f2",
          "#c5c6c8",
          "#b2e2f2",
        ],
      },
    ],
  };

  const getTotal = () => {
    let total = 0;

    sectionsData.forEach((data) => {
      total += data?.nettotal;
    });

    return total;
  };

  if (sectionsData) {
    if (sectionsData.length > 0) {
      return (
        <div className="pie-container">
          <div className="pie-chart">
            <h4>
              Ventas por Zona del {dayAndMonthFormat(date?.from)} al{" "}
              {dayAndMonthFormat(date?.to)}
            </h4>
            <Pie className="chart" data={data} />
            <div className="dataDetail">
              {sectionsData.map((data, index) => {
                return (
                  <div key={index}>
                    <label htmlFor="">{data?.descript1}:</label>
                    <p>{formatToDecimal(sectionsData[index]?.nettotal)}</p>
                  </div>
                );
              })}
              <div className="total-chart">
                <label htmlFor="">Total:</label>
                <p>{formatToDecimal(getTotal())}</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pie-container">
          <div className="no-data">
            <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="pie-container">
        <div className="no-data">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
        </div>
      </div>
    );
  }
}

export default PieSectionsGraph;
