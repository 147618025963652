import { useState } from "react";

const useSelectedTransact = (dataArray) => {
  const [selectedTransact, setSelectedTransact] = useState(null);
  const [arrayData, setArrayData] = useState(dataArray);
  const [index, setIndex] = useState(null);

  function HandleSelectedTransact(array, index) {
    setIndex(index);
    setArrayData(array);
    const value = array[index];
    return setSelectedTransact(value);
  }

  function NextTransact() {
    if (!(arrayData.length > index + 1)) return;
    const value = arrayData[index + 1];
    return setSelectedTransact(value), setIndex(index + 1);
  }

  function PreviousTransact() {
    if (index === 0) return;
    const value = arrayData[index - 1];
    return setSelectedTransact(value), setIndex(index - 1);
  }

  return { selectedTransact, HandleSelectedTransact, NextTransact, PreviousTransact };
};

export default useSelectedTransact;
