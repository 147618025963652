import useSelectBranch from "../../hooks/useSelectBranch";
import useSelectBusiness from "../../hooks/useSelectBusiness";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { usersOnlyInventory } from "../../assets/usersAccess";

export const BranchSelect = ({ className, noAddAllBranches }) => {
  const { auth, setAuth } = useAuth();

  const [branchArray, setBranchArray] = useState([]);

  const isMultibusiness = auth?.isMultibusiness;
  const branches = auth?.branches;

  const { HandleSelectBranch } = useSelectBranch();

  const selectedBusiness = isMultibusiness
    ? JSON.parse(auth?.selectedBusiness)
    : null;
    
  const selectedBranch = auth?.selectedBranch;

  function HandleBranchesSelectedUpdate() {
    const temporalArray = [];

    if (usersOnlyInventory.includes(auth.username)) {
      let branchId
      branches?.forEach((branch) => {
        if (branch?.NOMBRE_SUCURSAL.toUpperCase() === auth.username.toUpperCase()) {
          branchId = branch.ID_SUCURSAL
          return temporalArray.push(branch);
        }
      });
      setAuth({...auth, selectedBranch: branchId})
      return setBranchArray(temporalArray);
    }

    if (isMultibusiness) {
      selectedBusiness?.sucursales?.forEach((branch) => {
        if (branch?.ID_SUCURSAL === parseInt(selectedBranch)) {
          return temporalArray.push(branch);
        }
      });

      selectedBusiness?.sucursales?.forEach((branch) => {
        if (branch?.ID_SUCURSAL !== parseInt(selectedBranch)) {
          return temporalArray.push(branch);
        }
      });
      if(!noAddAllBranches) {temporalArray.push({ ID_SUCURSAL: "Todos", NOMBRE_SUCURSAL: "Todos" })}
      
    } else {
      branches?.forEach((branch) => {
        if (branch?.ID_SUCURSAL === parseInt(selectedBranch)) {
          return temporalArray.push(branch);
        }
      });

      branches?.forEach((branch) => {
        if (branch?.ID_SUCURSAL !== parseInt(selectedBranch)) {
          return temporalArray.push(branch);
        }
      });
      if(!noAddAllBranches) {temporalArray.push({ ID_SUCURSAL: "Todos", NOMBRE_SUCURSAL: "Todos" })}
    }

    return setBranchArray(temporalArray);
  }

  

  useEffect(() => {
    HandleBranchesSelectedUpdate();
  }, [auth?.selectedBusiness]);

  if (isMultibusiness) {
    return (
      <select className={className} onChange={HandleSelectBranch}>
        {branchArray?.map((branch, index) => {
          return (
            <option key={index} value={branch?.ID_SUCURSAL}>
              {branch?.NOMBRE_SUCURSAL}
            </option>
          );
        })}
      </select>
    );
  }

  return (
    <select className={className} onChange={HandleSelectBranch}>
      {branchArray?.map((branch, index) => {
        return (
          <option key={index} value={branch?.ID_SUCURSAL}>
            {branch?.NOMBRE_SUCURSAL}
          </option>
        );
      })}
    </select>
  );
};

export const BusinessSelect = ({ className }) => {
  const { auth, setAuth } = useAuth();
  const [businessArray, setBusinessArray] = useState([]);
  const business = auth?.business;
  const selectedBusiness = JSON.parse(auth?.selectedBusiness);

  const { HandleSelectBusiness } = useSelectBusiness();

  function HandleRefreshBranches() {
    return setAuth({ ...auth, branchRefetch: true });
  }

  function HandleBusinessSelectedUpdate() {
    const newOrderBusiness = [];
    newOrderBusiness.push(selectedBusiness);
    business.forEach((b) => {
      if (b?.ID_NEGOCIO !== selectedBusiness?.ID_NEGOCIO) {
        return newOrderBusiness.push(b);
      }
    });
    return setBusinessArray(newOrderBusiness);
  }

  useEffect(() => {
    HandleBusinessSelectedUpdate();
    HandleRefreshBranches();
  }, []);

  return (
    <select className={className} onChange={HandleSelectBusiness}>
      {businessArray?.map((business, index) => {
        return (
          <option key={index} value={JSON.stringify(business)}>
            {business?.NOMBRE_NEGOCIO}
          </option>
        );
      })}
    </select>
  );
};
