
export const formatDate = (currentDate) => {
  const date = new Date(currentDate);
  const month =
    date.getUTCMonth() + 1 > 9
      ? `${date.getUTCMonth() + 1}`
      : `0${date.getUTCMonth() + 1}`;
  const day =
    date.getUTCDate() > 9 ? `${date.getUTCDate()}` : `0${date.getUTCDate()}`;
  return `${day}-${month}-${date.getFullYear()}`;
};

export const formatDateTime = (currentDate) => {
  const date = new Date(currentDate);
  const month =
    date.getUTCMonth() + 1 > 9
      ? `${date.getUTCMonth() + 1}`
      : `0${date.getUTCMonth() + 1}`;
  const day =
    date.getUTCDate() > 9 ? `${date.getUTCDate()}` : `0${date.getUTCDate()}`;
    const hours = date.getUTCHours() > 9 ?  `${date.getUTCHours()}` : `0${date.getUTCHours()}`
    const minutes = date.getMinutes() > 9 ?  `${date.getMinutes()}` : `0${date.getMinutes()}`
    const seconds = date.getSeconds() > 9 ?  `${date.getSeconds()}` : `0${date.getSeconds()}`
  return `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
};