import { useState, useEffect, useRef } from "react";
import Ncf607Form from "./Ncf607Form";
import useAuth from "../../../hooks/useAuth";
import useGetData from "../../../hooks/useGetData";
import * as XLSX from "xlsx";

const Ncf607Index = () => {
  //Here we define the global variables
  const { auth } = useAuth();
  const businessId = auth?.business?.ID_NEGOCIO;
  const businessName = auth?.business?.NOMBRE_NEGOCIO;

  //Defining the ref to use.
  const rncRef = useRef(null);
  const limitRef = useRef(100);
  const tableContentScroll = useRef(null);
  const tableFooterScroll = useRef(null);

  //Defining the ref the states.
  const [selectedBranch, setSelectedBranch] = useState(
    auth?.branches[0]?.ID_SUCURSAL
  );
  const [ncfData, setNcfData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [limit, setLimit] = useState({
    value: limitRef.current,
    placeholder: limitRef.current,
  });
  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });

  //Here the fetch to the database
  //Note: the value of "1", is according to the bussiness's branch
  const { data } = useGetData(
    {
      url: `/business/${businessId}/branch/${selectedBranch}/ncf/getall`
    }
  );

  function HandleSelectBranch(e) {
    const branchId = e.target.value
    return setSelectedBranch(branchId);
  }
  //This function handle the scroll of the table when its overflow.
  const handleTableScroll = (event) => {
    const { scrollTop, scrollLeft } = event.target;
    tableContentScroll.current.scrollTop = scrollTop;
    tableFooterScroll.current.scrollLeft = scrollLeft;
  };

  //This function takes the values of an object, converting it to a new array
  function ObjectToArraysOfValues(array) {
    let newArrayOfValues = [];
    for (let i = 0; i < array.length; i++) {
      newArrayOfValues.push(Object.values(data[i]));
    }
    return newArrayOfValues;
  }

  //The date coming from the database has a diff format, so this function changes the format.
  function formatDate(currentDate, format) {
    const date = new Date(currentDate);
    const day =
      date.getUTCDate() < 9 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;
    const month =
      date.getUTCMonth() + 1 < 9
        ? `0${date.getUTCMonth() + 1}`
        : `${date.getUTCMonth() + 1}`;
    const year = date.getUTCFullYear();
    const hours =
      date.getUTCHours() < 9
        ? `0${date.getUTCHours()}`
        : `${date.getUTCHours()}`;
    const minutes =
      date.getUTCMinutes() < 9
        ? `0${date.getUTCMinutes()}`
        : `${date.getUTCMinutes()}`;
    const seconds =
      date.getUTCSeconds() < 9
        ? `0${date.getUTCSeconds()}`
        : `${date.getUTCSeconds()}`;

    if (format === "yyyy/mm/dd") {
      const newdate = `${year}${month}${day}`;
      return newdate;
    }
    const newdate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return newdate;
  }

  //This function is for generate an excel document
  function generateExcel() {
    const exceldata = [
      [
        "ID",
        "FECHA",
        "ITBIS",
        "SERVICIO",
        "EFECTIVO",
        "TARJETA",
        "OTROS PAGOS",
      ],
      ...ObjectToArraysOfValues(data),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(exceldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    XLSX.writeFile(workbook, `Reporte de Ventas - ${businessName}.xlsx`);
  }

  //This function takes as a parameter to dates, and its take both to calculate the days between.
  function calculateDateDifference(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    const diffMs = Math.abs(d2 - d1);

    const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  //When the user changes the select, this function handles it
  function HandleSelectDateChange(e) {
    const date = new Date();
    const actualDay =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const actualMonth =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;

    const structureDate = (diffDays, limitValue) => {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - parseInt(diffDays));

      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setLimit({ value: limitValue, placeholder: limitValue });
      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    };

    if (e.target.value === "Todos") {
      setDateFilter({
        from: ``,
        to: ``,
      });
      setLimit({ value: 7, placeholder: 7 });
    }

    if (e.target.value === "Hoy") {
      setDateFilter({
        from: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
      setLimit({ value: 1, placeholder: 1 });
    }

    if (e.target.value === "Ayer") {
      const dayDiff = 1;
      structureDate(dayDiff);
    }

    if (e.target.value === "Semana") {
      const dayDiff = 7;
      structureDate(dayDiff);
    }

    if (e.target.value === "Mes") {
      const dayDiff = 30;
      structureDate(dayDiff);
    }

    if (e.target.value === "Año") {
      const dayDiff = 365;
      structureDate(dayDiff);
    }
  }

  //This function is for the limit input and icon.
  //It does two things, check if the value is correct (integer), and refetch the data to the
  //database when the user clicks on the refresh icon.
  function HandleLimitChange() {
    try {
      if (!isNaN(limitRef?.current?.value)) {
        document
          .getElementById("input-limit")
          .classList.remove("limit-input-invalid");

        setDateFilter({
          from: "",
          to: "",
        });

        setLimit({
          value: limitRef?.current?.value ? limitRef?.current?.value : 7,
          placeholder: limitRef?.current?.value ? limitRef?.current?.value : 7,
        });
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
      } else {
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
        document
          .getElementById("input-limit")
          .classList.add("limit-input-invalid");
      }
    } catch (error) {}
  }

  function validFormatDate(date) {
    try {
      const cleanDate = new Date(date.replace("T00:00:00.000Z", ""));
      const day = `${
        cleanDate.getDate() + 1 < 10
          ? `0${cleanDate.getDate() + 1}`
          : cleanDate.getDate() + 1
      } `;
      const month = `${
        cleanDate.getMonth() + 1 < 10
          ? `0${cleanDate.getMonth() + 1}`
          : `${cleanDate.getMonth() + 1}`
      } `;
      const year = cleanDate.getFullYear();
      const newFormatDate = `${year}-${month}-${day}`;
      return newFormatDate;
    } catch (error) {
      console.error("Error in validFormatDate" + error.message);
    }
  }

  //This function is the responsable of display the table'contents on the screen
  //according to the inputs date. Also its change the limit value and placeholder.
  function validDateBasedOnInputsDate(isDateInputChanged) {
    const date = new Date();
    let newNcfData = [];

    if (!isDateInputChanged) {
      return setNcfData(data);
    } else {
      data.forEach((d) => {
        if (
          !(validFormatDate(dateFilter.from) <= validFormatDate(d.opendate))
        ) {
          return;
        }

        if (!(validFormatDate(d.opendate) <= validFormatDate(dateFilter.to)))
          return;

        newNcfData.push(d);
      });

      setLimit({
        value: calculateDateDifference(dateFilter.from, date),
        placeholder: calculateDateDifference(dateFilter.from, date),
      });

      return setNcfData(newNcfData);
    }
  }

  // function ClearRncRef() {
    
  // }

  function HandleFilterByRNC() {
    const rnc = rncRef.current.value;
    if (rnc === "") return setFilters([]);
    // const filterArray = ncfData.filter((data) => {
    //   return data?.rnc === rnc;
    // });
    // return setFilters(filterArray);
  }

  function HandleTypeNcf(e) {
    const type = e.target.value;
    if (type === "1") {
      const filterArray = ncfData.filter((data) => {
        return data?.tipo === 1;
      });
      return setFilters(filterArray);
    } else if (type === "2") {
      const filterArray = ncfData.filter((data) => {
        return data?.tipo === 2;
      });
      return setFilters(filterArray);
    } else if (type === "3") {
      const filterArray = ncfData.filter((data) => {
        return data?.tipo === 3;
      });
      return setFilters(filterArray);
    }

    return setFilters([]);
  }

  function totalDataSum(array) {
    let sum = {
      nettotal: 0,
      efectivo: 0,
      tarjeta: 0,
      cheque: 0,
      credito: 0,
      finaltotal: 0,
      tax1: 0,
      tax5: 0,
    };
    array.forEach((arr) => {
      sum.nettotal += arr?.nettotal;
      sum.efectivo += arr?.efectivo;
      sum.efectivo += arr?.cheque;
      sum.tarjeta += arr?.tarjeta;
      sum.credito += arr?.credito;
      sum.finaltotal += arr?.finaltotal;
      sum.tax1 += arr?.tax1;
      sum.tax5 += arr?.tax5;
    });
    return sum;
  }
  
  useEffect(() => {
    if (filters?.length > 0) {
      setTotalSum(totalDataSum(filters));
    } else {
      setTotalSum(totalDataSum(ncfData));
    }
  }, [ncfData, filters]);

  //This useEffect is for set the data on the state when data has a value on it.
  useEffect(() => {
    if (data === null) return;
    if (dateFilter.from === "" || dateFilter.to === "") {
      validDateBasedOnInputsDate(false);
    } else {
      validDateBasedOnInputsDate(true);
    }
  }, [data, dateFilter]);

  return (
    <>
      <Ncf607Form
        branches={auth?.branches}
        businessName={businessName}
        ncfData={ncfData}
        generateExcel={generateExcel}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        formatDate={formatDate}
        HandleSelectDateChange={HandleSelectDateChange}
        limitRef={limitRef}
        HandleLimitChange={HandleLimitChange}
        tableContentScroll={tableContentScroll}
        tableFooterScroll={tableFooterScroll}
        handleTableScroll={handleTableScroll}
        limit={limit}
        HandleSelectBranch={HandleSelectBranch}
        filters={filters}
        HandleFilterByRNC={HandleFilterByRNC}
        rncRef={rncRef}
        HandleTypeNcf={HandleTypeNcf}
        totalSum={totalSum}
      />
    </>
  );
};

export default Ncf607Index;
