import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useDateFilter from "../../hooks/useDateFilter";
import useGetSalesData from "./hooks/useGetSalesData";
import useGetSectionsData from "./hooks/useGetSectionsData";
import useGetCategoriesData from "./hooks/useGetCategoriesData";
import useToggles from "./hooks/useToggles";
import Filters from "./components/Filters";
import CardInfo from "./components/CardInfo";
import BarSales from "./components/graphs/BarSales";
import PieSections from "./components/graphs/PieSections";
import PieCategory from "./components/graphs/PieCategory";
import PiePayment from "./components/graphs/PiePayments";
import LineClients from "./components/graphs/LineClients";
import { Notification } from "../../components/Notification/Index";
import Mobile from "./components/Mobile";
import useGetPaymentData from "./hooks/useGetPaymentData";
// import useSelectBranch from "../../hooks/useSelectBranch";
import useGetAllSalesData from "./hooks/useGetAllSalesData";
import MembershipStatus from "../../components/MembershipStatus";
import useValidateExpDate from "./hooks/useValidateExpDate";

const Index = () => {
  const { auth } = useAuth();
  const expirationDate = auth?.expiration_date;
  const isMultibusiness = auth?.isMultibusiness;
  const businessName = auth?.business?.NOMBRE_NEGOCIO;

  const { date, HandleDateChange } = useDateFilter("", "");

  const { toggles, HandleToggle, HandleDateToggle } = useToggles();

  const { showMembership, HandleShowMembership } = useValidateExpDate({
    expirationDate,
  });

  const {
    data: salesData,
    loading: salesLoading,
    HandleSearch,
  } = useGetSalesData({
    first_date: date?.from,
    second_date: date?.to,
  });

  const {
    data: allSalesData,
    loading: allSalesLoading,
    HandleSearch: HandleAllSalesSearch,
  } = useGetAllSalesData({
    first_date: date?.from,
    second_date: date?.to,
  });

  const {
    data: sectionsData,
    loading: sectionsLoading,
    HandleSearch: HandleSearchSections,
  } = useGetSectionsData({
    first_date: date?.from,
    second_date: date?.to,
  });

  const {
    data: categoriesData,
    loading: categoriesLoading,
    HandleSearch: HandleSearchCategories,
  } = useGetCategoriesData({
    first_date: date?.from,
    second_date: date?.to,
  });

  const {
    data: paymentsData,
    loading: paymentsLoading,
    HandleSearch: HandleSearchPayment,
  } = useGetPaymentData({
    first_date: date?.from,
    second_date: date?.to,
  });

  const isAllBranches = auth?.selectedBranch === "Todos";

  const switchBetweenData = !isAllBranches ? salesData : allSalesData;

  function HandleSearches() {
    if (!isAllBranches) {
      return (
        HandleSearch(),
        HandleSearchSections(),
        HandleSearchCategories(),
        HandleSearchPayment()
      );
    } else {
      HandleAllSalesSearch();
    }
  }

  function isSomethingLoading() {
    return (
      salesLoading ||
      sectionsLoading ||
      categoriesLoading ||
      paymentsLoading ||
      allSalesLoading
    );
  }

  useEffect(() => {
    HandleSearches();
  }, []);

  return (
    <>
      <div className="background-dashboard"></div>
      {showMembership && (
        <MembershipStatus
          expirationDate={expirationDate}
          HandleToggle={HandleShowMembership}
        />
      )}
      <Notification isActive={isSomethingLoading()} message={"Buscando..."} />
      <section className="home-section">
        <div className="heading">
          <h2>Dashboard's {businessName}</h2>
          <hr />
        </div>
        <Filters
          date={date}
          dateToggle={toggles?.date}
          isMultibusiness={isMultibusiness}
          isAllBranches={isAllBranches}
          HandleDateChange={HandleDateChange}
          HandleSearch={HandleSearches}
          HandleDateToggle={HandleDateToggle}
        />

        <CardInfo salesData={switchBetweenData} date={date} />
        {auth?.selectedBranch !== "Todos" && (
          <>
            <section className="chart-container animation-show-chart">
              <BarSales salesData={salesData} date={date} />
              <PieSections sectionsData={sectionsData} date={date} />
            </section>
            <section className="chart-container animation-show-chart">
              <PieCategory categoriesData={categoriesData} date={date} />
              <LineClients clientsData={salesData} date={date} />
            </section>
            <section className="chart-container animation-show-chart">
              <PiePayment paymentsData={paymentsData} date={date} />
            </section>
          </>
        )}
      </section>
      <Mobile
        businessName={businessName}
        date={date}
        dateToggle={toggles?.date}
        salesData={switchBetweenData}
        isMultibusiness={isMultibusiness}
        HandleDateChange={HandleDateChange}
        HandleDateToggle={HandleDateToggle}
        HandleSearch={HandleSearches}
      />
    </>
  );
};

export default Index;
