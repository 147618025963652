export const getEmployeeWhoStarted = (dataArray) => {
  if(!dataArray) return []
  const newArray = dataArray?.map((data) => {
    return data?.empname1;
  });
  const uniqueValues = [...new Set(newArray)];
  return uniqueValues;
};

export const getEmployeeWhoClosed = (dataArray) => {
  if(!dataArray) return []
  const newArray = dataArray?.map((data) => {
    return data?.empname2;
  });
  const uniqueValues = [...new Set(newArray)];
  return uniqueValues;
};
