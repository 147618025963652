import { useRef, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  BranchSelect,
  BusinessSelect,
} from "../../../../components/Selects/Selects";

const FiltersHeading = (props) => {
  const { auth } = useAuth();
  const businessName = auth?.business?.NOMBRE_NEGOCIO;
  const isMultibusiness = auth?.isMultibusiness;

  const transactRef = useRef(null);

  const [collapse, setCollapse] = useState(true);

  function HandleToggle() {
    return setCollapse(!collapse);
  }

  return (
    <section className="filters-heading">
      <div className="table-heading">
        <div className="filters">
          <h2>Filtros</h2>
          <div>
            <div className="filter date-grid">
              <div>
                <div className="date-filter">
                  <label className="date-label" htmlFor="">
                    Desde:{" "}
                  </label>
                  <input
                    type="date"
                    value={props.date?.from}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "from",
                      });
                    }}
                  />
                </div>
                <div className="date-filter">
                  <label htmlFor="">Hasta: </label>
                  <input
                    type="date"
                    value={props.date?.to}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "to",
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <select
                  onChange={(e) => {
                    props.HandleDateChange(e, {
                      property: "from",
                      isSelect: true,
                    });
                  }}
                >
                  <option value="Sin Filtro">Sin filtro</option>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">Una semana</option>
                  <option value="Mes">Un mes</option>
                  <option value="Año">Un año</option>
                </select>
                <button
                  className="btn-search"
                  onClick={() => {
                    props.HandleSearches();
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
            <div className="clear-filters">
              <label htmlFor="">Limpiar filtros:</label>
              <button className="" onClick={props.clearFilters}></button>
            </div>
          </div>
          <div>
            <div className="filter">
              <div className="search-filter">
                <label htmlFor="">Por Categoría:</label>
                <select
                  ref={props.categoryRef}
                  onChange={props.HandleFilterByCategory}
                  name=""
                  id=""
                >
                  <option value="default">- Select type -</option>
                  {props.categories.map((data, index) => {
                    return (
                      <option key={index} value={data?.category}>
                        {data?.category}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="filter">
                <div className="detail-filter">
                  <label htmlFor="">Por Producto: </label>
                  <select
                  ref={props.selectMethodRef}
                  onChange={props.filterByMethodPayment}
                  name=""
                  id=""
                >
                  <option value="default">- Select type -</option>
                </select>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="business">
          <div className="line"></div>
          {isMultibusiness ? (
            <>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar negocio: </label>
                <BusinessSelect />
              </div>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          ) : (
            <>
              <h2>{businessName}</h2>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          )}

          <div className="">
            <label htmlFor="">
              Total de registros:{" "}
              {props.registerCount ? props.registerCount : 0}
            </label>
          </div>
        </div>
      </div>
      <div className={collapse ? "collapsed-heading" : "uncollapsed-heading"}>
        {collapse ? (
          <FontAwesomeIcon
            icon={faBars}
            className="icon-bars"
            onClick={HandleToggle}
          />
        ) : (
          <>
            <div className="container">
              <div className="filters">
                <div>
                  <div className="filter date-grid">
                    <div>
                      <div className="date-filter">
                        <label className="date-label" htmlFor="">
                          Desde:{" "}
                        </label>
                        <input
                          type="date"
                          value={props.date?.from}
                          onChange={(e) => {
                            props.HandleDateChange(e, {
                              property: "from",
                            });
                          }}
                        />
                      </div>
                      <div className="date-filter">
                        <label htmlFor="">Hasta: </label>
                        <input
                          type="date"
                          value={props.date?.to}
                          onChange={(e) => {
                            props.HandleDateChange(e, {
                              property: "to",
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          props.HandleDateChange(e, {
                            property: "from",
                            isSelect: true,
                          });
                        }}
                      >
                        <option value="Sin Filtro">Sin filtro</option>
                        <option value="Hoy">Hoy</option>
                        <option value="Ayer">Ayer</option>
                        <option value="Semana">Una semana</option>
                        <option value="Mes">Un mes</option>
                        <option value="Año">Un año</option>
                      </select>
                    </div>
                  </div>
                  <div className="clear-filters">
                    <label htmlFor="">Limpiar filtros:</label>
                    <button
                      className="clear-filters"
                      onClick={props.clearFilters}
                    >
                      Limpiar
                    </button>
                  </div>
                </div>
                <div>
                  <div className="filter">
                    <div className="search-filter">
                      <label htmlFor="">Por Tipo de NCF:</label>
                      <input
                        type="text"
                        placeholder="transact #"
                        ref={transactRef}
                      />
                      <select name="" id="">
                        <option value="default">Seleccione NCF</option>
                      </select>
                      {/* <button
                        className="btn-search"
                        onClick={() => {
                          props.HandleTransactId(transactRef);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button> */}
                    </div>
                    <div className="operation-filter">
                      <select ref={props.selectOperationRef} name="" id="">
                        <option value="default">Buscar por: </option>
                        <option value="RNC/CED">RNC o Cédula</option>
                        <option value="Transact">Transact #</option>
                      </select>
                      <input
                        type="text"
                        placeholder="valor"
                        ref={props.inputOperationRef}
                      />
                      <button
                        className="btn-search"
                        onClick={props.HandleFilterByOperation}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="business">
                <div className="line"></div>
                <h2>{businessName}</h2>
                <div className="multiple-branch">
                  <label htmlFor="">Cambiar sucursal: </label>
                  <BranchSelect />
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={HandleToggle}
              icon={faXmark}
              className="icon-xmark"
            />
          </>
        )}
      </div>
    </section>
    // <section className="filters-heading">
    //   <div className="table-heading">
    //     <div className="filters">
    //       <h2>Filtros</h2>
    //       <div>
    //         <div className="filter date-grid">
    //           <div>
    //             <div className="date-filter">
    //               <label className="date-label" htmlFor="">
    //                 Desde:{" "}
    //               </label>
    //               <input
    //                 type="date"
    //                 value={props.date?.from}
    //                 onChange={(e) => {
    //                   props.HandleDateChange(e, {
    //                     property: "from",
    //                   });
    //                 }}
    //               />
    //             </div>
    //             <div className="date-filter">
    //               <label htmlFor="">Hasta: </label>
    //               <input
    //                 type="date"
    //                 value={props.date?.to}
    //                 onChange={(e) => {
    //                   props.HandleDateChange(e, {
    //                     property: "to",
    //                   });
    //                 }}
    //               />
    //             </div>
    //           </div>
    //           <div>
    //             <select
    //               onChange={(e) => {
    //                 props.HandleDateChange(e, {
    //                   property: "from",
    //                   isSelect: true,
    //                 });
    //               }}
    //             >
    //               <option value="Sin Filtro">Sin filtro</option>
    //               <option value="Hoy">Hoy</option>
    //               <option value="Ayer">Ayer</option>
    //               <option value="Semana">Una semana</option>
    //               <option value="Mes">Un mes</option>
    //               <option value="Año">Un año</option>
    //             </select>
    //             <button
    //               className="btn-search"
    //               onClick={props.HandleFilterByOperation}
    //             >
    //               <FontAwesomeIcon icon={faMagnifyingGlass} />
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //       <div>
    //         <div className="filter">
    //           <div className="method-filter">
    //             <label htmlFor="">Metodo de pago: </label>
    //             <select onChange={props.filterByMethodPayment} name="" id="">
    //               <option value="default">- Select type -</option>
    //               {props.methods.map((method, index) => {
    //                 return (
    //                   <option key={index} value={method}>
    //                     {method}
    //                   </option>
    //                 );
    //               })}
    //             </select>
    //             <FontAwesomeIcon
    //               id="refresh-fitler-icon"
    //               className="filter-icon"
    //               icon={faArrowsRotate}
    //               onClick={props.HandleFilterByRNC}
    //             />
    //           </div>
    //         </div>
    //         <div className="filter">
    //           <div className="detail-filter">
    //             <label htmlFor="">Reporte detallado: </label>
    //             <input
    //               id=""
    //               className="eliminar"
    //               type="checkbox"
    //               onChange={props.HandleDetailToggle}
    //               ref={props.productRef}
    //               placeholder={"search"}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="business">
    //       <div className="line"></div>
    //       <h2>{businessName}</h2>
    //       <div className="multiple-branch">
    //         <label htmlFor="">Cambiar sucursal: </label>
    //         <BranchSelect />
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default FiltersHeading;
