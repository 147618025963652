import { useEffect, useState } from "react";
import useAuth from "./useAuth";

const useSelectBusiness = () => {
  const [selectBusiness, setSelectBusiness] = useState(null);
  const { auth, setAuth } = useAuth();

  function HandleSelectBusiness(e) {
    const selectBusiness = e.target.value;
    setSelectBusiness(selectBusiness);
  }

  useEffect(() => {
    if (!selectBusiness) return;
    const selectBusinessJson = JSON.parse(selectBusiness);

    setAuth({
      ...auth,
      selectedBranch: selectBusinessJson?.sucursales[0]?.ID_SUCURSAL,
      selectedBusinessOnlyId: selectBusinessJson.ID_NEGOCIO,
      selectedBusiness: selectBusiness
    });
  }, [selectBusiness]);

  return { selectBusiness, HandleSelectBusiness };
};

export default useSelectBusiness;
