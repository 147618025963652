import { useState, useEffect } from "react";
import useGetProductByCategory from "./useGetProductByCategory";

const useFilters = ({ dataArray, first_date, second_date, categoryRef, clearRefs }) => {
  const [filters, setFilters] = useState([]);

  const {
    data: productByCategory_data,
    loading: productByCategory_loading,
    HandleSearch: HandleSearchCategories,
  } = useGetProductByCategory({
    first_date,
    second_date,
    category: categoryRef?.current?.value
  });

  function HandleFilterByCategory() {
    if(!categoryRef?.current?.value) return
    if (!dataArray) return clearFilters();

    HandleSearchCategories()
  }

  function getCategoryWithProductsFormat() {
    const categories = new Set(productByCategory_data?.rows?.map((data) => data?.category));
    const categoriesWithProducts = [];
    categories.forEach((category) => {
      const products = [];
      let Sum = {
        qty: 0,
        total: 0,
      };
      productByCategory_data?.rows.forEach((data) => {
        if (!(category === data.category)) return;
        products.push(data);
        Sum.qty += data?.cantidad;
        Sum.total += data?.total;
      });
      categoriesWithProducts.push({ category, products, info: Sum });
    });
    return setFilters(categoriesWithProducts);
  }

  function clearFilters() {
    return setFilters([]), clearRefs();
  }

  useEffect(() => {
    getCategoryWithProductsFormat()
  }, [productByCategory_data])

  useEffect(() => {
    clearFilters();
  }, [dataArray]);

  return { filters, loading: productByCategory_loading, HandleFilterByCategory,  clearFilters };
};

export default useFilters;
