import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
// import Navbar from "../Navbar/NavbarIndex";
// import Footer from "../Footer/FooterIndex";
import Sidebar from "../Sidebar/Index";
import { usersOnlyInventory } from "../../assets/usersAccess";

const LayoutIndex = () => {
  
  const { auth } = useAuth();
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if(location.pathname === '/inventory' ) return 
    if(usersOnlyInventory.includes(auth?.username)) return navigate('/inventory')
  }, [location])
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <main className="App">
      <div className={auth !== null ? "main-container" : null}>
        {/* <Navbar /> */}
        {auth !== null ? <Sidebar /> : <></>}
        <Outlet />
        {/* <Footer /> */}
      </div>
    </main>
  );
};

export default LayoutIndex;