import React from "react";
import { fullDateFormat } from "../../../utils/dateFormat/dateFormat";

const ModalDelete = (props) => {
  const type = props.deleteInfo.typeTitle;
  const date = props.deleteInfo.date;
  return (
    <>
      <div className="background"></div>
      <section className="modal">
        <h2>
          Registro de {type} del {fullDateFormat(date, "dd/mm/yyyy")}
        </h2>
        <p>
          Este registro está a punto de ser borrado, de ser así, esta acción no
          podrá ser reversible.
        </p>
        <div className="buttons-container">
          <button
            className="button-18"
            onClick={() => {
              props.HandleToggle("modal", false);
            }}
          >
            Cancelar
          </button>
          <button onClick={props.postDelete} className="button-18 btn-red">
            Eliminar de todos modos
          </button>
        </div>
      </section>
    </>
  );
};

export default ModalDelete;
