import { useEffect, useState } from "react";
import useAuth from "./useAuth";

const useSelectBranch = () => {
  const [selectBranch, setSelectBranch] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { auth, setAuth } = useAuth();

  function HandleSelectBranch(e) {
    const branchId = e.target.value;
    setRefresh(!refresh)
    setSelectBranch(branchId);
  }

  function getFirstBranch() {
    const isMultibusiness = auth?.isMultibusiness;
    if(isMultibusiness) {
      const selectedBusiness = JSON.parse(auth?.selectedBusiness)
      const firstBranchId = selectedBusiness?.sucursales[0]?.ID_SUCURSAL
      return setAuth({...auth, selectBranch: firstBranchId})
    }

    const branches = auth?.branches;
    const firstBranchId = branches[0]?.ID_SUCURSAL
    return setAuth({...auth, selectBranch: firstBranchId})
  }

  useEffect(() => {
    if(!selectBranch) return 
    setAuth({ ...auth, selectedBranch: selectBranch });
  }, [refresh]);

  return { selectBranch, getFirstBranch, HandleSelectBranch };
};

export default useSelectBranch;
