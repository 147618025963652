import React from "react";
import { formatInteger } from "../../../../utils/formatToDecimal/formatInteger";

const TableContentCount = (props) => {
  const ncfCountData = props?.ncfCountData;

  return (
    <>
      <section className="ncf-section">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                {/* <th className="numeration-transact">No.</th> */}
                <th className="ncf-width">No</th>
                <th className="descript-count-with">Descripción</th>
                <th className="serie-count-with">Serie</th>
                <th className="itbis-width">Inicio</th>
                <th className="itbis-width">Actual</th>
                <th className="itbis-width">Fin</th>
                <th className="itbis-width">Disponible</th>
              </tr>
            </thead>
            <tbody>
              {ncfCountData?.map((data, index) => {
                return (
                  <tr
                    className={data?.finaltotal !== 0 ? "" : " red"}
                    key={index}
                  >
                    {/* <td className="numeration-transact">
                      {(index + 1)}
                    </td> */}
                    <td className="ncf-width">{data?.countnum}</td>
                    <td className="descript-count-with">{data?.descript}</td>
                    <td className="serie-count-with">{data?.serie}</td>
                    <td className="values-count-with">
                      {formatInteger(data?.ininum)}
                    </td>
                    <td className="values-count-with">
                      {formatInteger(data?.actualnum)}
                    </td>
                    <td className="values-count-with">
                      {formatInteger(data?.finnum)}
                    </td>
                    <td className="values-count-with">
                      {formatInteger(
                        props.availableNcf(
                          data?.ininum,
                          data?.actualnum,
                          data?.finnum
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
              <tr>
                <th className="center">Total</th>
                <th className="ncf-width">
                  {props.total?.ncf}
                </th>
                <th className="nettotal-width">
                  {formatToDecimal(props.total?.nettotal)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.itbis)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.tips)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.finaltotal)}
                </th>
              </tr>
            </tfoot> */}
          </table>
        </div>
      </section>
    </>
  );
};

export default TableContentCount;
