import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useFilters = ({ dataArray, clearRefs }) => {
  const [filters, setFilters] = useState([]);

  const [selectedTransact, setSelectedTransact] = useState(null);

  const navigate = useNavigate();

  const goToTransact = (transact) =>
    navigate(`/report/transactions/${transact}`);

  function filterByMethodPayment(e) {
    const value = e.target.value;
    if (!dataArray) return clearFilters();

    const filterArray = dataArray.filter((data) => {
      return value === data?.descript;
    });
    return setFilters(filterArray);
  }

  function HandleSelectedTransact(value) {
    return setSelectedTransact(value);
  }

  function clearFilters() {
    return setFilters([]), clearRefs();
  }

  useEffect(() => {
    if(!selectedTransact) return
    goToTransact(selectedTransact)
  }, [selectedTransact])

  useEffect(() => {
    clearFilters();
  }, [dataArray]);

  return {
    filters,
    selectedTransact,
    filterByMethodPayment,
    clearFilters,
    HandleSelectedTransact,
  };
};

export default useFilters;
