import { useEffect, useState } from "react";

const useFilters = ({ dataArray }) => {
  const [filters, setFilters] = useState([]);
  const [match, setMatch] = useState(true)

  function HandleNCFType(e) {
    const type = e.target.value;
    const filteredArray = dataArray?.filter((data) => {
      const ecf = data?.ecf;
      if (ecf.includes(type)) return data;
      if (type === "CC" && ecf === "") return data;
    });

    if(type === "default") return setMatch(true), setFilters([]);
    if (filteredArray.length === 0) return setMatch(false), setFilters([]); //To show the no data available animation

    return setFilters(filteredArray), setMatch(true);
  }

  function clearFilters() {
    setFilters([]);
    setMatch(true)
  }

  return { filters, match, clearFilters, HandleNCFType };
};

export default useFilters;
