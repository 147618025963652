import React from "react";
import { BranchSelect } from "../../../components/Selects/Selects";
import { fullDateFormat } from "../../../utils/dateFormat/dateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

const Menu = (props) => {
  return (
    <section className="menu-section">
      <div className="container">
        <div className="left-side">
          <h2 className="title">Inventario de Productos</h2>
          <div className="actions">
            <h4>Acciones: </h4>
            <button className="button-18" onClick={() => {
              props.HandleToggle("conteo", true)
            }}>Conteo</button>
            <button className="button-18" onClick={() => {
              props.HandleToggle("recepcion", true)
            }}>Recepción</button>
          </div>
        </div>
        <hr className="divisor-line" />
        <div className="right-side">
          <h3>Histórico de Inventario</h3>
          <div className="filters">
            <div>
              <label htmlFor="">Sucursal:</label>
              <BranchSelect className={"select"} />
            </div>
            <div className="dates-container">
              <div className="date">
                <label htmlFor="">Desde:</label>
                <input
                  type="date"
                  className=""
                  value={props.rangeDate?.from}
                  onChange={(e) => {
                    props.HandleRageDateChange(e, {
                      property: "from",
                    });
                  }}
                />
              </div>
              <div className="date">
                <label htmlFor="">Hasta:</label>
                <input
                  type="date"
                  className=""
                  value={props.rangeDate?.to}
                  onChange={(e) => {
                    props.HandleRageDateChange(e, {
                      property: "to",
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table-log">
            <table className="">
              <thead>
                <tr>
                  <td>Sucursal</td>
                  <td>Fecha</td>
                  <td>Conteo</td>
                  <td>Recepción</td>
                </tr>
              </thead>
              <tbody>
                {props.headData.map((data) => {
                  return (
                    <tr key={data.id}>
                      <td>{data.sucursale.NOMBRE_SUCURSAL}</td>
                      <td>{fullDateFormat(data.createdAt, "dd/mm/yyyy")}</td>
                      <td>
                        {data.has_conteo && !props.onlyInventory ? (
                          <>
                            <FontAwesomeIcon
                              className="icon"
                              icon={faEye}
                              onClick={() => {
                                props.HandleVisualizeEdit(data, 'conteo');
                              }}
                            />
                            <FontAwesomeIcon
                              className="icon ml-1"
                              icon={faTrash}
                              onClick={() => {
                                props.HandleToggle("modal_delete", true);
                                props.HandleDelete({
                                  date: data.createdAt,
                                  type: "conteo",
                                  typeTitle: "Conteo",
                                  
                                });
                              }}
                            />
                          </>
                        ) : (
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={data.has_conteo ? true : false}
                          />
                        )}
                      </td>
                      <td>
                        {data.has_recepcion && !props.onlyInventory ? (
                          <>
                            <FontAwesomeIcon
                              className="icon"
                              icon={faEye}
                              onClick={() => {
                                props.HandleVisualizeEdit(data, 'recepcion');
                              }}
                            />
                            <FontAwesomeIcon
                              className="icon ml-1"
                              icon={faTrash}
                              onClick={() => {
                                props.HandleToggle("modal_delete", true);
                                props.HandleDelete({
                                  date: data.createdAt,
                                  type: "recepcion",
                                  typeTitle: "Recepción",
                                });
                              }}
                            />
                          </>
                        ) : (
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={data.has_recepcion ? true : false}
                          />
                        )}
                      </td>
                    </tr>
                  )
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
