import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  BusinessSelect,
  BranchSelect,
} from "../../../components/Selects/Selects";

const Filters = ({
  date,
  dateToggle,
  isMultibusiness,
  isAllBranches,
  HandleDateChange,
  HandleSearch,
  HandleDateToggle,
}) => {
  return (
    <section className="filters-container">
      <div
        className={
          !dateToggle
            ? "filter remove-margin-bottom"
            : "filter add-margin-bottom"
        }
      >
        <label htmlFor="">Filtrar por Fecha</label>
        <FontAwesomeIcon icon={faCalendar} className="icon-calendar" />
        <select
          onChange={(e) => {
            const value = e.target.value;
            if (value === "select-date") return HandleDateToggle(true);

            HandleDateToggle(false);
            HandleDateChange(
              e,
              {
                type: "interval-time",
              },
              HandleSearch
            );
          }}
          name=""
          id=""
        >
          <option value="default">Hoy</option>
          <option value="Ayer">Ayer</option>
          <option value="Este mes">Este mes</option>
          <option value="select-date">Seleccionar fecha</option>
        </select>

        {dateToggle ? (
          <div className="filter-date">
            <div className="from">
              <label htmlFor="">Desde</label>
              <input
                onChange={(e) => {
                  HandleDateChange(e, {
                    property: "from",
                  });
                }}
                type="date"
                value={date?.from}
              />
            </div>
            <div className="to">
              <label htmlFor="">Hasta</label>
              <input
                onChange={(e) => {
                  HandleDateChange(e, {
                    property: "to",
                  });
                }}
                type="date"
                value={date?.to}
              />
            </div>
            <button onClick={HandleSearch} className="icon-btn">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isMultibusiness ? (
        <>
          <div className="filter">
            <label htmlFor="">Filtrar por Negocio</label>
            <BusinessSelect />
          </div>
          <div className="filter">
            <label htmlFor="">Filtrar por Sucursal</label>
            <BranchSelect />
          </div>
        </>
      ) : (
        <div className="filter">
          <label htmlFor="">Filtrar por Sucursal</label>
          <BranchSelect />
        </div>
      )}
      {!dateToggle && (
        <button onClick={HandleSearch} className="filter-search-btn">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
      )}
    </section>
  );
};

export default Filters;
