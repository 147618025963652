import React, { useEffect } from "react";
import {
  dayAndMonthFormat,
  fullDateFormat,
} from "../../../../utils/dateFormat/dateFormat";
import useGetInventorySummary from "../hooks/useGetInventorySummary";
import useGetArticles from "../hooks/useGetArticles";
import useGetInventoryConsumeSummary from "../../InventoryConsume/hooks/useGetInventoryConsumeSummary";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const VariacionForm = ({ select, HandleToggle }) => {
  const dateA = new Date(
    select[0].createdAt < select[1].createdAt
      ? select[0].createdAt
      : select[1].createdAt
  );

  const dateAafterOneDay = new Date(dateA);
  dateAafterOneDay.setDate(dateA.getDate() + 1);
  const dateB = new Date(
    select[0].createdAt > select[1].createdAt
      ? select[0].createdAt
      : select[1].createdAt
  );

  const { data: conteoA, HandleSearch: HandleSearchA } = useGetInventorySummary(
    {
      first_date: fullDateFormat(dateA),
      second_date: fullDateFormat(dateA),
    }
  );

  const { data: recepcionAToB, HandleSearch: HandleSearchRecepcionAtoB } =
    useGetInventorySummary({
      first_date: fullDateFormat(dateAafterOneDay),
      second_date: fullDateFormat(dateB),
    });

  const { data: consumeAToB, HandleSearch: HandleSearchConsumeAtoB } =
    useGetInventoryConsumeSummary({
      first_date: fullDateFormat(dateAafterOneDay),
      second_date: fullDateFormat(dateB),
    });

  const { data: conteoB, HandleSearch: HandleSearchB } = useGetInventorySummary(
    {
      first_date: fullDateFormat(dateB),
      second_date: fullDateFormat(dateB),
    }
  );

  const {
    modifiedData: modifiedDataArticles,
    HandleSearch: HandleSearchArticles,
  } = useGetArticles({ conteoA, recepcionAToB, consumeAToB, conteoB });

  const getCostTotal = () => {
    let result = 0;
    modifiedDataArticles.forEach((element) => {
      result += element.conteoFinal * element.costperu;
    });
    return result;
  };

  const getCostDiff = () => {
    let result = 0;
    modifiedDataArticles.forEach((element) => {
      result +=
        element.costperu * (element.conteoFinal - element.inventarioTeorico);
    });
    return result;
  };

  useEffect(() => {
    HandleSearchB();
    HandleSearchA();
    HandleSearchRecepcionAtoB();
    HandleSearchArticles();
    HandleSearchConsumeAtoB();
  }, [select]);

  return (
    <section className="variacion-container">
      <div className="title-section">
        <button
          className="button-18"
          onClick={() => {
            HandleToggle("variacion", false);
          }}
        >
          Volver
        </button>
        <h2>
          Variación del {fullDateFormat(dateA)} al {fullDateFormat(dateB)}
        </h2>
      </div>
      <div className="table-log">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>{fullDateFormat(dateA, "dd/mm/yyyy")}</th>
              <th>
                {dayAndMonthFormat(dateAafterOneDay)} al{" "}
                {dayAndMonthFormat(dateB)}
              </th>
              <th>
                {dayAndMonthFormat(dateAafterOneDay)} al{" "}
                {dayAndMonthFormat(dateB)}
              </th>
              <th></th>
              <th>{fullDateFormat(dateB, "dd/mm/yyyy")}</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th className="">Descripción</th>
              {/* <th>Invennum</th> */}
              <th>Unidad</th>
              <th className="">Costo</th>
              <th className="min-w-date">Conteo Inicial</th>
              <th className="min-w-date">Recepción</th>
              <th className="min-w-date">Consumo</th>
              <th>Inv. Teórico</th>
              <th className="min-w-date">Conteo Final</th>
              <th>Diff</th>
              <th>Costo Total</th>
              <th>Costo Diff</th>
            </tr>
          </thead>
          <tbody>
            {modifiedDataArticles.map((element) => {
              return (
                <tr key={element.id}>
                  <td className="descript">{element.descript}</td>
                  {/* <td>{element.invennum}</td> */}
                  <td>{element.unitdes}</td>
                  <td>{element.costperu}</td>
                  <td>{element.conteoInicial}</td>
                  <td>{element.recepcion}</td>
                  <td>{element.consumo}</td>
                  <td className={element.inventarioTeorico >= 0 ? "" : "red"}>
                    {element.inventarioTeorico}
                  </td>
                  <td className={element.conteoFinal >= 0 ? "" : "red"}>
                    {element.conteoFinal}
                  </td>
                  <td>
                    <p
                      className={
                        element.conteoFinal - element.inventarioTeorico >= 0
                          ? ""
                          : "red"
                      }
                    >
                      {element.conteoFinal - element.inventarioTeorico}
                    </p>
                  </td>
                  <td>
                    <p
                      className={
                        element.conteoFinal * element.costperu >= 0 ? "" : "red"
                      }
                    >
                      {formatToDecimal(element.conteoFinal * element.costperu)}
                    </p>
                  </td>
                  <td>
                    <p
                      className={
                        element.costperu *
                          (element.conteoFinal - element.inventarioTeorico) >=
                        0
                          ? ""
                          : "red"
                      }
                    >
                      {formatToDecimal(
                        element.costperu *
                          (element.conteoFinal - element.inventarioTeorico)
                      )}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total: </td>
              <td></td>
              <td>
                <p className={getCostTotal() > 0 ? "" : "red"}>
                {formatToDecimal(getCostTotal())}
                </p>
              </td>
              <td >
                <p className={getCostDiff() > 0 ? "" : "red"}>{formatToDecimal(getCostDiff())}</p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  );
};

export default VariacionForm;
