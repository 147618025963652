import useGetData from "../../../../hooks/useGetData";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import useAuth from "../../../../hooks/useAuth";

const useGetNcfByTransact = (inputRef) => {
  const { auth } = useAuth();
  
  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  
  const [transactId, setTransactId] = useState(null)

  const isNullValue = transactId === null || transactId === "" ? true : false

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/ncf/transact/${transactId}`;

  const { data, loading, ResetValue } = useGetData({
    url: endpoint,
    isNullValue: isNullValue
  });

  function clearTransact() {
    return setTransactId(null)
  }
  
  function HandleTransactId() {
    const value = inputRef.current.value
    if (value === "") return toast.error("Transacción inválida")
    return setTransactId(value)
  }

  useEffect(() => {
    if(data?.nothingFound) {
      toast.error("Transacción no encontrada...")
    }
  }, [data])

  return { data: data?.ncf607, loading, ResetValue, clearTransact, HandleTransactId };
};

export default useGetNcfByTransact;
