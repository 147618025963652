import { useState } from "react";

const useToggles = () => {
  const [toggles, setToggles] = useState({
    summary: false,
    countbook: false,
  });

  function HandleSummaryToggle() {
    return setToggles({ countbook: false, summary: !toggles?.summary });
  }

  function HandleCountbookToggle() {
    return setToggles({ summary: false , countbook: !toggles?.countbook });
  }

  function disableSummaryToggle() {
    if (!toggles?.summary) return;

    return setToggles({ ...toggles, summary: false });
  }

  return { toggles, disableSummaryToggle, HandleSummaryToggle, HandleCountbookToggle };
};

export default useToggles;
