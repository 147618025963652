import React, { useEffect, useState } from "react";

export const Notification = ({ message, isActive }) => {
  const [active, setActive] = useState(true);

  const notificationAnimation = {
    animation: "show-notification .2s linear forwards",
  };

  useEffect(() => {
    if (isActive) return setActive(true);

    setTimeout(() => {
      setActive(false);
    }, 1000);
  }, [isActive]);

  if (active) {
    return (
      <div className="notification" style={notificationAnimation}>
        <span className="loader"></span>
        <p>{message}</p>
      </div>
    );
  }
  
  return <></>;
};
