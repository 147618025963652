import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const TransactionsForm = (props) => {
  return (
    <section className="transaction-section">
      <div className="container">
        <div className="table-heading">
          <div className="filters">
            <h2>Filtros</h2>
            <div>
              <div className="filter date-grid">
                <div>
                  <div className="date-filter">
                    <label className="date-label" htmlFor="">
                      Desde:{" "}
                    </label>
                    <input
                      type="date"
                      value={props.dateFilter.from}
                      onChange={(e) => {
                        props.setDateFilter({
                          ...props.dateFilter,
                          from: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="date-filter">
                    <label htmlFor="">Hasta: </label>
                    <input
                      type="date"
                      value={props.dateFilter.to}
                      onChange={(e) => {
                        props.setDateFilter({
                          ...props.dateFilter,
                          to: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <select onChange={props.HandleSelectDateChange}>
                  <option value="Todos">Sin filtro</option>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">A semana</option>
                  <option value="Mes">A mes</option>
                  <option value="Año">A año</option>
                </select>
              </div>
              <div className="filter">
                <div className="limit-filter">
                  <label htmlFor="">Límite: </label>
                  <input
                    id="input-limit"
                    className="limit-input"
                    type="text"
                    ref={props.limitRef}
                    placeholder={props.limit.placeholder}
                  />
                  <FontAwesomeIcon
                    id="refresh-icon"
                    className="refresh-icon"
                    icon={faArrowsRotate}
                    onClick={props.HandleLimitChange}
                  />
                </div>
              </div>
              <div className="filter operations-grid">
                <div className="operations-filter">
                  <select ref={props.selectOperationRef} name="" id="">
                    <option value="default">Seleccione operación</option>
                    <option value="greaterThan">Mayor que</option>
                    <option value="lessThan">Menor que</option>
                    <option value="equalTo">Igual que</option>
                  </select>
                  <input
                    type="text"
                    ref={props.operationRef}
                    placeholder="0.00"
                  />
                </div>
                {/* <div className="operations-filter">
                  <div>
                    <label htmlFor="">Mayor que: </label>
                    <input
                      id="input-limit"
                      className="filter-input"
                      type="text"
                      ref={props.greaterThanRef}
                      placeholder={"0.00"}
                    />
                  </div>
                  <div>
                    <label htmlFor="">Menor que: </label>
                    <input
                      id="input-limit"
                      className="filter-input"
                      type="text"
                      ref={props.lessThanRef}
                      placeholder={"0.00"}
                    />
                  </div>
                  <div>
                    <label className="label-mid-with" htmlFor="">
                      Igual que:{" "}
                    </label>
                    <input
                      id="input-limit"
                      className="filter-input"
                      type="text"
                      ref={props.equalToRef}
                      placeholder={"0.00"}
                    />
                  </div>
                </div> */}
                <FontAwesomeIcon
                  id="refresh-fitler-icon"
                  className="operation-icon"
                  onClick={props.HandleOperations}
                  icon={faArrowsRotate}
                />
              </div>
            </div>
            <div>
              <div className="filter">
                <div className="transact-filter">
                  <label htmlFor="">Filtrar por transacción: </label>
                  <input
                    id="input-limit"
                    className="filter-input"
                    type="text"
                    ref={props.transactRef}
                    placeholder={"transact #"}
                  />
                  <FontAwesomeIcon
                    id="refresh-fitler-icon"
                    className="filter-icon"
                    icon={faArrowsRotate}
                    onClick={props.HandleFilterChange}
                  />
                </div>
              </div>
              <div className="filter employee-grid">
                <div>
                  <div className="employee-filter">
                    <label htmlFor="">Quién inició: </label>
                    <select
                      ref={props.whoStartedRef}
                      onChange={props.HandleWhoStarted}
                      className="select-employee"
                      name=""
                      id=""
                    >
                      <option value="default">- Seleccionar empleado -</option>
                      {props
                        ?.GetCurrentsEmployee()
                        ?.whoStarted?.map((employee, index) => {
                          return (
                            <option key={index} value={employee}>
                              {employee}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="employee-filter">
                    <label htmlFor="">Quién cerró: </label>
                    <select
                      ref={props.whoClosedRef}
                      onChange={props.HandleWhoClosed}
                      className="select-employee"
                      name=""
                      id=""
                    >
                      <option value="default">- Seleccionar empleado -</option>
                      {props
                        ?.GetCurrentsEmployee()
                        ?.whoClosed?.map((employee, index) => {
                          return (
                            <option key={index} value={employee}>
                              {employee}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <button onClick={props.ClearFilters} className="">
                  Limpiar filtros
                </button>
              </div>
            </div>
          </div>
          <div className="business">
            <div className="line"></div>
            <h2>{props.businessName}</h2>
            <div className="multiple-branch">
              <label htmlFor="">Cambiar sucursal: </label>
              <select onChange={props.HandleSelectBranch} name="" id="">
                {props?.branches?.map((branch, index) => {
                  return (
                    <option key={index} value={branch?.ID_SUCURSAL}>
                      {branch?.NOMBRE_SUCURSAL}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {props.transactData.length !== 0 &&
        !props.filterTransact?.noDataFound ? (
          <>
            <div
              ref={props.tableContentScroll}
              onScroll={props.handleTableScroll}
              className="table-content"
            >
              <table>
                <thead>
                  <tr>
                    <th className="short-width center">Transacción #</th>
                    <th className="short-width center">Mesa #</th>
                    <th className="mid-width center">Opendate</th>
                    <th className="mid-width center">Cuando Inicia</th>
                    <th className="mid-width center">Cuando Termina</th>
                    <th className="mid-width center">Quién Inicia</th>
                    <th className="mid-width center">Quién Cierra</th>
                    <th className="values-width right">Net</th>
                    <th className="values-width right">Final</th>
                  </tr>
                </thead>
                <tbody>
                  {props.filterTransact.data.length === 0
                    ? props?.transactData?.map((data, index) => {
                        return (
                          <tr
                            onClick={() => {
                              props.HandleTransaction({
                                indexArray: index,
                              });
                              props.ToggleOrderDetailModal(true);
                            }}
                            className={
                              data?.total !== 0 ? "" : " red"
                            }
                            key={index}
                          >
                            <td className="short-width center">
                              {data?.transact}
                            </td>
                            <td className="short-width center">
                              {data?.tablenum}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(data?.opendate)}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(
                                data?.timestart?.toLocaleString("en")
                              )}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(
                                data?.timeend?.toLocaleString("en")
                              )}
                            </td>
                            <td className="mid-width center">
                              {data?.empname1}
                            </td>
                            <td className="mid-width center">
                              {data?.empname2?.toLocaleString("en")}
                            </td>
                            <td className="values-width right">
                              {data?.nettotal?.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.total?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })
                    : props?.filterTransact.data?.map((data, index) => {
                        if (data.length === 0) return <></>;
                        return (
                          <tr
                            onClick={() => {
                              props.HandleTransaction({
                                indexArray: index,
                              });
                              props.ToggleOrderDetailModal(true);
                            }}
                            className={
                              data?.total !== 0 ? "" : "red"
                            }
                            key={index}
                          >
                            <td className="short-width center">
                              {data?.transact}
                            </td>
                            <td className="short-width center">
                              {data?.tablenum}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(data?.opendate)}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(
                                data?.timestart?.toLocaleString("en")
                              )}
                            </td>
                            <td className="mid-width center">
                              {props.formatDate(
                                data?.timeend?.toLocaleString("en")
                              )}
                            </td>
                            <td className="mid-width center">{data?.empname1}</td>
                            <td className="mid-width center">
                              {data?.empname2?.toLocaleString("en")}
                            </td>
                            <td className="values-width right">
                              {data?.nettotal?.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.total?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
                <tfoot>
                  <tr>
                    <th className=""></th>
                    <th className=""></th>
                    <th className=""></th>
                    <th className=""></th>
                    <th className=""></th>
                    <th className=""></th>
                    <th className="center">Total</th>
                    <th className="values-width right">
                      {props.totalSum?.netsales.toFixed(2)}
                    </th>
                    <th className="values-width right">
                      {props.totalSum?.totalsales.toFixed(2)}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* <div ref={props.tableFooterScroll} className="total-table">
              <h4>Total</h4>
              <h4>Venta: {props.totalSum?.totalsales?.toLocaleString("en")}</h4>
            </div> */}
          </>
        ) : (
          <div className="nothing-to-show">
            <FontAwesomeIcon icon={faEyeSlash} className="icon" />
            <h4>Nada por mostrar</h4>
          </div>
        )}
      </div>
    </section>
  );
};

export default TransactionsForm;
