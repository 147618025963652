export function getTotal(dataArray) {
  function sumTotal() {
    if(!dataArray) return
    const total = { tender: 0, cambio: 0 };
    dataArray.forEach((data) => { 
      total.tender += data?.tender;
      total.cambio += data?.cambio;
    });
    return total
  }

  return {
    total: sumTotal()
  };
}
