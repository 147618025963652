import React, { useEffect, useState, useRef } from "react";
import FiltersHeading from "./components/FiltersHeading";
import TablePayments from "./TablePayments";
import TableGroupPayments from "./TableGroupPayments";
import Pagination from "../../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../hooks/useAuth";
import useGetPayments from "./hooks/useGetPayments";
import useGetPaymentsTotal from "./hooks/useGetPaymentsTotal";
import useGetGroupPayments from "./hooks/useGetGroupPayments";
import useDateFilter from "../../../hooks/useDateFilter";
import useToggles from "./hooks/useToggles";
import useFilters from "./hooks/useFilters";
import { getPaymentMethods } from "./utils/getPaymentMethods";
import { getTotal } from "./utils/getTotal";

const Index = () => {
  const { auth } = useAuth();
  const selectMethodRef = useRef(null);
  const [resetPage, setResetPage] = useState(false);

  const limit = 300;

  function clearRefs() {
    return (selectMethodRef.current.value = "default");
  }

  const { date, HandleDateChange } = useDateFilter("", "");

  const {
    data: paymentData,
    loading: loadingPayment,
    HandleSearch: HandleSearchPayment,
    HandlePage,
  } = useGetPayments({
    first_date: date.from,
    second_date: date.to,
    limit,
  });

  const { data: paymentTotal, HandleSearch: HandleTotalSearch } =
    useGetPaymentsTotal({
      first_date: date.from,
      second_date: date.to,
    });

  const {
    data: paymentSummaryData,
    loading: loadingGroupPayment,
    HandleSearch: HandleGroupSearch,
    HandlePage: HandleSummaryPage,
  } = useGetGroupPayments({
    first_date: date.from,
    second_date: date.to,
    limit,
  });

  const { toggles, HandleDetailToggle } = useToggles();

  const switchBetweenData = toggles?.detail
    ? paymentData?.rows
    : paymentSummaryData;

  const {
    filters,
    selectedTransact,
    clearFilters,
    filterByMethodPayment,
    HandleSelectedTransact,
  } = useFilters({
    dataArray: paymentData?.rows,
    clearRefs,
  });

  const filterHasData = filters.length > 0 ? filters : switchBetweenData;

  const methods = getPaymentMethods(paymentData?.rows);

  const totalItemsCount = toggles?.detail
    ? paymentData?.count
    : paymentSummaryData?.length;

  const hasData = filterHasData?.length > 0;
  const isSomethingLoading = loadingPayment || loadingGroupPayment;

  const manageTotal = filters.length > 0 ? filterHasData : paymentTotal;

  const { total } = getTotal(manageTotal);

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  function HandleSearches() {
    return (
      HandleSearchPayment(),
      HandleGroupSearch(),
      HandleTotalSearch(),
      HandleResetPage()
    );
  }

  function HandleAllPages(e) {
    return toggles?.detail ? HandlePage(e) : HandleSummaryPage(e);
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
  }, [auth]);

  return (
    <section className="sales-summary-section">
      <FiltersHeading
        date={date}
        methods={methods}
        selectMethodRef={selectMethodRef}
        registerCount={
          filterHasData?.length > 0 ? totalItemsCount : filterHasData?.length
        }
        clearFilters={clearFilters}
        filterByMethodPayment={filterByMethodPayment}
        HandleDateChange={HandleDateChange}
        HandleDetailToggle={HandleDetailToggle}
        HandleSearches={HandleSearches}
      />
      {toggles?.detail && !isSomethingLoading && hasData && (
        <TablePayments
          paymentData={filters.length > 0 ? filters : paymentData?.rows}
          totalTender={total?.tender}
          totalCambio={total?.cambio}
          selectedTransact={selectedTransact}
          HandleSelectedTransact={HandleSelectedTransact}
        />
      )}

      {!toggles?.detail && !isSomethingLoading && hasData && (
        <TableGroupPayments
          paymentSummaryData={filters.length > 0 ? filters : paymentSummaryData}
          totalTender={total?.tender}
          totalCambio={total?.cambio}
        />
      )}

      <Pagination
        itemsCount={totalItemsCount}
        itemsPerPage={limit}
        loading={isSomethingLoading}
        hasData={hasData}
        resetPage={resetPage}
        HandlePage={HandleAllPages}
      />

      {isSomethingLoading && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}

      {!isSomethingLoading && !hasData && (
        <div className="nothing-to-show">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          <h4>Nada por mostrar</h4>
        </div>
      )}
    </section>
  );
};

export default Index;
