import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { formatToDecimal } from "../../../utils/formatToDecimal/formatToDecimal"

const Ncf607Form = (props) => {
  return (
    <section className="ncf607-section">
      <div className="container">
        <div className="table-heading">
          <div className="filters">
            <h2>Filtros</h2>
            <div>
              <div className="filter date-grid">
                <div>
                  <div className="date-filter">
                    <label className="date-label" htmlFor="">
                      Desde:{" "}
                    </label>
                    <input
                      type="date"
                      value={props.dateFilter.from}
                      onChange={(e) => {
                        props.setDateFilter({
                          ...props.dateFilter,
                          from: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="date-filter">
                    <label htmlFor="">Hasta: </label>
                    <input
                      type="date"
                      value={props.dateFilter.to}
                      onChange={(e) => {
                        props.setDateFilter({
                          ...props.dateFilter,
                          to: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <select onChange={props.HandleSelectDateChange}>
                  <option value="Todos">Sin filtro</option>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">A semana</option>
                  <option value="Mes">A mes</option>
                  <option value="Año">A año</option>
                </select>
              </div>
              <div className="filter">
                <div className="rnc-filter">
                  <label htmlFor="">Filtrar por RNC: </label>
                  <input
                    id="input-limit"
                    className="filter-input"
                    type="text"
                    ref={props.rncRef}
                    placeholder={"search"}
                  />
                  <FontAwesomeIcon
                    id="refresh-fitler-icon"
                    className="filter-icon"
                    icon={faArrowsRotate}
                    onClick={props.HandleFilterByRNC}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="filter">
                <div className="type-filter">
                  <label htmlFor="">Tipo de NCF: </label>
                  <select onChange={props.HandleTypeNcf} name="" id="">
                  <option value="default">- Select type -</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
                  <FontAwesomeIcon
                    id="refresh-fitler-icon"
                    className="filter-icon"
                    icon={faArrowsRotate}
                    onClick={props.HandleFilterByRNC}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="business">
            <div className="line"></div>
            <h2>{props.businessName}</h2>
            <div className="multiple-branch">
              <label htmlFor="">Cambiar sucursal: </label>
              <select onChange={props.HandleSelectBranch} name="" id="">
                {props?.branches?.map((branch, index) => {
                  return (
                    <option key={index} value={branch?.ID_SUCURSAL}>
                      {branch?.NOMBRE_SUCURSAL}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {props.ncfData.length !== 0 ? (
          <>
            <div
              ref={props.tableContentScroll}
              onScroll={props.handleTableScroll}
              className="table-content"
            >
              <table>
                <thead>
                  <tr>
                    <th className="short-width center">RNC - Ced</th>
                    <th className="type-width center">Tipo</th>
                    <th className="ncf-width center">NCF</th>
                    <th className="ncf-width center">NCF Ref</th>
                    <th className="date-width center">F. Comp</th>
                    <th className="values-width right">Neto</th>
                    <th className="values-width right">ITBIS</th>
                    <th className="values-width right">Ley</th>
                    <th className="values-width right">Efectivo</th>
                    <th className="values-width right">Cheque</th>
                    <th className="values-width right">TC o ATH</th>
                    <th className="values-width right">Credito</th>
                    <th className="values-width right">Total</th>
                    <th className="right">Transact</th>
                    <th className="center">RefComp</th>
                  </tr>
                </thead>
                <tbody>
                  {props.filters.length === 0
                    ? props?.ncfData?.map((data, index) => {
                        return (
                          <tr
                            onClick={() => {
                              //   props.ToggleOrderDetailModal(true);
                            }}
                            className={
                              data?.total !== 0 ? "" : "red"
                            }
                            key={index}
                          >
                            <td className="short-width center">{data?.rnc}</td>
                            <td className="type-width center">{data?.tipo}</td>
                            <td className="ncf-width center">{data?.ncf}</td>
                            <td className="ncf-width center">{data?.ncfref}</td>
                            <td className="date-width center">
                              {props.formatDate(data?.opendate, "yyyy/mm/dd")}
                            </td>
                            <td className="values-width right">
                              {data?.nettotal.toFixed(2)}
                            </td>
                            <td className="values-width right">{data?.tax1.toFixed(2)}</td>
                            <td className="values-width right">{data?.tax5.toFixed(2)}</td>
                            <td className="values-width right">
                              {data?.efectivo.toFixed(2)}
                            </td>
                            <td className="values-width right">{data?.cheque.toFixed(2)}</td>
                            <td className="values-width right">
                              {data?.tarjeta.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.credito.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.finaltotal.toFixed(2)}
                            </td>
                            <td className="right">{data?.transact}</td>
                            <td className="center"></td>
                          </tr>
                        );
                      })
                    : props?.filters?.map((data, index) => {
                        return (
                          <tr
                            onClick={() => {
                              //   props.ToggleOrderDetailModal(true);
                            }}
                            className={
                              data?.total !== 0 ? "tr-body" : "tr-body red"
                            }
                            key={index}
                          >
                            <td className="short-width center">{data?.rnc}</td>
                            <td className="type-width center">{data?.tipo}</td>
                            <td className="ncf-width center">{data?.ncf}</td>
                            <td className="ncf-width center">{data?.ncfref}</td>
                            <td className="date-width center">
                              {props.formatDate(data?.opendate, "yyyy/mm/dd")}
                            </td>
                            <td className="values-width right">
                              {data?.nettotal.toFixed(2)}
                            </td>
                            <td className="values-width right">{data?.tax1.toFixed(2)}</td>
                            <td className="values-width right">{data?.tax5.toFixed(2)}</td>
                            <td className="values-width right">
                              {data?.efectivo.toFixed(2)}
                            </td>
                            <td className="values-width right">{data?.cheque.toFixed(2)}</td>
                            <td className="values-width right">
                              {data?.tarjeta.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.credito.toFixed(2)}
                            </td>
                            <td className="values-width right">
                              {data?.finaltotal.toFixed(2)}
                            </td>
                            <td className=""></td>
                            <td className="">{data?.transact}</td>
                            <td className=""></td>
                          </tr>
                        );
                      })}
                </tbody>
                <tfoot>
                  <tr>
                    <th className="short-width center"></th>
                    <th className="type-width center"></th>
                    <th className="ncf-width center"></th>
                    <th className="ncf-width center"></th>
                    <th className="date-width center">Totales</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.nettotal)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.tax1)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.tax5)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.efectivo)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.cheque)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.tarjeta)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.credito)}</th>
                    <th className="values-width right">{formatToDecimal(props.totalSum?.finaltotal)}</th>
                    <th className=""></th>
                    <th className=""></th>
                    <th className=""></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div ref={props.tableFooterScroll} className="table-footer">
              {/* <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
              </table> */}
            </div>
          </>
        ) : (
          <div className="table-content">
            <div className="nothing-to-show">
              <FontAwesomeIcon icon={faEyeSlash} className="icon" />
              <h4>Nada por mostrar</h4>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Ncf607Form;
