import React from "react";
import { formatToDecimal } from "../../../../../../utils/formatToDecimal/formatToDecimal";
import { formatDate, formatDateTime } from "../../../utils/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Card = (props) => {
  return (
    <>
      <section className="order-detail-section">
        <div className="heading">
          <h4>Opendate: {formatDate(props.opendate)} </h4>
          <h2>Detalle de orden - Transacción # {props.transactId}</h2>
          <div>
            <input
              type="checkbox"
              name=""
              id=""
              onClick={() => {
                props.HandleDetailOrderMoreToggle(!props.moreDetails);
              }}
            />
            <label htmlFor="">More details</label>
          </div>
        </div>
        <div className="table-content-modal">
          {!props?.moreDetails && !props.loading && (
            <table>
              <thead>
                <tr>
                  <th className="qty-with">Cantidad</th>
                  <th className="description-with">Descripción</th>
                  <th className="total-with">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.detailOrderData?.orders?.map((data, index) => {
                  return (
                    <tr key={index} className={data?.precio > 0 ? "" : "red"}>
                      <td className="qty-with">{data?.cantidad}</td>
                      <td className="description-with">{data?.descript}</td>
                      <td className="total-with">
                        {formatToDecimal(data?.precio * data?.cantidad)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {props?.moreDetails && !props.loading && (
            <table className="more-details">
              <thead>
                <tr>
                  <th className="left">Empleado</th>
                  <th className="uniqueid">Id Unico</th>
                  <th className="timeord">Tiempo de Orden</th>
                  <th className="center">Cantidad</th>
                  <th className="descript">Descripción</th>
                  <th className="right">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.detailOrderData?.orders?.map((data, index) => {
                  return (
                    <tr key={index} className={data?.precio > 0 ? "" : "red"}>
                      <td className="left">{data?.empname1}</td>
                      <td className="uniqueid">{data?.uniqueid}</td>
                      <td className="timeord">
                        {formatDateTime(data?.timeord)}
                      </td>
                      <td className="center">{data?.cantidad}</td>
                      <td className="descript">{data?.descript}</td>
                      <td className="right">
                        {formatToDecimal(data?.precio * data?.cantidad)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {props.loading && (
            <>
              <table>
                <thead>
                  <tr>
                    <th className="qty-with">Cantidad</th>
                    <th className="description-with">Descripción</th>
                    <th className="total-with">Total</th>
                  </tr>
                </thead>
              </table>
              <div className="skeleton skeleton-td"></div>
              <div className="skeleton skeleton-td"></div>
              <div className="skeleton skeleton-td"></div>
            </>
          )}
        </div>
        <footer>
          <div className="payment-methods">
            <h3>Metodos de pago:</h3>
            <div className="methods">
              {props.detailOrderData?.payment_methods?.map((data, index) => {
                return (
                  <p key={index}>
                    {data?.descript}: {formatToDecimal(data?.tender)}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="additional-info">
            <h3>Información adicional:</h3>
            <div className="two-tags">
              <h4>T. Venta: </h4>
              <p>
                {props.descript.length > 25
                  ? `${props.descript.substring(0, 25)}...`
                  : `${props.descript}`}
              </p>
            </div>
            <div className="two-tags">
              <h4>Zona: </h4>
              <p>
                {props.descript1.length > 25
                  ? `${props.descript1.substring(0, 25)}...`
                  : `${props.descript1}`}
              </p>
            </div>
            {props.memcode !== 0 ? <>
              <h3 className="client-title">Cliente: </h3>
              {props.memcode} - {
                  props.membername.length > 20
                    ? `${props.membername.substring(0, 20)}...`
                    : `${props.membername}`
                }
            </> : <></>}
          </div>
          <div className="results">
            <div className="data">
              <label htmlFor="">Total descuentos: </label>
              <h4>{formatToDecimal(props.discount)}</h4>
            </div>
            <div className="data">
              <label htmlFor="">Subtotal: </label>
              <h4>{formatToDecimal(props.subtotal)}</h4>
            </div>
            <div className="data">
              <label htmlFor="">Propinas: </label>
              <h4>{formatToDecimal(props.tips)}</h4>
            </div>
            <div className="data">
              <label htmlFor="">Itbis %: </label>
              <h4>{formatToDecimal(props.itbis)}</h4>
            </div>
            <div className="data">
              <label className="total">Total: </label>
              <h4 className="total">{formatToDecimal(props.total)}</h4>
            </div>
          </div>
        </footer>
        <div className="buttons">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon left"
            onClick={props.PreviousTransact}
          />
          <FontAwesomeIcon
            icon={faArrowRight}
            className="icon right"
            onClick={props.NextTransact}
          />
        </div>
      </section>
    </>
  );
};

export default Card;
