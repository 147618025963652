import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faAngleRight,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import ReportLogo from "../../assets/reporte-icono.png";

const  SidebarForm = ({
  businessId,
  sidebarData,
  collapse,
  logout,
  onlyInventory,
  ToggleCollapse,
  ToggleDialog,
  ToggleElementsCollapse,
  ToggleOpenSidebar,
  HandleClickRoute,
}) => {
  return (
    <section
      className={collapse ? "sidebar-container-collapse" : "sidebar-container"}
    >
      <div
        onClick={() => {
          ToggleCollapse(!collapse);
        }}
        className="collapse-sidebar"
      >
        <FontAwesomeIcon
          className="icon-collapse-sidebar"
          icon={faAngleRight}
        />
      </div>
      <div className="report-logo">
        <img className="" src={ReportLogo} alt="" />
      </div>
      <ul className="elements-container">
        {sidebarData?.map((data, index) => {
          if(onlyInventory) return <div key={index}></div>
          if(data?.validBusiness) {
            if(!(data.validBusiness.includes(businessId)))  return <div key={index}></div>
          }
          if (data?.withElements) {
            return (
              <div className="with-elements-item" key={index}>
                <li
                  className=""
                  onClick={() => {
                    ToggleElementsCollapse(index);
                    if (collapse) {
                      ToggleOpenSidebar();
                    }
                    // ToggleCollapse(false)
                  }}
                >
                  <FontAwesomeIcon className="icon" icon={data?.icon} />
                  <p className="main-title">{data?.title}</p>
                  <FontAwesomeIcon
                    className={
                      data?.collapse
                        ? "icon icon-collapse"
                        : "icon icon-collapse rotate-icon"
                    }
                    icon={faAngleDown}
                  />
                </li>
                <ul
                  className={
                    !data?.collapse
                      ? "inside-elements-show"
                      : "inside-elements-hide"
                  }
                >
                  {data?.elements.map((element, i) => {
                    return (
                      <li
                        className={element.active ? "inner-element" : "inner-element element-disabled"}
                        key={i}
                        onClick={() => {
                          if(!element?.active && (!businessId === 5)) return ToggleDialog(true)
                          HandleClickRoute(element?.route)
                        }}
                      >
                        <p>{element?.title}</p>
                        <FontAwesomeIcon
                          className="icon icon-right"
                          icon={faAngleRight}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }
          return (
            <li key={index} onClick={() => HandleClickRoute(data?.route)}>
              <FontAwesomeIcon className="icon" icon={data?.icon} />
              <p>{data?.title}</p>
            </li>
          );
        })}
        <li onClick={logout} className="logout-element">
          <FontAwesomeIcon className="icon" icon={faRightFromBracket} />
          <p>Cerrar Sesión</p>
        </li>
      </ul>
    </section>
  );
};

export default SidebarForm;
