import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import useGetData from "../../../hooks/useGetData";

const useGetArticles = () => {
  const { auth } = useAuth();
  const [modifiedData, setModifiedData] = useState([]);
  const [search, setSearch] = useState(false);

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/inventory/articles`;

  const { data, loading } = useGetData({
    url: endpoint,
    search: search,
  });

  function HandleSearch() {
    // if (search) {
    //   return toast.error("Fecha inválida");
    // }
    return setSearch(true);
  }

  function addIncomeQty() {
    const dataWithIncomeQty = [];
    data.forEach((d) => {
      dataWithIncomeQty.push({ ...d, incomeQty: 0 });
    });
    return setModifiedData(dataWithIncomeQty);
  }

  function editIncomeQty(editData, type) {
    if(type === 'conteo') {
      const dataWithIncomeQty = [];
      data.forEach((d) => {
        const filter = editData.filter((edit) => {
          if(edit.invennum === d.invennum) return edit
        })
        dataWithIncomeQty.push({ ...d, incomeQty: filter[0].conteo_cantidad });
      });
      return setModifiedData(dataWithIncomeQty);
    }
   
    if(type === 'recepcion') {
      const dataWithIncomeQty = [];
      data.forEach((d) => {
        const filter = editData.filter((edit) => {
          if(edit.invennum === d.invennum) return edit
        })
        dataWithIncomeQty.push({ ...d, incomeQty: filter[0].recepcion_cantidad });
      });
      
      return setModifiedData(dataWithIncomeQty);
    }
   
  }

  function HandleRecepcionQty(id, qty) {
    const updatedData = modifiedData.map((item) => {
      if (item.id !== id) return item;
  
      // Verifica si qty es un número positivo; si no lo es, mantiene el valor anterior
      const newIncomeQty = qty > -1 ? qty : item.incomeQty;
  
      return { ...item, incomeQty: newIncomeQty };
    });
  
    return setModifiedData(updatedData);
  }

  function ResetArticlesData() {
    return addIncomeQty()
  }

  useEffect(() => {
    HandleSearch();
  }, [auth]);

  useEffect(() => {
    setSearch(false);
    addIncomeQty()
  }, [data]);

  return { data, modifiedData, loading, editIncomeQty, HandleRecepcionQty, HandleSearch, ResetArticlesData };
};

export default useGetArticles;
