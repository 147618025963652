import React from "react";
import { formatDate } from "../../Transactions/utils/formatDate";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const TableContent = (props) => {
  const ecfData = props?.ecfData;

  return (
    <>
      <section className="e_invoice-section">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th className="center">No.</th>
                <th className="center opendate-with">Opendate</th>
                <th className="center">Transact</th>
                <th className="center">E-NCF</th>
                {/* <th className="center tipo_doc-with">T. Documento</th> */}
                <th className="left descript-with">Descripción</th>
                <th className="right">Subtotal</th>
                <th className="right">Descuento</th>
                <th className="right">Itbis</th>
                <th className="right">Propina</th>
                <th className="right">Total</th>
                <th className="center rnc-with">RNC</th>
                <th className="center rnc-with">Razon Social</th>
                <th className="center">E-NCF_Ref</th>
                <th className="center">Id_transact</th>
              </tr>
            </thead>
            <tbody>
              {ecfData?.map((data, index) => {
                return (
                  <tr
                    onClick={() => {
                      // props.HandleSelectedTransact(props?.ecfData, index);
                      // props.HandleDetailOrderToggle();
                    }}
                    className={parseFloat(data?.total) > 0 ? "" : "red"}
                    value={data?.transact}
                    key={index}
                  >
                    <td className="center">{index + 1}</td>
                    <td className="center">{data?.opendate}</td>
                    <td className="center">{data?.trs}</td>
                    <td className="center">{data?.ecf}</td>
                    {/* <td className="center">{data?.tipo_documento}</td> */}
                    <td className="left">{data?.descripcion}</td>
                    <td className="right">{data?.subtotal}</td>
                    <td className="right">{data?.descuento}</td>
                    <td className="right">{data?.itbis}</td>
                    <td className="right">{data?.propina}</td>
                    <td className="right">{data?.total}</td>
                    <td className="center rnc-with">{data?.rnc}</td>
                    <td className="left">{data?.razonsocial}</td>
                    <td className="center">{data?.ecf_ref}</td>
                    <td className="center">{data?.id_tran}</td>
                  </tr>
                );
              })}
            </tbody>
            {!props.filterHasData && (
              <tfoot>
                <tr>
                  <th className="center"></th>
                  <th className="center"></th>
                  <th className="center"></th>
                  {/* <th className="center"></th> */}
                  <th className="left"></th>
                  <th className=""></th>
                  <th className="right">
                    {formatToDecimal(parseFloat(props.ecfTotal[0]?.subtotal))}
                  </th>
                  <th className="right">
                    {formatToDecimal(parseFloat(props.ecfTotal[0]?.descuento))}
                  </th>
                  <th className="right">
                    {formatToDecimal(parseFloat(props.ecfTotal[0]?.itbis))}
                  </th>
                  <th className="right">
                    {formatToDecimal(parseFloat(props.ecfTotal[0]?.propina))}
                  </th>
                  <th className="right">
                    {formatToDecimal(parseFloat(props.ecfTotal[0]?.total))}
                  </th>
                  <th className=""></th>
                  <th className=""></th>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </section>
    </>
  );
};

export default TableContent;
