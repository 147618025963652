import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useGetSalesProduct from "./hooks/useGetSalesProduct";
import useDateFilter from "../../../hooks/useDateFilter";
import FiltersHeading from "./components/FiltersHeading";
import TableProductDetail from "./components/TableProductDetail";
import Pagination from "../../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useFilters from "./hooks/useFilters";
import useGetSalesTotal from "./hooks/useGetSalesTotal";
import useGetCategories from "./hooks/useGetCategories";

const Index = () => {
  const { auth } = useAuth();
  const categoryRef = useRef(null);

  const clearRefs = () => {
    return (categoryRef.current.value = "default");
  };

  const [resetPage, setResetPage] = useState(false);

  const limit = 300;

  const { date, HandleDateChange } = useDateFilter("", "");

  const {
    data: salesProductData,
    salesProduct,
    loading: salesProductLoading,
    HandleSearch,
    HandlePage
  } = useGetSalesProduct({
    first_date: date.from,
    second_date: date.to,
    limit,
  });

  const {
    data: total_data,
    loading: total_loading,
    HandleSearch: HandleSearchTotal,
  } = useGetSalesTotal({
    first_date: date.from,
    second_date: date.to,
  });

  const {
    data: categories_data,
    loading: categories_loading,
    HandleSearch: HandleSearchCategories,
  } = useGetCategories({
    first_date: date.from,
    second_date: date.to,
  });

  const { filters, filters_loading, HandleFilterByCategory, clearFilters } =
    useFilters({
      dataArray: salesProduct,
      first_date: date.from,
      second_date: date.to,
      categoryRef,
      clearRefs,
    });

  const filtersHasData = filters?.length > 0 ? filters : salesProduct;

  const totalItemsCount = salesProductData?.count?.length;
  const hasData = salesProduct?.length > 0;

  const isSomethingLoading =
    salesProductLoading ||
    total_loading ||
    categories_loading ||
    filters_loading;

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  function HandleSearches() {
    return (
      HandleSearch(),
      HandleResetPage(),
      HandleSearchTotal(),
      HandleSearchCategories()
    );
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
  }, [auth]);

  return (
    <section className="sales-product-section">
      <FiltersHeading
        date={date}
        categories={categories_data}
        categoryRef={categoryRef}
        clearFilters={clearFilters}
        HandleFilterByCategory={HandleFilterByCategory}
        registerCount={salesProductData?.count?.length}
        HandleDateChange={HandleDateChange}
        HandleSearches={HandleSearches}
      />
      {!isSomethingLoading && hasData && (
        <TableProductDetail
          salesProductData={filtersHasData}
          generalTotal={total_data[0]}
        />
      )}

      {isSomethingLoading && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}

      {!isSomethingLoading && !hasData && (
        <div className="nothing-to-show">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          <h4>Nada por mostrar</h4>
        </div>
      )}
      <Pagination
        itemsCount={totalItemsCount}
        itemsPerPage={limit}
        loading={isSomethingLoading}
        HandlePage={HandlePage}
        hasData={hasData}
        resetPage={resetPage}
        disable={filters?.length > 0}
      />
    </section>
  );
};

export default Index;
