import React, { useEffect, useState, useRef } from "react";
import FiltersHeading from "./components/Heading.jsx";
import TableTransactions from "./components/TableTransactions.jsx";
import Pagination from "../../../components/Pagination.jsx";
import OrderDetail from "./components/modals/OrderDetail/Index.jsx";
import { getTotal } from "./utils/getTotal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useDateFilter from "../../../hooks/useDateFilter.js";
import useGetTransactions from "./hooks/useGetTransactions";
import useToggles from "./hooks/useToggles";
import useFilters from "./hooks/useFilters.js";
import useGetTransact from "./hooks/useGetTransact.js";
import useSelectedTransact from "./hooks/useTransactId.js";
import useAuth from "../../../hooks/useAuth.js";
import useGetTransactionsTotal from "./hooks/useGetTransactionsTotal.js";
import { useParams } from "react-router-dom";

const Index = () => {
  const { auth } = useAuth();
  const { date, HandleDateChange } = useDateFilter("", "");

  const { transact_id } = useParams();

  const transactRef = useRef(null);

  const [resetPage, setResetPage] = useState(false);

  const limit = 300;

  const {
    data: transactionData,
    loading: transactionLoading,
    HandleSearch: HandleSearchTransactions,
    HandlePage,
  } = useGetTransactions({
    first_date: date.from,
    second_date: date.to,
    limit,
  });

  const { data: transactionDataTotal, HandleSearch: HandleSearchTotal } =
    useGetTransactionsTotal({
      first_date: date.from,
      second_date: date.to,
    });

  const {
    data: transactByIdData,
    loading: transactByIdLoading,
    ResetValue: ResetTransact,
    clearTransact,
    HandleTransactId,
  } = useGetTransact({ transact_id, transactRef });

  const TransactByIdHasData = transactByIdData?.length > 0;

  const switchBetweenData = TransactByIdHasData
    ? transactByIdData
    : transactionData?.rows;

  const {
    selectedTransact,
    HandleSelectedTransact,
    NextTransact,
    PreviousTransact,
  } = useSelectedTransact(switchBetweenData); //order detail

  const { toggles, HandleDetailOrderToggle, HandleDetailOrderMoreToggle } =
    useToggles();

  const {
    filters,
    match,
    inputOperationRef,
    selectOperationRef,
    whoStartedRef,
    whoClosedRef,
    HandleFilterByOperation,
    HandleSelectEmployee,
    clearFilters,
  } = useFilters({
    dataArray: transactionData?.rows,
    ResetTransact,
    clearTransact,
    transactRef,
  });

  const filterHasData = filters.length > 0 ? filters : switchBetweenData;

  const manageTotal = filters.length > 0 ? filterHasData : transactionDataTotal;

  const { total } = getTotal(manageTotal);

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  function HandleSearches() {
    return HandleSearchTransactions(), HandleSearchTotal(), HandleResetPage();
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
  }, [auth]);

  return (
    <section className="transactions-section">
      {toggles?.detailOrder && (
        <OrderDetail
          toggles={toggles}
          selectedTransact={selectedTransact}
          HandleDetailOrderToggle={HandleDetailOrderToggle}
          HandleDetailOrderMoreToggle={HandleDetailOrderMoreToggle}
          NextTransact={NextTransact}
          PreviousTransact={PreviousTransact}
        />
      )}

      <FiltersHeading
        transactRef={transactRef}
        switchBetweenData={switchBetweenData}
        date={date}
        registerCount={
          filterHasData?.length > 0
            ? transactionData?.count
            : filterHasData?.length
        }
        inputOperationRef={inputOperationRef}
        selectOperationRef={selectOperationRef}
        whoStartedRef={whoStartedRef}
        whoClosedRef={whoClosedRef}
        clearFilters={clearFilters}
        HandleDateChange={HandleDateChange}
        HandleFilterByOperation={HandleFilterByOperation}
        HandleSelectEmployee={HandleSelectEmployee}
        HandleTransactId={HandleTransactId}
        HandleSearch={HandleSearches}
      />

      {filterHasData?.length > 0 &&
        match &&
        !transactionLoading &&
        !transactByIdLoading && (
          <TableTransactions
            transactionData={filterHasData}
            count={transactionData?.count}
            // itemOffset={itemOffset}
            total={total}
            HandleSelectedTransact={HandleSelectedTransact}
            HandleDetailOrderToggle={HandleDetailOrderToggle}
          />
        )}

      <Pagination
        itemsCount={transactionData?.count}
        itemsPerPage={limit}
        disable={TransactByIdHasData}
        loading={transactionLoading}
        hasData={filterHasData?.length > 0 && match}
        resetPage={resetPage}
        HandlePage={HandlePage}
      />

      {(transactionLoading || transactByIdLoading) && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}

      {!(filterHasData?.length > 0 && match) && !transactionLoading && (
        <div className="nothing-to-show">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          <h4>Nada por mostrar</h4>
        </div>
      )}
    </section>
  );
};

export default Index;
