import { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";

const useFilters = ({ dataArray, clearTransact, ResetTransact, transactRef }) => {
  const [filters, setFilters] = useState([]);
  const [match, setMatch] = useState(true);

  const inputOperationRef = useRef(0);
  const selectOperationRef = useRef("default");
  const whoStartedRef = useRef("default");
  const whoClosedRef = useRef("default");

  function HandleFilterByOperation() {
    if (!dataArray) return clearFilters();

    const inputValue = inputOperationRef.current.value;
    const selectValue = selectOperationRef.current.value;

    let filterArray = [];
    let defaultCase = false;

    switch (selectValue) {
      case "Greater Than":
        filterArray = dataArray.filter((data) => {
          return parseFloat(inputValue) < parseFloat(data?.total);
        });
        break;

      case "Less Than":
        filterArray = dataArray.filter((data) => {
          return parseFloat(inputValue) > parseFloat(data?.total);
        });
        break;

      case "Equal To":
        filterArray = dataArray.filter((data) => {
          return parseFloat(inputValue) === parseFloat(data?.total);
        });
        break;

      default:
        defaultCase = true;
        toast.error("Seleccione una operación");
        break;
    }

    if (filterArray.length === 0 && !defaultCase) return setMatch(false);

    return setFilters(filterArray), setMatch(true);
  }

  function HandleSelectEmployee() {
    const whoStarted = whoStartedRef.current.value;
    const whoClosed = whoClosedRef.current.value;

    const filterArray = dataArray.filter((data) => {
      if (!(whoStarted === "default") && !(whoClosed === "default")) {
        return data?.empname1 === whoStarted && data?.empname2 === whoClosed;
      }
      return data?.empname1 === whoStarted || data?.empname2 === whoClosed;
    });

    if (
      filterArray.length === 0 &&
      !(whoStarted === "default") &&
      !(whoClosed === "default")
    ) {
      return setMatch(false);
    }

    return setFilters(filterArray), setMatch(true);
  }

  function HandleFilters(value) {
    return setFilters(value)
  }

  function clearFilters() {
    selectOperationRef.current.value = "default";
    whoStartedRef.current.value = "default";
    whoClosedRef.current.value = "default";
    inputOperationRef.current.value = "";
    transactRef.current.value = ""
    setMatch(true);
    setFilters([]);
    clearTransact()
    ResetTransact()
  }

  useEffect(() => {
    // clearFilters();
    ResetTransact()
  }, [dataArray]);

  return {
    filters,
    match,
    inputOperationRef,
    selectOperationRef,
    whoStartedRef,
    whoClosedRef,
    HandleFilters,
    HandleFilterByOperation,
    HandleSelectEmployee,
    clearFilters,
  };
};

export default useFilters;
