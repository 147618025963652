import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetTransact = ({transact_id, transactRef}) => {
  const { auth } = useAuth();
  
  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;

  const [transactId, setTransactId] = useState(null);

  const isNullValue = transactId === null || transactId === "" ? true : false;

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/transact/${transactId}`;

  const { data, loading, ResetValue } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
  });

  function clearTransact() {
    return setTransactId(null);
  }

  function HandleTransactId(value) {
    if (value === "") return toast.error("Transacción inválida");
    const checkIfTransactRefHasAValue = transactRef.current.value
    if(checkIfTransactRefHasAValue) {
      return setTransactId(transactRef.current.value);
    } 
    return setTransactId(value);
  }

  useEffect(() => {
    if(!transact_id) return
    HandleTransactId(transact_id)
  }, [transactId])

  useEffect(() => {
    if (data?.nothingFound) {
      toast.error("Transacción no encontrada...");
    }
  }, [data]);

  return {
    data: data?.transaction,
    loading,
    ResetValue,
    clearTransact,
    HandleTransactId,
  };
};

export default useGetTransact;
