import React from "react";

const TableContentSummary = (props) => {
  console.log(props.data);
  return (
    <section className="ncf-section">
      <div className="table-content">
        <table>
          <thead>
            <tr>
              <th className="numeration-transact">No.</th>
              <th className="opendate-width">Invennum</th>
              <th className="opendate-width">Descripción</th>
              <th className="opendate-width">Cantidad</th>
              <th className="opendate-width">Unitdes</th>
              <th className="opendate-width">Upercont</th>
              <th className="opendate-width">Contdes</th>
              <th className="opendate-width">Contperp</th>
              <th className="opendate-width">Packdes</th>
              <th className="opendate-width">Consumo</th>
              <th className="opendate-width">Reportno</th>
              <th className="opendate-width">Descripción</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((d, index) => {
              return (
                <tr key={d.id}>
                  <td className="numeration-transact">{index + 1}</td>
                  <td className="opendate-width">{d.invennum}</td>
                  <td className="opendate-width">{d.descript}</td>
                  <td className="opendate-width">{d.cantidad}</td>
                  <td className="opendate-width">{d.unitdes}</td>
                  <td className="opendate-width">{d.upercont}</td>
                  <td className="opendate-width">{d.contdes}</td>
                  <td className="opendate-width">{d.contperp}</td>
                  <td className="opendate-width">{d.packdes}</td>
                  <td className="opendate-width">{d.consumo}</td>
                  <td className="opendate-width" >{d.reportno}</td>
                  <td className="opendate-width">{d.descript1}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
              <th className=""></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  );
};

export default TableContentSummary;
