import React, { useEffect, useState } from "react";
import { diffDays } from "../utils/diffDays";

const CardInfo = ({ salesData, date }) => {
  const [cardsInfo, setCardsInfo] = useState([]);

  const diffDaysValue = diffDays({
    first_date: date?.to,
    second_date: date?.from,
  });

  function getTotalSales() {
    let totalSales = 0;
    let totalOpenSales = 0;
    let totalNetSales = 0;
    let totalITBIS = 0;
    let totalTips = 0;
    let totalDiscounts = 0;
    let totalClients = 0;
    let totalTransacts = 0;
    let totalOpenTransacts = 0;
    let totalCancelattions = 0;

    salesData.forEach((data) => {
      totalSales += data?.total;
      totalOpenSales += data?.ventasbiertas;
      totalNetSales += data?.nettotal;
      totalITBIS += data?.tax1;
      totalTips += data?.tax5;
      totalDiscounts += data?.descuentos;
      totalClients += parseInt(data?.numcust);
      totalTransacts += parseInt(data?.trans);
      totalOpenTransacts += parseInt(data?.transabiertas);
      totalCancelattions += parseInt(data?.anulaciones);
    });

    let object = {
      totalSales: totalSales + totalOpenSales,
      totalOpenSales,
      totalNetSales,
      totalITBIS,
      totalTips,
      totalDiscounts: Math.abs(totalDiscounts),
      totalClients,
      totalTransacts: totalTransacts + totalOpenTransacts,
      totalOpenTransacts,
      totalCancelattions: Math.abs(totalCancelattions),
    };

    return setCardsInfo(object);
  }

  useEffect(() => {
    if (!salesData) return;
    getTotalSales();
  }, [salesData]);

  return (
    <section className="cards-container">
      <div className="card-info">
        <label htmlFor="">Ventas Netas</label>
        <h4>{cardsInfo?.totalNetSales?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">ITBIS 18%</label>
        <h4>{cardsInfo?.totalITBIS?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">10% Ley</label>
        <h4>{cardsInfo?.totalTips?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">Ventas Abiertas</label>
        <h4>{cardsInfo?.totalOpenSales?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">Total Ventas</label>
        <h4 className="green">
          {cardsInfo?.totalSales?.toLocaleString("en-US")}
        </h4>
        <p>vs {diffDaysValue} días</p>
      </div>
      <div className="card-info">
        <label htmlFor="">Clientes</label>
        <h4>{cardsInfo?.totalClients?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">Transacciones Abiertas</label>
        <h4>{cardsInfo?.totalOpenTransacts?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">Transacciones</label>
        <h4>{cardsInfo?.totalTransacts?.toLocaleString("en-US")}</h4>
        <p>vs {diffDaysValue} días </p>
      </div>
      <div className="card-info">
        <label htmlFor="">Descuentos</label>
        <h4 className="red">
          {cardsInfo?.totalDiscounts?.toLocaleString("en-US")}
        </h4>
        <p>vs {diffDaysValue} días</p>
      </div>
      <div className="card-info">
        <label htmlFor="">Cancelaciones</label>
        <h4 className="red">
          {cardsInfo?.totalCancelattions?.toLocaleString("en-US")}
        </h4>
        <p>vs {diffDaysValue} días </p>
      </div>
    </section>
  );
};

export default CardInfo;
