import useGetData from "../../../hooks/useGetData";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetSectionsData = ({ first_date, second_date }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);

  const isNullValue = !first_date || !second_date ? true : false;
  const businessId = auth?.isMultibusiness ?  auth?.selectedBusinessOnlyId : auth?.business?.ID_NEGOCIO
  const selectedBranch = auth?.selectedBranch;

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/transactions/summary/places/firstdate/${first_date}/seconddate/${second_date}`;

  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });

  function HandleSearch() {
    if (isNullValue && search) {
      return toast.error("Fecha inválida");
    }
    return setSearch(true);
  }

  useEffect(() => {
    HandleSearch();
  }, [auth]);

  useEffect(() => {
    return setSearch(false);
  }, [data]);

  return { data, loading, HandleSearch };
};

export default useGetSectionsData;
