import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  BusinessSelect,
  BranchSelect,
} from "../../../components/Selects/Selects";
import { formatToDecimal } from "../../../utils/formatToDecimal/formatToDecimal";
import { formatInteger } from "../../../utils/formatToDecimal/formatInteger";
import useLogOut from "../../../hooks/useLogout";

const Mobile = ({
  businessName,
  date,
  dateToggle,
  salesData,
  isMultibusiness,
  HandleDateToggle,
  HandleDateChange,
  HandleSearch,
}) => {
  const [cardsInfo, setCardsInfo] = useState([]);
  const logout = useLogOut();

  function getTotalSales() {
    let totalSales = 0;
    let totalOpenSales = 0;
    let totalNetSales = 0;
    let totalITBIS = 0;
    let totalTips = 0;
    let totalDiscounts = 0;
    let totalClients = 0;
    let totalTransacts = 0;
    let totalOpenTransacts = 0;
    let totalCancelattions = 0;
    let totalMontoCalculado = 0;
    let totalMontoActual = 0;
    let totalSobranteFaltante = 0;

    salesData.forEach((data) => {
      totalSales += data?.total;
      totalOpenSales += data?.ventasbiertas;
      totalNetSales += data?.nettotal;
      totalITBIS += data?.tax1;
      totalTips += data?.tax5;
      totalDiscounts += data?.descuentos;
      totalClients += parseInt(data?.numcust);
      totalTransacts += parseInt(data?.trans);
      totalOpenTransacts += parseInt(data?.transabiertas);
      totalCancelattions += parseInt(data?.anulaciones);
      totalMontoCalculado += parseInt(data?.monto_calculado);
      totalMontoActual += parseInt(data?.monto_actual);
      totalSobranteFaltante += parseInt(data?.sobrantefaltante);
    });

    let object = {
      totalSales: totalSales + totalOpenSales,
      totalOpenSales,
      totalNetSales,
      totalITBIS,
      totalTips,
      totalDiscounts: Math.abs(totalDiscounts),
      totalClients,
      totalTransacts: totalTransacts + totalOpenTransacts,
      totalOpenTransacts,
      totalCancelattions: Math.abs(totalCancelattions),
      totalMontoCalculado,
      totalMontoActual,
      totalSobranteFaltante,
    };

    return setCardsInfo(object);
  }

  useEffect(() => {
    if (!salesData) return;
    getTotalSales();
  }, [salesData]);

  return (
    <section className="home-section-mobile">
      <div className="logout-button" onClick={logout}>
        <FontAwesomeIcon icon={faRightFromBracket} className="icon" />
        <label htmlFor=""> Cerrar Sesión</label>
      </div>
      <h2 className="title">Dashboard's {businessName}</h2>
      <div className="filters-container">
        <div
          className={
            !dateToggle
              ? "filter remove-margin-bottom"
              : "filter add-margin-bottom"
          }
        >
          <label htmlFor="">Filtrar por Fecha: </label>
          <select
            onChange={(e) => {
              const value = e.target.value;
              if (value === "select-date") return HandleDateToggle(true);

              HandleDateToggle(false);
              HandleDateChange(
                e,
                {
                  type: "interval-time",
                },
                HandleSearch
              );
            }}
            name=""
            id=""
          >
            <option value="default">Hoy</option>
            <option value="Ayer">Ayer</option>
            <option value="Mes">Este mes</option>
            <option value="select-date">Seleccionar fecha</option>
          </select>
          {dateToggle ? (
            <div className="filter-date">
              <div>
                <div className="from">
                  <label htmlFor="">Desde: </label>
                  <input
                    onChange={(e) => {
                      HandleDateChange(e, {
                        property: "from",
                      });
                    }}
                    type="date"
                    value={date?.from}
                  />
                </div>
                <div className="to">
                  <label htmlFor="">Hasta: </label>
                  <input
                    onChange={(e) => {
                      HandleDateChange(e, {
                        property: "to",
                      });
                    }}
                    type="date"
                    value={date?.to}
                  />
                </div>
              </div>
              <button onClick={HandleSearch} className="icon-btn">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {isMultibusiness ? (
          <>
            <div className="filter">
              <label htmlFor="">Filtrar por Negocio: </label>
              <BusinessSelect />
            </div>
            <div className="filter">
              <label htmlFor="">Filtrar por Sucursal: </label>
              <BranchSelect />
            </div>
          </>
        ) : (
          <div className="filter">
            <label htmlFor="">Filtrar por Sucursal: </label>
            <BranchSelect />
          </div>
        )}
        {!dateToggle && (
          <button onClick={HandleSearch} className="icon-btn">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </button>
        )}
      </div>
      <div className="container">
        <div className="item">
          <label htmlFor="">Ventas Netas: </label>
          <h4>{formatToDecimal(cardsInfo?.totalNetSales)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Itbis: </label>
          <h4>{formatToDecimal(cardsInfo?.totalITBIS)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Ley: </label>
          <h4>{formatToDecimal(cardsInfo?.totalTips)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Ventas Abiertas: </label>
          <h4>{formatInteger(cardsInfo?.totalOpenSales)}</h4>
        </div>
        <div className="item green">
          <label htmlFor="">Total Ventas: </label>
          <h4>{formatToDecimal(cardsInfo?.totalSales)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Clientes: </label>
          <h4>{formatInteger(cardsInfo?.totalClients)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Transacciones Abiertas: </label>
          <h4>{formatInteger(cardsInfo?.totalOpenTransacts)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Transacciones: </label>
          <h4>{formatInteger(cardsInfo?.totalTransacts)}</h4>
        </div>
        <div className="item">
          <label htmlFor="">Descuentos: </label>
          <h4>{formatToDecimal(cardsInfo?.totalDiscounts)}</h4>
        </div>
        <div className="item red">
          <label htmlFor="">Cancelaciones: </label>
          <h4>{formatInteger(cardsInfo?.totalCancelattions)}</h4>
        </div>
        <label className="item division-line"/>
        <div className={cardsInfo?.totalMontoCalculado < 0 ? "item red" : "item"}>
          <label htmlFor="">Balance Sistema: </label>
          <h4>{formatToDecimal(cardsInfo?.totalMontoCalculado)}</h4>
        </div>
        <div className={cardsInfo?.totalMontoActual < 0 ? "item red" : "item"}>
          <label htmlFor="">Balance Actual: </label>
          <h4>{formatToDecimal(cardsInfo?.totalMontoActual)}</h4>
        </div>
        <div className={cardsInfo?.totalSobranteFaltante < 0 ? "item red" : "item"}>
          <label htmlFor="">Sobrante/Faltante: </label>
          <h4>{formatToDecimal(cardsInfo?.totalSobranteFaltante)}</h4>
        </div>
      </div>
    </section>
  );
};

export default Mobile;
