import { useEffect, useState } from "react";
import { fullDateFormat } from "../utils/dateFormat/dateFormat";
import { getDateFromSelect } from "../utils/dateFormat/dateFormat";

export default function useDateFilter(fromDate, toDate) {
  const currentDate = new Date();
  currentDate.setUTCHours(currentDate.getUTCHours() - 4);

  const initialOfTheDayDate = () => {
    const date = new Date();
    const removingForHours = -4;
    const initialDate = new Date(
      date.getUTCFullYear(),
      date.getMonth(),
      currentDate?.getUTCDate(),
      removingForHours
    );
    return initialDate;
  };

  const [date, setDate] = useState({
    from: "",
    to: "",
  });

  //The HandleSearch function, is for automaticly for searching when is a interval of time like month, days, etc.

  function HandleDateChange(e, config, HandleSearch) {
    const event = e.target.value;
    const newDate = config?.isSelect ? getDateFromSelect(event) : event;

    if (config?.isSelect && event === "Sin Filtro") {
      return clearDateState();
    }

    if (config?.type === "interval-time") {
      IntervalTime(e, HandleSearch);
    }

    if(event === "Ayer") {
      const yesterday = getDateFromSelect(event);
      return setDate({
        to: fullDateFormat(yesterday),
        from: fullDateFormat(yesterday),
      })
    }

    if (config?.property === "from")
      return setDate({
        to: config?.isSelect ? fullDateFormat(currentDate) : date?.to,
        from: fullDateFormat(newDate),
      });

    if (config?.property === "to")
      return setDate({
        from: config?.isSelect ? fullDateFormat(currentDate) : date?.from,
        to: fullDateFormat(newDate),
      });
  }

  function IntervalTime(e, HandleSearch) {
    const value = e.target.value;
    const intervalDate = getDateFromSelect(value);

    if(value === "Hoy") {
      return (
        setDate({
          from: fullDateFormat(initialOfTheDayDate()),
          to: fullDateFormat(currentDate),
        }),
        HandleSearch()
      );
    }

    if (value === "Ayer") {
      return (
        setDate({
          from: fullDateFormat(intervalDate),
          to: fullDateFormat(intervalDate),
        }),
        HandleSearch()
      );
    }

    return (
      setDate({
        from: fullDateFormat(intervalDate),
        to: fullDateFormat(currentDate),
      }),
      HandleSearch()
    );
  }

  function clearDateState() {
    return setDate({
      from: "",
      to: "",
    });
  }

  useEffect(() => {
    if (fromDate === "" && toDate === "") {
      return setDate({
        from: fullDateFormat(currentDate),
        to: fullDateFormat(currentDate),
      });
    }
    setDate({ from: fullDateFormat(fromDate), to: fullDateFormat(toDate) });
  }, []);

  return { date, HandleDateChange, clearDateState };
}
