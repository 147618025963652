import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import useGetData from "../../../../hooks/useGetData";

const useGetInventorySummary = ({ first_date, second_date }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);

  const isNullValue = !first_date || !second_date ? true : false;
  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/inventory/head&body/firstdate/${first_date}/seconddate/${second_date}/summary`;

  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });

  console.log(endpoint);

  function HandleSearch() {
    return setSearch(true);
  }

  useEffect(() => {
    HandleSearch();
  }, [auth, selectedBranch]);

  useEffect(() => {
    setSearch(false);
    //eslint-disable-next-line
  }, [data]);

  return { data, loading, HandleSearch };
};

export default useGetInventorySummary;
