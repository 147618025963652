import { formatToDecimal } from "../../../utils/formatToDecimal/formatToDecimal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const TablePayments = (props) => {
  return (
    <section className="tablepayments-section">
      <div className="table-content">
        <table className="table-header">
          <thead className="background-tr-1">
            <tr>
              <th className="numeration-with">No.</th>
              <th className="opendate-with">Opendate</th>
              <th className="transact-with">Transact</th>
              <th className="employee-with">Empleado</th>
              <th className="method-with">Metodo de Pago</th>
              <th className="numbers-with">Tender</th>
              <th className="numbers-with">Cambio/Propina</th>
            </tr>
          </thead>
          {props.paymentData?.map((data, index) => {
            return (
              <tbody key={index}>
                <tr
                  className={
                    props.selectedTransact === data?.transact
                      ? "transact-selected"
                      : ""
                  }
                >
                  <td className="numeration-with">{index + 1}</td>
                  <td className="opendate-with">{data?.opendate}</td>
                  <td className="transact-with">
                    {data?.transact}
                    <div
                      className="search-icon"
                      onClick={() =>
                        props.HandleSelectedTransact(data?.transact)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="icon"
                      />
                    </div>
                  </td>
                  <td className="employee-with">{data?.empname}</td>
                  <td className="method-with">{data?.descript}</td>
                  <td className="numbers-with">
                    {formatToDecimal(data?.tender)}
                  </td>
                  <td className="numbers-with">
                    {formatToDecimal(data?.cambio)}
                  </td>
                </tr>
              </tbody>
            );
          })}
          <tfoot>
            <tr>
              <th className="numeration-with"></th>
              <th className="opendate-with"></th>
              <th className="employee-with"></th>
              <th className="method-with">Total General:</th>
              <th></th>
              <th className="numbers-with">
                {formatToDecimal(props.totalTender)}
              </th>
              <th className="numbers-with">
                {formatToDecimal(props.totalCambio)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  );
};

export default TablePayments;
