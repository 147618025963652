import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAuth from "../../../../hooks/useAuth";
import useGetData from "../../../../hooks/useGetData";

const useGetArticles = ({ conteoA, recepcionAToB, consumeAToB, conteoB }) => {
  const { auth } = useAuth();
  const [modifiedData, setModifiedData] = useState([]);
  const [search, setSearch] = useState(false);

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/inventory/articles`;

  const { data, loading } = useGetData({
    url: endpoint,
    search: search,
  });

  function HandleSearch() {
    if (search) {
      return toast.error("Fecha inválida");
    }
    return setSearch(true);
  }

  function ManipulateData() {
    try {
      const localArray = [];
    data.forEach((d) => {
      const filterA =
        conteoA?.inventory_bodies.length > 0
          ? conteoA?.inventory_bodies.filter((body) => {
              if (body.invennum === d.invennum) return body;
            })
          : [];

      const conteoInicial = filterA[0]?.conteo_cantidad
        ? filterA[0]?.conteo_cantidad
        : 0;

      const filterRecepcionAtoB =
        recepcionAToB?.inventory_bodies.length > 0
          ? recepcionAToB?.inventory_bodies.filter((body) => {
              if (body.invennum === d.invennum) return body;
            })
          : [];

      const recepcion =
        recepcionAToB?.inventory_bodies.length > 0
          ? filterRecepcionAtoB[0]?.recepcion_cantidad
            ? filterRecepcionAtoB[0]?.recepcion_cantidad
            : 0
          : 0;

      const filterConsumeAtoB = consumeAToB.filter((body) => {
        if (body.invennum === d.invennum) return body;
      });

      const consumo = filterConsumeAtoB[0]?.cantidad
        ? filterConsumeAtoB[0]?.cantidad
        : 0;

      const inventarioTeorico =
        parseInt(conteoInicial) + parseInt(recepcion) - parseInt(consumo);

      const filterB =
        conteoB?.inventory_bodies 
          ? conteoB?.inventory_bodies?.length > 0 && conteoB.inventory_bodies.filter((body) => {
            if (body.invennum === d.invennum) return body;
          })
          : [];

      const conteoFinal = filterB[0]?.conteo_cantidad
        ? filterB[0]?.conteo_cantidad
        : 0;

      localArray.push({
        ...d,
        conteoInicial,
        recepcion,
        consumo,
        inventarioTeorico,
        conteoFinal,
      });
    });
    return setModifiedData(localArray);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    HandleSearch();
  }, [auth]);

  useEffect(() => {
    ManipulateData();
  }, [data, conteoA, recepcionAToB, consumeAToB, conteoB]);

  useEffect(() => {
    setSearch(false);
  }, [data]);

  return { data, modifiedData, loading, HandleSearch };
};

export default useGetArticles;
