import { useQuery } from "react-query";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;

function PiePaymentGraph(props) {
  const { data: paymentsSalesData } = useQuery(
    "paymentsSalesData",
    () => {
      return fetch(
        `${BASE_URL}/business/${props?.businessId}/branch/${props?.selectedBranch}/sales/summary/payments/firstdate/${props?.from}/seconddate/${props?.to}`
      ).then((res) => {
        return res.json();
      });
    }
  );

  const data = {
    labels: paymentsSalesData
      ? paymentsSalesData.map((data) => data?.descript)
      : [],
    datasets: [
      {
        data: paymentsSalesData
          ? paymentsSalesData.map((data) => data?.tender)
          : [],
        backgroundColor: [
          "#84b6f4",
          "#fdfd96",
          "#77dd77",
          "#ff6961",
          "#fdcae1",
          "#ffda9e",
          "#fcb7af",
          "#b0c2f2",
          "#c5c6c8",
          "#b2e2f2"
        ],
      },
    ],
  };

  const getTotal = () => {
    let total = 0;

    paymentsSalesData.forEach((data) => {
      total += data?.tender;
    });

    return total;
  };

  if (paymentsSalesData) {
    if (paymentsSalesData.length > 0) {
      return (
        <div className="pie-chart">
          <h4>
            Ventas del {dayAndMonthFormat(props.from)} al{" "}
            {dayAndMonthFormat(props.to)}
          </h4>
          <Pie className="chart" data={data} />
          <div className="dataDetail">
            {paymentsSalesData.map((data, index) => {
              return (
                <div key={index}>
                  <label htmlFor="">{data?.descript}:</label>
                  <p>
                    {formatToDecimal(paymentsSalesData[index]?.tender)}
                  </p>
                </div>
              );
            })}
            <div className="total-chart">
              <label htmlFor="">Total:</label>
              <p>{formatToDecimal(getTotal())}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="no-data">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
        </div>
      );
    }
  } else {
    return (
      <div className="no-data">
        <FontAwesomeIcon className="icon" icon={faEyeSlash} />
      </div>
    );
  }
}

export default PiePaymentGraph;
