import React from "react";
import { fullDateFormat } from "../utils/dateFormat/dateFormat";

const MembershipStatus = ({ expirationDate, HandleToggle }) => {

  function closeToggle() {
    return HandleToggle(false);
  }

  return (
    <section className="membership-section">
      <div className="background" />
      <div className="membership-container">
        <h2>Estatus de membresía</h2>
        <p>Estimado usuario/a</p>
        <p className="description">
          Su membresía está por terminar, le recordamos contactarse con los
          administradores para así proceder con la renovación.
        </p>
        <p>
          Fin de membresía:{" "}
          <strong>
            <em>{fullDateFormat(expirationDate, 'dd/mm/yyyy')}</em>{" "}
          </strong>
        </p>
        <button onClick={closeToggle}>Ententido</button>
      </div>
    </section>
  );
};

export default MembershipStatus;
