import { Line } from "react-chartjs-2";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";

function LineClients({ clientsData, date }) {
  const getDays = () => {
    if (!clientsData) return [];
    const days = clientsData.map((data) => {
      const date = new Date(data?.opendate);
      const day = date.getUTCDate();
      return day;
    });
    return days;
  };
  const data = {
    labels: getDays(),
    datasets: [
      {
        label: "Clientes por día",
        data: clientsData ? clientsData.map((data) => data?.numcust) : [0],
        backgroundColor: "#77dd77",
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="bar-chart">
      <h4>
        Clientes del {dayAndMonthFormat(date?.from)} al{" "}
        {dayAndMonthFormat(date?.to)}
      </h4>
      <Line className=" chart" data={data} />
    </div>
  );
}

export default LineClients;
