export function getTotal(dataArray) {
  function sumTotal() {
    if(!dataArray) return
    const total = { nettotal: 0, final: 0 };
    dataArray.forEach((data) => { 
      total.nettotal += data?.nettotal;
      total.final += data?.total;
    });
    return total
  }

  return {
    total: sumTotal()
  };
}
