import React, { useEffect, useState } from "react";
import FiltersHeading from "./components/FilterHeading";
import TableContent from "./components/TableContent";
import useGetInventoryConsume from "./hooks/useGetInventoryConsume";
import useDateFilter from "../../../hooks/useDateFilter";
import useAuth from "../../../hooks/useAuth";
import Pagination from "../../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useGetInventoryConsumeSummary from "./hooks/useGetInventoryConsumeSummary";
import TableContentSummary from "./components/TableContentSummary";
import useToggles from "./hooks/useToggles";

const InventoryConsume = () => {
  const { auth } = useAuth();
  const limit = 300;
  const [resetPage, setResetPage] = useState(false);
  const { date, HandleDateChange } = useDateFilter("", "");
  const { data, loading, HandleSearch, HandlePage } = useGetInventoryConsume({
    first_date: date.from,
    second_date: date.to,
    limit,
  });
  const { data: summary, loading: loadingSummary, HandleSearch: HandleSearchSummary } = useGetInventoryConsumeSummary({
    first_date: date.from,
    second_date: date.to
  });

  const {toggles, HandleToggle} = useToggles()


  const inventoryData = data.rows ? data.rows : [];
  const summaryData = summary.length > 0 ? summary : [];

  function HandleSearches() {
    HandleSearch();
    HandleSearchSummary()
  }

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
    HandleResetPage();
  }, [auth]);

  return (
    <section className="inventory-consume-section">
      <FiltersHeading
        date={date}
        toggles={toggles}
        registerCount={data.count}
        HandleSearches={HandleSearches}
        HandleToggle={HandleToggle}
        HandleDateChange={HandleDateChange}
      />
      
      {!loading && !toggles.summary && inventoryData.length > 0 && (
        <TableContent data={inventoryData} />
      )}

      {toggles.summary && !loadingSummary  && summaryData.length > 0 && <TableContentSummary data={summaryData}/>}

      {loading && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}

      {!loading && !(inventoryData.length > 0) && (
        <div className="nothing-to-show">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          <h4>Nada por mostrar</h4>
        </div>
      )}
      <Pagination
        itemsCount={data?.count}
        itemsPerPage={limit}
        loading={loading}
        hasData={data.rows?.length > 0 && !toggles.summary}
        resetPage={resetPage}
        HandlePage={HandlePage}
      />
    </section>
  );
};

export default InventoryConsume;
