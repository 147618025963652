import React from "react";
import { BranchSelect } from "../../../../components/Selects/Selects";
import { fullDateFormat } from "../../../../utils/dateFormat/dateFormat";

const Menu = (props) => {
  return (
    <section className="menu-container">
      <h2 className="title">Variación de Inventario</h2>
      <div className="date-container">
        <div>
          <label htmlFor="">Sucursal: </label>
          <BranchSelect className="select" noAddAllBranches={true} />
        </div>
        <div>
          <label htmlFor="">Desde: </label>
          <input
            className="date-input"
            type="date"
            value={props.rangeDate?.from}
            onChange={(e) => {
              props.HandleRageDateChange(e, {
                property: "from",
              });
            }}
          />
        </div>
        <div>
          <label htmlFor="">Hasta: </label>
          <input
            className="date-input"
            type="date"
            value={props.rangeDate?.to}
            onChange={(e) => {
              props.HandleRageDateChange(e, {
                property: "to",
              });
            }}
          />
        </div>
      </div>
      <div className="table-log">
        <table>
          <thead>
            <tr>
              <th>Selección</th>
              <th>Sucursal</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((data) => {
              if (!data.has_conteo) return <></>;
              return (
                <tr key={data.id}>
                  <td>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={props.select.includes(data)}
                      onClick={() => {
                        props.HandleSelect(data);
                      }}
                      onChange={() => {}}
                    />
                  </td>
                  <td>{data.sucursale.NOMBRE_SUCURSAL}</td>
                  <td>{fullDateFormat(data.createdAt, "dd/mm/yyyy")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="button-container">
        <button
          disabled={props.select.length > 1 ? false : true}
          className="button-18 "
          onClick={() => {
            props.HandleToggle("variacion", true)
          }}
        >
          Comprobar existencias
        </button>
      </div>
    </section>
  );
};

export default Menu;
