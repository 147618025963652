import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarForm from "./SidebarForm";
import useLogOut from "../../hooks/useLogout";
import { faDatabase, faHouse, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import useSelectBranch from "../../hooks/useSelectBranch";
import Dialog from "./components/Dialog.jsx";
import { usersOnlyInventory } from "../../assets/usersAccess.js";

const SidebarIndex = () => {
  const data = [
    {
      id: 1,
      title: "Dashboard",
      icon: faHouse,
      route: "/",
    },
    {
      id: 2,
      title: "Reportes",
      icon: faPaperclip,
      collapse: true,
      withElements: true,
      elements: [
        {
          title: "Ncf",
          route: "/report/ncf/607",
          active: true,
        },
        {
          title: "Transacciones",
          route: "/report/transactions",
          active: true,
        },
        {
          title: "Pagos",
          route: "/report/payments",
          active: true,
        },
        {
          title: "Ventas Productos",
          route: "/report/sales/product",
          active: true,
        },
        {
          title: "Facturación Electrónica",
          route: "/report/electronic-invoice",
          active: false,
        },
      ],
    },
    {
      id: 3,
      title: "Inventario",
      icon: faDatabase,
      collapse: true,
      withElements: true,
      validBusiness: [3],
      elements: [
        {
          title: "Inventario de Productos",
          route: "/inventory",
          active: true,
        },
        {
          title: "Inventario de Consumo",
          route: "/inventory/consume",
          active: true,
        },
        {
          title: "Variación de Inventario",
          route: "/inventory/variacion",
          active: true,
        }
      ],
    },
  ];

  
  const [collapse, setCollapse] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [sidebarData, setSidebarData] = useState(data);
  
  const logout = useLogOut();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const onlyInventory = usersOnlyInventory.includes(auth?.username)

  const businessId = auth?.business?.ID_NEGOCIO;

  const { getFirstBranch } = useSelectBranch();

  function ToggleElementsCollapse(index) {
    if (collapse) return;
    const currentData = sidebarData[index];
    currentData.collapse = !currentData?.collapse;

    const filterData = sidebarData.filter((data) => {
      return data?.id !== currentData?.id;
    });

    filterData.push(currentData);

    const organizeArray = filterData.sort((d1, d2) => {
      return d1?.id - d2?.id;
    });

    return setSidebarData(organizeArray);
  }

  function ToggleCollapse(bool) {
    const closeCollapse = () => {
      return sidebarData.map((data) => {
        return { ...data, collapse: true };
      });
    };
    setSidebarData(closeCollapse());
    setCollapse(bool);
  }

  function ToggleOpenSidebar() {
    return setCollapse(false);
  }

  function HandleClickRoute(route) {
    if (auth?.selectedBranch === "Todos") {
      getFirstBranch();
    }
    navigate(route, {
      secure: true,
    });
    ToggleCollapse(true);
  }

  function ToggleDialog(bool) {
    return setDialog(bool);
  }

  return (
    <>
      {dialog && <Dialog ToggleDialog={ToggleDialog} />}
      <SidebarForm
        businessId={businessId}
        sidebarData={sidebarData}
        collapse={collapse}
        onlyInventory={onlyInventory}
        logout={logout}
        ToggleCollapse={ToggleCollapse}
        ToggleDialog={ToggleDialog}
        ToggleElementsCollapse={ToggleElementsCollapse}
        ToggleOpenSidebar={ToggleOpenSidebar}
        HandleClickRoute={HandleClickRoute}
      />
    </>
  );
};

export default SidebarIndex;
