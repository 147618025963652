import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";

const useGetNcfCount = () => {
  const { auth } = useAuth();

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  
  const endpoint = `/business/${businessId}/branch/${selectedBranch}/ncf/countbook`;

  const { data, loading } = useGetData({
    url: endpoint,
  });

  return { data, loading };
};

export default useGetNcfCount;
