import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/LayoutIndex";
import RequireAuth from "./components/RequireAuth";
import { Toaster } from "react-hot-toast";
import TransactionsOld from "./pages/Report/Transactions old/TransactionsIndex";
import Transactions from "./pages/Report/Transactions/Index";
import NCF607 from "./pages/Report/NCF607/Index";
import ElectronicInvoice from "./pages/Report/ElectronicInvoice/Index";
import Ncf607old from "./pages/Report/Ncf 607 old/Ncf607Index";
import SalesProduct from "./pages/Report/SalesProduct/Index";
import Payments from "./pages/Report/Payments/Index";
import Home from "./pages/Home/Index";
import HomeOld from "./pages/Home old/HomeIndex";
import Login from "./pages/Login/LoginIndex";
import Inventory from "./pages/Inventory/Index";
import Inventory2 from "./pages/Inventory2/Index";
import InventoryConsume from "./pages/Inventory/InventoryConsume/Index";
import InventoryVariacion from "./pages/Inventory/InventoryVariacion/Index";
import { useEffect } from "react";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/old" element={<HomeOld />} />
            {/* <Route path="/inventory" element={<Inventory />} /> */}
            <Route path="/inventory" element={<Inventory2 />} />
            <Route path="/inventory/consume" element={<InventoryConsume />} />
            <Route path="/inventory/variacion" element={<InventoryVariacion />} />
            <Route
              path="/report/transactions/old"
              element={<TransactionsOld />}
            />
            <Route path="/report/transactions" element={<Transactions />} />
            <Route path="/report/transactions/:transact_id?" element={<Transactions />} />
            <Route path="/report/ncf/607/old" element={<Ncf607old />} />
            <Route path="/report/ncf/607" element={<NCF607 />} />
            <Route path="/report/electronic-invoice" element={<ElectronicInvoice />} />
            <Route path="/report/sales/product" element={<SalesProduct />} />
            <Route path="/report/payments" element={<Payments />} />
          </Route>
        </Route>
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
