import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useQueryClient } from "react-query";

const OrderDetailModal = (props) => {
  const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;
  const queryClient = useQueryClient();

  const tabletTitleScroll = useRef(null);
  const tableContentScroll = useRef(null);

  const [toggles, setToggles] = useState({
    showEmployee: false,
  });

  const [indexArray, setIndexArray] = useState(props.transaction.indexArray);
  const transactionDetail = !(props.filterTransact?.data.length > 0)
    ? props.transactionData[indexArray]
    : props.filterTransact.data[indexArray];

  const itbis = transactionDetail?.tax1;
  const tips = transactionDetail?.tax5;
  const subtotal = transactionDetail?.nettotal;
  const total = transactionDetail?.total;

  const {
    isFetching,
    status,
    error,
    data: orderDetailData,
  } = useQuery("dataDetailOrder", () => {
    return fetch(
      `${BASE_URL}/business/1/branch/1/orders/transact/${transactionDetail.transact}`
    ).then((res) => res.json());
  });

  //
  function handleRefetch() {
    return queryClient.fetchQuery("dataDetailOrder");
  }

  function SumAllDiscount() {
    try {
      let discounts = 0;
      orderDetailData?.discounts?.forEach((discount) => {
        discounts += Math.abs(discount?.monto);
      });
      return discounts;
    } catch (error) {}
  }

  function TotalPrice(qty, price) {
    return (qty * price).toFixed(2);
  }

  function ToggleEmployee() {
    return setToggles({ ...toggles, showEmployee: !toggles.showEmployee });
  }

  function NextTransact() {
    if (props.filterTransact?.data.length > 0) {
      if (indexArray + 1 < props.filterTransact?.data.length) {
        let index = indexArray + 1;
        return setIndexArray(index);
      }
    }
    else {
      if (indexArray + 1 < props.transactionData.length) {
        let index = indexArray + 1;
        return setIndexArray(index);
      }
    }
  }

  function PreviousTransact() {
    if (indexArray > 0) {
      let index = indexArray - 1;
      setIndexArray(index);
    }
  }

  const handleTableScroll = (event) => {
    const { scrollTop, scrollLeft } = event.target;
    tabletTitleScroll.current.scrollLeft = scrollLeft;
    tableContentScroll.current.scrollTop = scrollTop;
  };

  function formatDate(currentDate) {
    const date = new Date(currentDate);
    const day =
      date.getUTCDate() < 9 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;
    const month =
      date.getUTCMonth() + 1 < 9
        ? `0${date.getUTCMonth() + 1}`
        : `${date.getUTCMonth() + 1}`;
    const year = date.getUTCFullYear();
    const hours =
      date.getUTCHours() < 9
        ? `0${date.getUTCHours()}`
        : `${date.getUTCHours()}`;
    const minutes =
      date.getUTCMinutes() < 9
        ? `0${date.getUTCMinutes()}`
        : `${date.getUTCMinutes()}`;
    const seconds =
      date.getUTCSeconds() < 9
        ? `0${date.getUTCSeconds()}`
        : `${date.getUTCSeconds()}`;

    const newdate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return newdate;
  }

  function ShowWhoAuthorize(data) {
    if (data?.precio > 0) return "";
    return data?.empname2;
  }

  useEffect(() => {
    handleRefetch();
  }, [indexArray]);

  return (
    <>
      <div
        className="background"
        onClick={() => {
          // HandleModalClose()
          props.ToggleOrderDetailModal(false);
        }}
      ></div>
      <section className="order-detail-modal">
        <h4 className="opendate">
          Opendate: {props.formatDate(transactionDetail?.opendate)}
        </h4>
        <div className="checks">
          <input type="checkbox" onChange={ToggleEmployee} />
          <label htmlFor="">More Details</label>
        </div>
        <h3>Detail Order - Transact - {transactionDetail?.transact}</h3>
        <div className="container">
          {!toggles.showEmployee ? (
            <>
              <div className="transaction-details-title">
                <h4 className="title center">Quantity</h4>
                <h4 className="title">Description</h4>
                <h4 className="title right">Total</h4>
              </div>
              <div className="transaction-details-container">
                {status === "loading" || isFetching || error ? (
                  <div className="transaction-details-container">
                    <div className="skeleton-transaction-item skeleton"></div>
                    <div className="skeleton-transaction-item skeleton"></div>
                    <div className="skeleton-transaction-item skeleton"></div>
                  </div>
                ) : (
                  orderDetailData?.orders?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          data?.precio > 0
                            ? "transaction-details-items"
                            : "transaction-details-items delete-item"
                        }
                      >
                        <p className="center">{data?.cantidad}</p>
                        <p className="descript">{data?.descript}</p>
                        <p className="right">
                          {TotalPrice(data?.cantidad, data?.precio)}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          ) : (
            <>
              <div
                ref={tabletTitleScroll}
                className="transaction-details-title-more-details"
              >
                <h4 className="title">Employee</h4>
                <h4 className="title center">Unique Id</h4>
                <h4 className="title">Time Order</h4>
                <h4 className="title center">Prodnum</h4>
                <h4 className="title center">Quantity</h4>
                <h4 className="title">Who authorize</h4>
                <h4 className="title">Description</h4>
                <h4 className="title right">Total</h4>
              </div>
              <div
                ref={tableContentScroll}
                onScroll={handleTableScroll}
                className="transaction-details-container"
              >
                {status === "loading" || isFetching || error ? (
                  <div className="transaction-details-container">
                    <div className="skeleton-transaction-item skeleton"></div>
                    <div className="skeleton-transaction-item skeleton"></div>
                    <div className="skeleton-transaction-item skeleton"></div>
                  </div>
                ) : (
                  orderDetailData?.orders?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          data?.precio > 0
                            ? "transaction-details-items-more-details"
                            : "transaction-details-items-more-details delete-item"
                        }
                      >
                        <p className="person">{data?.empname1}</p>
                        <p className="center">{data?.uniqueid}</p>
                        <p className="center">{formatDate(data?.timeord)}</p>
                        <p className="center prodnum">{data?.prodnum}</p>
                        <p className="center">{data?.cantidad}</p>
                        <p className="center">{ShowWhoAuthorize(data)}</p>
                        <p className="descript">{data?.descript}</p>
                        <p className="right">
                          {TotalPrice(data?.cantidad, data?.precio)}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
          <footer>
            <div className="payment-method">
              <h4>Payment method: </h4>
              <div className="container">
                {orderDetailData?.payment_methods?.map((method, index) => {
                  return (
                    <div key={index} className="details">
                      <p>
                        {method?.descript}: {method?.tender.toFixed(2)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="prices">
              <hr />
              <div className="discount">
                <h4>Total discounts: </h4>
                <p>{SumAllDiscount().toFixed(2)}</p>
              </div>
              <div className="subtotal">
                <h4>Subtotal: </h4>
                <p>{subtotal.toFixed(2)}</p>
              </div>
              <div className="tips">
                <h4>Tips: </h4>
                <p>{tips.toFixed(2)}</p>
              </div>
              <div className="itbis">
                <h4>itbis %: </h4>
                <p>{itbis.toFixed(2)}</p>
              </div>
              <div className="total">
                <h4>Total: </h4>
                <p>{total.toFixed(2)}</p>
              </div>
            </div>
          </footer>
          <div className="buttons">
            <FontAwesomeIcon
              onClick={PreviousTransact}
              icon={faArrowLeft}
              className="icon left"
            />
            <FontAwesomeIcon
              onClick={NextTransact}
              icon={faArrowRight}
              className="icon right"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetailModal;
