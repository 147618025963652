import React from "react";

const Dialog = (props) => {
  return (
    <>
    <div className="background"></div>
      <section className="dialog-message-sidebar">
        <h2>Acesso Denegado</h2>
        <p>
          De momento, no cuenta con este reporte. Para habilitarlo, por favor
          contactar con alguno de los administradores.
        </p>
        <button onClick={() => {
          props.ToggleDialog(false)
        }}>Aceptar</button>
      </section>
    </>
  );
};

export default Dialog;
