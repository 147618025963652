import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetSalesProduct = ({ first_date, second_date, limit }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);
  const [page, setPage] = useState(0);

  const [salesProduct, setSalesProduct] = useState([]);

  const isNullValue = !first_date || !second_date ? true : false;

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  
  const endpoint = `business/${businessId}/branch/${selectedBranch}/sales/product/detail/firstdate/${first_date}/seconddate/${second_date}/limit/${limit}/page/${page}`;
  
  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });

  function getCategoryWithProducts() {
    const categories = new Set(data?.rows?.map((data) => data?.category));
    const categoriesWithProducts = [];
    categories.forEach((category) => {
      const products = [];
      let Sum = {
        qty: 0,
        total: 0,
      };
      data?.rows.forEach((data) => {
        if (!(category === data.category)) return;
        products.push(data);
        Sum.qty += data?.cantidad;
        Sum.total += data?.total;
      });
      categoriesWithProducts.push({ category, products, info: Sum });
    });
    return setSalesProduct(categoriesWithProducts);
  }

  function HandlePage(page) {
    return setPage(page), setSearch(true);
  }

  function HandleSearch() {
    if (isNullValue) {
      return toast.error("Fecha inválida");
    }
    return setSearch(true);
  }

  useEffect(() => {
    if (!data?.rows?.length > 0) return setSalesProduct([]), setSearch(false);
    getCategoryWithProducts();
    return setSearch(false);
  }, [data]);

  return { data, salesProduct, loading: loading, HandleSearch, HandlePage };
};

export default useGetSalesProduct;
