import { useState } from "react";

const useToggles = () => {
  const [toggles, setToggles] = useState({
    detailOrder: false,
    detailOrderMore: false
  });

  function HandleDetailOrderMoreToggle(bool) {
    return setToggles({ ...toggles, detailOrderMore: bool });
  }

  function HandleDetailOrderToggle() {
    return setToggles({ detailOrderMore: false, detailOrder: !toggles?.detailOrder });
  }

  return { toggles, HandleDetailOrderToggle, HandleDetailOrderMoreToggle };
};

export default useToggles;
