import React, { useEffect, useState } from "react";
import HomeForm from "./HomeForm";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import BarSalesGraph from "./components/graphs/BarSales";
import LineClientsGraph from "./components/graphs/LineClients";
import PieCategoryGraph from "./components/graphs/PieCategory";
import BarSalesCategoriesGraph from "./components/graphs/BarSalesSections";
import PiePaymentGraph from "./components/graphs/PiePayments";
import { getDateFromSelect } from "../../utils/dateFormat/dateFormat";
import useGetData from "../../hooks/useGetData";
import { Notification } from "../../components/Notification/Index";
import HomeMultibusinessForm from "./HomeMultibusinessForm";
import useSelectBranch from "../../hooks/useSelectBranch";

ChartJS.register(
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const HomeIndex = () => {
  const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  // const business = auth?.business[0]?.ID_NEGOCIO

  const business = JSON.parse(auth?.selectedBusiness)
  const businessId = business?.ID_NEGOCIO;
  const businessName = auth?.business?.NOMBRE_NEGOCIO;

  const selectedBranch = auth?.selectedBranch

  const refetch = auth?.refetch

  const branches = [];
  // const branches = auth?.branches;

  const queryClient = useQueryClient();

  const date = new Date();

  const initialDate = () => {
    const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const day =
      initialDate.getDate() > 9
        ? `${initialDate.getDate()}`
        : `0${initialDate.getDate()}`;
    const month =
      initialDate.getMonth() + 1 > 9
        ? `${initialDate.getMonth() + 1}`
        : `0${initialDate.getMonth() + 1}`;
    const year = initialDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const currentDate = () => {
    const day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
    const month =
      date.getMonth() + 1 > 9
        ? `${date.getMonth() + 1}`
        : `0${date.getMonth() + 1}`;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const diffDays = () => {
    const dateTo = new Date(dateFilter.to);
    const dateFrom = new Date(dateFilter.from);
    const diffMiliseconds = dateTo - dateFrom;
    const milisecondsToDays = 1000 * 60 * 60 * 24;
    const diffDays = Math.round(diffMiliseconds / milisecondsToDays + 1);
    return diffDays;
  };

  const [cardsInfo, setCardsInfo] = useState(null);
  const [toggles, setToggles] = useState({
    datefilter: false,
    allBranchs: false,
  });
  const [dateFilter, setDateFilter] = useState({
    from: currentDate(),
    to: currentDate(),
  });

  const [dashboardData, setDashboardData] = useState([]);

  // const [selectedBranch, setSelectedBranch] = useState(
  //   auth?.isMultibusiness ? [] : auth?.branches[0]?.ID_SUCURSAL
  // );

  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState(false);

  const { data: salesData, loading } = useGetData({
    url: `${BASE_URL}/business/${businessId}/sales/summary/firstdate/${dateFilter.from}/seconddate/${dateFilter.to}`,
    refresh,
    search,
  });

  function HandleSearch() {
    return setSearch(true);
  }

  useEffect(() => {
    setSearch(false);
  }, [salesData]);

  function HandleRefresh() {
    return setRefresh(!refresh);
  }

  function GoToTransactions() {
    return navigate("report/transactions");
  }

  ////////////////////////////////////////////////////////////////////////

  function getTotalSales() {
    let totalSales = 0;
    let totalOpenSales = 0;
    let totalNetSales = 0;
    let totalITBIS = 0;
    let totalTips = 0;
    let totalDiscounts = 0;
    let totalClients = 0;
    let totalTransacts = 0;
    let totalOpenTransacts = 0;
    let totalCancelattions = 0;

    dashboardData.forEach((data) => {
      totalSales += data?.total;
      totalOpenSales += data?.ventasbiertas;
      totalNetSales += data?.nettotal;
      totalITBIS += data?.tax1;
      totalTips += data?.tax5;
      totalDiscounts += data?.descuentos;
      totalClients += parseInt(data?.numcust);
      totalTransacts += parseInt(data?.trans);
      totalOpenTransacts += parseInt(data?.transabiertas);
      totalCancelattions += parseInt(data?.anulaciones);
    });

    let object = {
      totalSales: totalSales + totalOpenSales,
      totalOpenSales,
      totalNetSales,
      totalITBIS,
      totalTips,
      totalDiscounts: Math.abs(totalDiscounts),
      totalClients,
      totalTransacts: totalTransacts + totalOpenTransacts,
      totalOpenTransacts,
      totalCancelattions: Math.abs(totalCancelattions),
    };

    return setCardsInfo(object);
  }
  
  //////////////////////////Handlers ///////////////////////////////////////

  function HandleSelectDateFilter(e) {
    const option = e.target.value;

    if (option === "default") {
      HandleDateToday();
      HandleSearch();
      return setToggles({ ...toggles, datefilter: false });
    }

    if (option === "yesterday") {
      HandleDateYesterday();
      HandleSearch();
      return setToggles({ ...toggles, datefilter: false });
    }

    if (option === "month") {
      HandleDateThisMonth();
      HandleSearch();
      return setToggles({ ...toggles, datefilter: false });
    }

    if (option === "select-date") {
      return setToggles({ ...toggles, datefilter: true });
    }
  }

  function handleRefetch() {
    return (
      HandleRefresh(),
      queryClient.fetchQuery("salesData"),
      queryClient.fetchQuery("clientsData"),
      queryClient.fetchQuery("categoriesSummaryData"),
      queryClient.fetchQuery("placesData"),
      queryClient.fetchQuery("paymentsSalesData")
    );
  }

  function DisableAuthRefetch() {
    return setAuth({...auth, refetch: false})
  }

  function HandleDateToday() {
    setDateFilter({
      from: currentDate(),
      to: currentDate(),
      refetch: true,
    });
  }

  function HandleDateYesterday() {
    setDateFilter({
      from: getDateFromSelect("Ayer"),
      to: currentDate(),
      refetch: true,
    });
  }

  function HandleDateThisMonth() {
    setDateFilter({
      from: initialDate(),
      to: currentDate(),
      refetch: true,
    });
  }

  function HandleDateState(option, e) {
    if (option === "from") {
      return setDateFilter({
        ...dateFilter,
        from: e.target.value,
        refetch: false,
      });
    } else {
      return setDateFilter({
        ...dateFilter,
        to: e.target.value,
        refetch: false,
      });
    }
  }

  function HandleClickDateBtn() {
    HandleSearch();
    return handleRefetch();
  }

  function HandleSelectBranch(e) {
    const value = e.target.value;
    if (value === "all") {
      // return (
      //   setToggles({ ...toggles, allBranchs: true }), setSelectedBranch("all")
      // );
    }

    // setSelectedBranch(parseInt(value));
    setToggles({ ...toggles, allBranchs: false });
    // handleRefetch();
  }

  function FilterByBranchData() {
    if (!salesData) return;
    if (selectedBranch === "all") return setDashboardData(salesData);
    const filterByBranch = salesData.filter((data) => {
      return data?.id_sucursal === parseInt(selectedBranch);
    });
    return setDashboardData(filterByBranch);
  }

  useEffect(() => {
    if(!refetch) return
    DisableAuthRefetch()
    HandleSearch()
    handleRefetch()
  }, [auth])

  useEffect(() => {
    getTotalSales();
  }, [dashboardData]);

  useEffect(() => {
    if (!salesData) return;
    FilterByBranchData();
  }, [salesData]);

  useEffect(() => {
    FilterByBranchData();
    handleRefetch();
  }, [selectedBranch]);

  useEffect(() => {
    if (!dateFilter.refetch) return;
    handleRefetch();
  }, [dateFilter]);

  useEffect(() => {
    if (!loading) return;
    // toast("Buscando información...")
  }, [loading]);

  return (
    <>
      <Notification isActive={loading} message={"Buscando información..."} />

      {auth?.isMultibusiness ? (
        <HomeMultibusinessForm
          businessId={businessId}
          selectedBranch={selectedBranch}
          multibusiness={auth?.multibusiness}
          businessName={businessName}
          diffDays={diffDays}
          dashboardData={dashboardData}
          GoToTransactions={GoToTransactions}
          BarSalesCategoriesGraph={BarSalesCategoriesGraph}
          LineClientsGraph={LineClientsGraph}
          PieCategoryGraph={PieCategoryGraph}
          PiePaymentGraph={PiePaymentGraph}
          getTotalSales={getTotalSales}
          cardsInfo={cardsInfo}
          branches={branches}
          toggles={toggles}
          dateFilter={dateFilter}
          BarSalesGraph={BarSalesGraph}
          HandleSelectDateFilter={HandleSelectDateFilter}
          HandleDateState={HandleDateState}
          HandleClickDateBtn={HandleClickDateBtn}
          HandleSelectBranch={HandleSelectBranch}
          HandleDateToday={HandleDateToday}
          HandleDateThisMonth={HandleDateThisMonth}
          HandleSearch={HandleSearch}
        />
      ) : (
        <HomeForm
          businessId={businessId}
          selectedBranch={selectedBranch}
          multibusiness={auth?.multibusiness}
          businessName={businessName}
          diffDays={diffDays}
          dashboardData={dashboardData}
          GoToTransactions={GoToTransactions}
          BarSalesCategoriesGraph={BarSalesCategoriesGraph}
          LineClientsGraph={LineClientsGraph}
          PieCategoryGraph={PieCategoryGraph}
          PiePaymentGraph={PiePaymentGraph}
          getTotalSales={getTotalSales}
          cardsInfo={cardsInfo}
          branches={branches}
          toggles={toggles}
          dateFilter={dateFilter}
          BarSalesGraph={BarSalesGraph}
          HandleSelectDateFilter={HandleSelectDateFilter}
          HandleDateState={HandleDateState}
          HandleClickDateBtn={HandleClickDateBtn}
          HandleSelectBranch={HandleSelectBranch}
          HandleDateToday={HandleDateToday}
          HandleDateThisMonth={HandleDateThisMonth}
          HandleSearch={HandleSearch}
        />
      )}
    </>
  );
};

export default HomeIndex;
