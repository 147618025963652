import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import PieSectionsGraph from "./components/graphs/PieSections";
import { formatToDecimal } from "../../utils/formatToDecimal/formatToDecimal";
import { formatInteger } from "../../utils/formatToDecimal/formatInteger";
import { BusinessSelect, BranchSelect } from "../../components/Selects/Selects";

const HomeMultibusinessForm = (props) => {
  return (
    <>
      <div className="background-dashboard"></div>
      <section className="home-section">
        <div className="home-container">
          <div className="heading">
            <h2>Dashboard's Multinegocios {props.businessName}</h2>
            <hr />
          </div>
          <div className="filters-container">
            <div
              className={
                !props.toggles?.datefilter
                  ? "filter remove-margin-bottom"
                  : "filter add-margin-bottom"
              }
            >
              <label htmlFor="">Filtrar por fecha</label>
              <FontAwesomeIcon icon={faCalendar} className="icon-calendar" />
              <select onChange={props.HandleSelectDateFilter} name="" id="">
                <option onClick={props.HandleDateToday} value="default">
                  Hoy
                </option>
                <option onClick={props.HandleDateToday} value="yesterday">
                  Ayer
                </option>
                <option onClick={props.HandleDateThisMonth} value="month">
                  Este mes
                </option>
                <option value="select-date">Seleccionar fecha</option>
              </select>
              {props.toggles?.datefilter ? (
                <div className="filter-date">
                  <div className="from">
                    <label htmlFor="">Desde</label>
                    <input
                      onChange={(e) => {
                        props.HandleDateState("from", e);
                      }}
                      type="date"
                      value={props.dateFilter.from}
                    />
                  </div>
                  <div className="to">
                    <label htmlFor="">Hasta</label>
                    <input
                      onChange={(e) => {
                        props.HandleDateState("to", e);
                      }}
                      type="date"
                      value={props.dateFilter.to}
                    />
                  </div>
                  <button
                    onClick={props.HandleClickDateBtn}
                    className="icon-btn"
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="filter">
              <label htmlFor="">Filtrar por negocio</label>
              <BusinessSelect />
            </div>
            {/* <div className="filter">
              <label htmlFor="">Filtrar por negocio</label>
              <select onChange={props.HandleSelectBranch} name="" id="">
                {props.business?.map((branch, index) => {
                  return (
                    <option key={index} value={branch?.ID_SUCURSAL}>
                      {branch?.NOMBRE_NEGOCIO}
                    </option>
                  );
                })}
                <option value="all">Todos</option>
              </select>
            </div> */}
            <div className="filter">
              <label htmlFor="">Filtrar por sucursal</label>
              <BranchSelect />
            </div>
            {/* <div className="filter">
              <label htmlFor="">Filtrar por sucursal</label>
              <select onChange={props.HandleSelectBranch} name="" id=""> */}
                {/* {props.branches?.map((branch, index) => {
                  return (
                    <option key={index} value={branch?.ID_SUCURSAL}>
                      {branch?.NOMBRE_SUCURSAL}
                    </option>
                  );
                })} */}
                {/* <option value="all">Todas</option>
              </select>
            </div> */}
          </div>
          <div className="cards-container">
            <div className="card-info">
              <label htmlFor="">Ventas Netas</label>
              <h4>{props.cardsInfo?.totalNetSales.toLocaleString("en-US")}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">ITBIS 18%</label>
              <h4>{props.cardsInfo?.totalITBIS.toLocaleString("en-US")}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">10% Ley</label>
              <h4>{props.cardsInfo?.totalTips.toLocaleString("en-US")}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Ventas Abiertas</label>
              <h4>{props.cardsInfo?.totalOpenSales.toLocaleString("en-US")}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Total Ventas</label>
              <h4 className="green">
                {props.cardsInfo?.totalSales.toLocaleString("en-US")}
              </h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Clients</label>
              <h4>{props.cardsInfo?.totalClients}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Transacciones Abiertas</label>
              <h4>{props.cardsInfo?.totalOpenTransacts}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Transacciones</label>
              <h4>{props.cardsInfo?.totalTransacts}</h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Descuentos</label>
              <h4 className="red">
                {props.cardsInfo?.totalDiscounts.toLocaleString("en-US")}
              </h4>
              <p>vs {props.diffDays()} days</p>
            </div>
            <div className="card-info">
              <label htmlFor="">Cancellations</label>
              <h4 className="red">
                {props.cardsInfo?.totalCancelattions.toLocaleString("en-US")}
              </h4>
              <p>vs {props.diffDays()} days</p>
            </div>
          </div>

          <div className="chart-container animation-show-chart">
            <props.BarSalesGraph
              dashboardData={props.dashboardData}
              businessId={props.businessId}
              from={props.dateFilter?.from}
              to={props.dateFilter?.to}
              selectedBranch={props.selectedBranch}
            />

            {!props.toggles.allBranchs && (
              <div className="pie-container">
                <PieSectionsGraph
                  businessId={props.businessId}
                  from={props.dateFilter?.from}
                  to={props.dateFilter?.to}
                  selectedBranch={props.selectedBranch}
                />
              </div>
            )}
          </div>

          {!props.toggles.allBranchs && (
            <div className="chart-container animation-show-chart">
              <div className="pie-container">
                <props.PieCategoryGraph
                  businessId={props.businessId}
                  from={props.dateFilter?.from}
                  to={props.dateFilter?.to}
                  selectedBranch={props.selectedBranch}
                />
              </div>
              <props.LineClientsGraph
                businessId={props.businessId}
                from={props.dateFilter?.from}
                to={props.dateFilter?.to}
                selectedBranch={props.selectedBranch}
              />
            </div>
          )}

          {!props.toggles.allBranchs && (
            <div
              className={
                !props.toggles.allBranchs
                  ? "chart-container animation-show-chart"
                  : "chart-container hide-show-chart"
              }
            >
              <div className="pie-container">
                <props.PiePaymentGraph
                  businessId={props.businessId}
                  from={props.dateFilter?.from}
                  to={props.dateFilter?.to}
                  selectedBranch={props.selectedBranch}
                />
              </div>
            </div>
          )}
          <div className="power-by">Powered By: Blue Logistic Services</div>
        </div>
      </section>
      <section className="home-section-mobile">
        <h2 className="title">Dashboard's Casa</h2>
        <div className="filters-container">
          <div
            className={
              !props.toggles?.datefilter
                ? "filter remove-margin-bottom"
                : "filter add-margin-bottom"
            }
          >
            <label htmlFor="">Filtrar por fecha: </label>
            <select onChange={props.HandleSelectDateFilter} name="" id="">
              <option onClick={props.HandleDateToday} value="default">
                Hoy
              </option>
              <option onClick={props.HandleDateToday} value="yesterday">
                Ayer
              </option>
              <option onClick={props.HandleDateThisMonth} value="month">
                Este mes
              </option>
              <option value="select-date">Seleccionar fecha</option>
            </select>
            {props.toggles?.datefilter ? (
              <div className="filter-date">
                <div>
                  <div className="from">
                    <label htmlFor="">Desde: </label>
                    <input
                      onChange={(e) => {
                        props.HandleDateState("from", e);
                      }}
                      type="date"
                      value={props.dateFilter.from}
                    />
                  </div>
                  <div className="to">
                    <label htmlFor="">Hasta: </label>
                    <input
                      onChange={(e) => {
                        props.HandleDateState("to", e);
                      }}
                      type="date"
                      value={props.dateFilter.to}
                    />
                  </div>
                </div>
                <button onClick={props.HandleClickDateBtn} className="icon-btn">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="filter">
            <label htmlFor="">Filtrar por sucursal: </label>
            <select onChange={props.HandleSelectBranch} name="" id="">
              {/* {props.branches?.map((branch, index) => {
                return (
                  <option key={index} value={branch?.ID_SUCURSAL}>
                    {branch?.NOMBRE_SUCURSAL}
                  </option>
                );
              })} */}
              <option value="all">Todas</option>
            </select>
          </div>
        </div>
        <div className="container">
          <div className="item">
            <label htmlFor="">Ventas Netas: </label>
            <h4>{formatToDecimal(props.cardsInfo?.totalNetSales)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Itbis: </label>
            <h4>{formatToDecimal(props.cardsInfo?.totalITBIS)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Ley: </label>
            <h4>{formatToDecimal(props.cardsInfo?.totalTips)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Ventas Abiertas: </label>
            <h4>{formatInteger(props.cardsInfo?.totalOpenSales)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Total Ventas: </label>
            <h4>{formatToDecimal(props.cardsInfo?.totalSales)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Clientes: </label>
            <h4>{formatInteger(props.cardsInfo?.totalClients)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Transacciones Abiertas: </label>
            <h4>{formatInteger(props.cardsInfo?.totalOpenTransacts)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Transacciones: </label>
            <h4>{formatInteger(props.cardsInfo?.totalTransacts)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Descuentos: </label>
            <h4>{formatToDecimal(props.cardsInfo?.totalDiscounts)}</h4>
          </div>
          <div className="item">
            <label htmlFor="">Cancelaciones: </label>
            <h4>{formatInteger(props.cardsInfo?.totalCancelattions)}</h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeMultibusinessForm;
