import { useEffect, useState } from "react";

const useValidateExpDate = ({ expirationDate }) => {
  const [showMembership, setShowMembership] = useState(false);

  function HandleShowMembership(bool) {
    return setShowMembership(bool);
  }


  function checkMembership() {
    const inputDate = new Date(expirationDate);
    const currentDate = new Date();

    const timeDifference = Math.abs(currentDate - inputDate);

    const dayDifference = timeDifference / (1000 * 3600 * 24);

    if (dayDifference <= 30) {
      return HandleShowMembership(true);
    } else {
      return HandleShowMembership(false);
    }
  }

  useEffect(() => {
    checkMembership();
  }, []);

  return { showMembership, HandleShowMembership };
};

export default useValidateExpDate;
