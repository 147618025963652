import React from "react";
import Card from "./Card";
import useGetDetailOrder from "./hooks/useGetDetailOrder";

const Index = ({
  selectedTransact,
  HandleDetailOrderToggle,
  NextTransact,
  PreviousTransact,
  toggles,
  HandleDetailOrderMoreToggle,
}) => {
  
  const transactId = selectedTransact?.transact;
  const opendate = selectedTransact?.opendate;
  const itbis = selectedTransact?.tax1;
  const tips = selectedTransact?.tax5;
  const discount = selectedTransact?.discount;
  const subtotal = selectedTransact?.nettotal;
  const total = selectedTransact?.total;
  const descript = selectedTransact?.descript;
  const descript1 = selectedTransact?.descript1;
  const memcode = selectedTransact?.memcode;
  const membername = selectedTransact?.membername;
  
  const moreDetails = toggles?.detailOrderMore 

  const { data: detailOrderData, loading } = useGetDetailOrder(transactId);

  return (
    <>
      <div className="background" onClick={HandleDetailOrderToggle}></div>
      <Card
        loading={loading}
        transactId={transactId}
        moreDetails={moreDetails}
        detailOrderData={detailOrderData}
        opendate={opendate}
        itbis={itbis}
        tips={tips}
        discount={discount}
        subtotal={subtotal}
        total={total}
        descript={descript}
        descript1={descript1}
        memcode={memcode}
        membername={membername}
        NextTransact={NextTransact}
        PreviousTransact={PreviousTransact}
        HandleDetailOrderMoreToggle={HandleDetailOrderMoreToggle}
      />
    </>
  );
};

export default Index;
