import React from "react";

const LoginForm = (props) => {
  return (
    <section className="login-section">
      <div className="background-login"></div>
      <form onSubmit={props.HandleLogin} className="login-window">
        {props.errMsg !== null && <div className="status">{props.errMsg}</div>}
        <div className="heading">
          <h2>Report</h2>
          <h3>Inicio de sesión</h3>
        </div>
        <div className="input-container">
          <label htmlFor="">Usuario</label>
          <input ref={props.usernameRef} type="text" />
        </div>
        <div className="input-container">
          <label htmlFor="">Contraseña</label>
          <input autoComplete="off" ref={props.passwordRef} type="password" />
        </div>
        {/* <div className="remember-me"> */}
          {/* <input type="checkbox" name="" id="" /> */}
          {/* <label htmlFor="">Recuérdame</label> */}
        {/* </div> */}
        <button>Entrar</button>
      </form>
      <div className="power-by">Powered By: Blue Logistic Services</div>
    </section>
  );
};

export default LoginForm;
