import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetEcf = ({ first_date, second_date, limit, include_cc }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);
  const [searchNoCC, setSearchNoCC] = useState(false);
  const [page, setPage] = useState(0);
  const [ecfData, setEcfData] = useState([])

  const isNullValue = !first_date || !second_date ? true : false;

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  const endpoint = `/business/${businessId}/branch/${selectedBranch}/xml-header/firstdate/${first_date}/seconddate/${second_date}/limit/${limit}/page/${page}`;
  const endpoint_no_cc = `/business/${businessId}/branch/${selectedBranch}/xml-header/firstdate/${first_date}/seconddate/${second_date}/limit/${limit}/page/${page}/no-cc`;

  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });

  const { data: dataNoCC } = useGetData({
    url: endpoint_no_cc,
    isNullValue: isNullValue,
    search: searchNoCC,
  });
  
  function HandlePage(page) {
    setPage(page)
    setSearch(true)
    setSearchNoCC(true)
  }

  function HandleSearch() {
    if (isNullValue) {
      return toast.error("Fecha inválida");
    }
    setSearchNoCC(true)
    setSearch(true);
  }


  useEffect(() => {
    if(!data ) return
    setSearch(false);
  }, [data]);

  useEffect(() => {
    if(!dataNoCC ) return
    setSearchNoCC(false);
  }, [dataNoCC]);

  useEffect(() => {
    if(include_cc) {
      setEcfData(data)
    } else {
      setEcfData(dataNoCC)
    }
  }, [data, dataNoCC, include_cc])

  return { ecfData, loading, HandleSearch, HandlePage };
};

export default useGetEcf;
