import { useState, useEffect, useRef } from "react";
import SalesReportForm from "./TransactionsForm";
import useAuth from "../../../hooks/useAuth";
import useGetData from "../../../hooks/useGetData";
import * as XLSX from "xlsx";
import OrderDetailModal from "../Modals/OrderDetailModal";
import { useQueryClient } from "react-query";

const TransactionsIndex = () => {
  //Here we define the global variables
  const { auth } = useAuth();
  const businessId = auth?.business?.ID_NEGOCIO;
  const businessName = auth?.business?.NOMBRE_NEGOCIO;
  const queryClient = useQueryClient();
  // const businessId = auth?.selectedBusiness?.businessId;

  // const navigate = useNavigate()

  //Defining the ref to use.
  const limitRef = useRef(100);
  const transactRef = useRef("");
  const employeeRef = useRef("");
  const tableContentScroll = useRef(null);
  const tableFooterScroll = useRef(null);
  const whoStartedRef = useRef(null);
  const whoClosedRef = useRef(null);
  const operationRef = useRef(0);
  const selectOperationRef = useRef(null);

  //Defining the ref the states.
  const [selectedBranch, setSelectedBranch] = useState(
    auth?.branches[0]?.ID_SUCURSAL
  );
  
  const [transactData, settransactData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [transaction, setTransaction] = useState({
    id: null,
    subtotal: null,
    itbis: null,
    propina: null,
    total: null,
  });
  const [show, setShow] = useState({
    detailOrderModal: false,
    noEmployeeAvailable: false,
  });
  const [limit, setLimit] = useState({
    value: limitRef.current,
    placeholder: limitRef.current,
  });
  const [filterTransact, setFilterTransact] = useState({
    transact_id: null,
    data: [],
    noDataFound: false,
  });
  const [employeeTransact, setEmployeeTransact] = useState({
    employee_name: null,
    data: [],
  });
  const [filterEmployee, setFilterEmployee] = useState({
    whoStarted: null,
    whoClosed: null,
  });
  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });

  //Here the fetch to the database
  //Note: the value of "1", is according to the bussiness's branch
  const { data } = useGetData(
    {
      url: `/business/${businessId}/transactions/branch/${selectedBranch}/limit/${limit?.value}`
    }
  );

  // const { data: transact_by_id} = useGetData(
  //   `/business/1/transactions/branch/1/transact/${filterTransact.transact_id}`
  // );
  const { data: transact_by_id } = useGetData(
    {
      url: `/business/${businessId}/transactions/branch/${selectedBranch}/transact/${filterTransact.transact_id}`
    }
  );

  const { data: employee_transactions } = useGetData(
    {
      url: `/business/${businessId}/transactions/branch/${selectedBranch}/employee/${employeeTransact.employee_name}`
    }
  );

  function HandleSelectBranch(e) {
    const branchId = e.target.value;
    return setSelectedBranch(branchId);
  }
  //This function handle the scroll of the table when its overflow.
  const handleTableScroll = (event) => {
    // const { scrollTop, scrollLeft } = event.target;
    // tableContentScroll.current.scrollTop = scrollTop;
    // tableFooterScroll.current.scrollLeft = scrollLeft;
  };

  //This function takes the values of an object, converting it to a new array
  function ObjectToArraysOfValues(array) {
    let newArrayOfValues = [];
    for (let i = 0; i < array.length; i++) {
      newArrayOfValues.push(Object.values(data[i]));
    }
    return newArrayOfValues;
  }

  const HandleModalClose = () => {
    // Borra los datos relevantes de React Query
    return queryClient.invalidateQueries({
      queryKey: ["dataDetailOrder"],
      refetchType: "none",
    });
  };

  function ToggleOrderDetailModal(bool) {
    HandleModalClose();
    setShow({ ...show, orderDetailModal: bool });
  }

  function HandleTransaction(transact) {
    return setTransaction({
      indexArray: transact.indexArray,
    });
  }

  //The date coming from the database has a diff format, so this function changes the format.
  function formatDate(currentDate) {
    const date = new Date(currentDate);
    const day =
      date.getUTCDate() < 9 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;
    const month =
      date.getUTCMonth() + 1 < 9
        ? `0${date.getUTCMonth() + 1}`
        : `${date.getUTCMonth() + 1}`;
    const year = date.getUTCFullYear();
    const hours =
      date.getUTCHours() < 9
        ? `0${date.getUTCHours()}`
        : `${date.getUTCHours()}`;
    const minutes =
      date.getUTCMinutes() < 9
        ? `0${date.getUTCMinutes()}`
        : `${date.getUTCMinutes()}`;
    const seconds =
      date.getUTCSeconds() < 9
        ? `0${date.getUTCSeconds()}`
        : `${date.getUTCSeconds()}`;

    if (hours === "00" && minutes === "00" && seconds === "00") {
      const newdate = `${day}-${month}-${year}`;
      return newdate;
    }
    const newdate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return newdate;
  }

  //This function is for generate an excel document
  function generateExcel() {
    const exceldata = [
      [
        "ID",
        "FECHA",
        "ITBIS",
        "SERVICIO",
        "EFECTIVO",
        "TARJETA",
        "OTROS PAGOS",
      ],
      ...ObjectToArraysOfValues(data),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(exceldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    XLSX.writeFile(workbook, `Reporte de Ventas - ${businessName}.xlsx`);
  }

  //The total sum of the table came from this function
  function totalDataSum(array) {
    let sum = {
      netsales: 0,
      totalsales: 0,
    };
    array.forEach((arr) => {
      sum.netsales += arr?.nettotal;
      sum.totalsales += arr?.total;
    });
    return sum;
  }

  //This function takes as a parameter to dates, and its take both to calculate the days between.
  function calculateDateDifference(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    const diffMs = Math.abs(d2 - d1);

    const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  //When the user changes the select, this function handles it
  function HandleSelectDateChange(e) {
    limitRef.current.value = "";

    const date = new Date();
    const actualDay =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const actualMonth =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;

    const structureDate = (diffDays, limitValue) => {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - parseInt(diffDays));

      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setLimit({ value: limitValue, placeholder: limitValue });
      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    };

    if (e.target.value === "Todos") {
      setDateFilter({
        from: ``,
        to: ``,
      });
      setLimit({ value: 100, placeholder: 100 });
    }

    if (e.target.value === "Hoy") {
      setDateFilter({
        from: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
      setLimit({ value: 1, placeholder: 1 });
    }

    if (e.target.value === "Ayer") {
      const dayDiff = 1;
      structureDate(dayDiff);
    }

    if (e.target.value === "Semana") {
      const dayDiff = 7;
      structureDate(dayDiff);
    }

    if (e.target.value === "Mes") {
      const dayDiff = 30;
      structureDate(dayDiff);
    }

    if (e.target.value === "Año") {
      const dayDiff = 365;
      structureDate(dayDiff);
    }
  }

  //This function is for the limit input and icon.
  //It does two things, check if the value is correct (integer), and refetch the data to the
  //database when the user clicks on the refresh icon.
  function HandleLimitChange() {
    try {
      setFilterTransact({ ...filterTransact, data: [] });
      if (!isNaN(limitRef?.current?.value)) {
        document
          .getElementById("input-limit")
          .classList.remove("limit-input-invalid");

        setDateFilter({
          from: "",
          to: "",
        });

        setLimit({
          value: limitRef?.current?.value ? limitRef?.current?.value : 7,
          placeholder: limitRef?.current?.value ? limitRef?.current?.value : 7,
        });
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
      } else {
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
        document
          .getElementById("input-limit")
          .classList.add("limit-input-invalid");
      }
    } catch (error) {}
  }

  function HandleFilterChange() {
    if (transactRef.current === null) return;
    if (transactRef.current.value === "") {
      return setFilterTransact({ ...filterTransact, data: [] });
    }

    const transactId = parseInt(transactRef?.current?.value);
    return setFilterTransact({ ...filterTransact, transact_id: transactId });
  }

  function HandleEmployeeChange() {
    if (employeeRef.current.value === "") {
      return setEmployeeTransact({ ...employeeTransact, data: [] });
    }

    const employeeName = employeeRef?.current?.value;
    return setEmployeeTransact({
      ...employeeTransact,
      employee_name: employeeName,
    });
  }

  function validFormatDate(date) {
    try {
      const cleanDate = new Date(date.replace("T00:00:00.000Z", ""));
      const day = `${
        cleanDate.getDate() + 1 < 10
          ? `0${cleanDate.getDate() + 1}`
          : cleanDate.getDate() + 1
      } `;
      const month = `${
        cleanDate.getMonth() + 1 < 10
          ? `0${cleanDate.getMonth() + 1}`
          : `${cleanDate.getMonth() + 1}`
      } `;
      const year = cleanDate.getFullYear();
      const newFormatDate = `${year}-${month}-${day}`;
      return newFormatDate;
    } catch (error) {
      console.error("Error in validFormatDate" + error.message);
    }
  }

  //This function is the responsable of display the table'contents on the screen
  //according to the inputs date. Also its change the limit value and placeholder.
  function validDateBasedOnInputsDate(isDateInputChanged) {
    const date = new Date();
    let newtransactData = [];

    if (!isDateInputChanged) {
      return settransactData(data);
    } else {
      data.forEach((d) => {
        if (
          !(validFormatDate(dateFilter.from) <= validFormatDate(d.opendate))
        ) {
          return;
        }

        if (!(validFormatDate(d.opendate) <= validFormatDate(dateFilter.to)))
          return;

        newtransactData.push(d);
      });

      setLimit({
        value: calculateDateDifference(dateFilter.from, date),
        placeholder: calculateDateDifference(dateFilter.from, date),
      });

      return settransactData(newtransactData);
    }
  }

  function GetCurrentsEmployee() {
    if (!data) return null;
    const empWhoStarted = [];
    const empWhoClosed = [];

    data?.forEach((d) => {
      if (d?.empname1 !== null) {
        empWhoStarted.push(d?.empname1);
      }
      if (d?.empname2 !== null) {
        empWhoClosed.push(d?.empname2);
      }
    });

    return {
      whoStarted: [...new Set(empWhoStarted)],
      whoClosed: [...new Set(empWhoClosed)],
    };
  }

  function HandleWhoStarted(e) {
    return setFilterEmployee({ ...filterEmployee, whoStarted: e.target.value });
  }

  function HandleWhoClosed(e) {
    return setFilterEmployee({ ...filterEmployee, whoClosed: e.target.value });
  }

  function HandleSelectedEmployee() {
    const filterTransactByEmployee = [];

    transactData.forEach((employee) => {
      if (
        filterEmployee?.whoStarted &&
        filterEmployee?.whoStarted !== "default" &&
        filterEmployee?.whoClosed &&
        filterEmployee?.whoClosed !== "default"
      ) {
        if (
          employee?.empname1 === filterEmployee?.whoStarted &&
          employee?.empname2 === filterEmployee?.whoClosed
        ) {
          filterTransactByEmployee.push(employee);
        } else {
          filterTransactByEmployee.push([]);
        }
      } else {
        if (employee?.empname1 === filterEmployee?.whoStarted) {
          filterTransactByEmployee.push(employee);
        } else if (employee?.empname2 === filterEmployee?.whoClosed) {
          filterTransactByEmployee.push(employee);
        }
      }
    });

    return setFilterTransact({
      ...filterTransact,
      data: filterTransactByEmployee,
    });
  }

  function HandleDefaultValueOnSelect() {
    const defaultValue = "default";
    whoStartedRef.current.value = defaultValue;
    whoClosedRef.current.value = defaultValue;
    setFilterEmployee({
      whoStarted: "",
      whoClosed: "",
    });
    setFilterTransact({ ...filterEmployee, data: [] });
  }

  function HandleOperations() {
    const value = parseFloat(operationRef.current.value);
    const option = selectOperationRef.current.value;

    const filterArray = [];

    if (option === "greaterThan") {
      transactData.forEach((transact) => {
        if (!(transact?.total > value)) return;
        return filterArray.push(transact);
      });
    } else if (option === "lessThan") {
      transactData.forEach((transact) => {
        if (!(transact?.total < value)) return;
        return filterArray.push(transact);
      });
    } else if (option === "equalTo") {
      transactData.forEach((transact) => {
        if (!(transact?.total === value)) return;
        return filterArray.push(transact);
      });
    }

    if (filterArray.length === 0 && value) {
      return setFilterTransact({
        ...filterTransact,
        data: [],
        noDataFound: true,
      });
    }

    return setFilterTransact({
      ...filterTransact,
      data: filterArray,
      noDataFound: false,
    });
  }

  function ClearFilters() {
    return (
      setDateFilter({from: "", to: ""}),
      limitRef.current.value = 100,
      operationRef.current.value = 0.00,
      whoStartedRef.current.value = "default",
      whoClosedRef.current.value = "default"
    )
  }

  //This useEffect is for set the data on the state when data has a value on it.
  useEffect(() => {
    if (data === null) return;
    if (dateFilter.from === "" || dateFilter.to === "") {
      validDateBasedOnInputsDate(false);
    } else {
      validDateBasedOnInputsDate(true);
    }
    setFilterTransact({ ...filterTransact, data: [] });
  }, [data, dateFilter]);

  useEffect(() => {
    if (transact_by_id === null) return;
    if (!(transact_by_id.length > 0)) return;
    setFilterTransact({ ...filterTransact, data: transact_by_id });
  }, [transact_by_id]);

  useEffect(() => {
    if (employee_transactions === null) return;
    if (!(employee_transactions.length > 0)) return;
    setEmployeeTransact({ ...employeeTransact, data: employee_transactions });
  }, [employee_transactions]);

  useEffect(() => {
    HandleSelectedEmployee();
  }, [filterEmployee]);

  useEffect(() => {
    if (filterTransact?.data?.length > 0) {
      setTotalSum(totalDataSum(filterTransact.data));
    } else {
      setTotalSum(totalDataSum(transactData));
    }
  }, [transactData, filterTransact]);

  return (
    <>
      {show?.orderDetailModal && (
        <OrderDetailModal
          transaction={transaction}
          transactionData={data}
          ToggleOrderDetailModal={ToggleOrderDetailModal}
          formatDate={formatDate}
          filterTransact={filterTransact}
        />
      )}
      <SalesReportForm
        branches={auth?.branches}
        businessName={businessName}
        transactData={transactData}
        generateExcel={generateExcel}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        formatDate={formatDate}
        totalSum={totalSum}
        HandleSelectDateChange={HandleSelectDateChange}
        limitRef={limitRef}
        HandleLimitChange={HandleLimitChange}
        tableContentScroll={tableContentScroll}
        tableFooterScroll={tableFooterScroll}
        handleTableScroll={handleTableScroll}
        limit={limit}
        HandleTransaction={HandleTransaction}
        ToggleOrderDetailModal={ToggleOrderDetailModal}
        transactRef={transactRef}
        HandleFilterChange={HandleFilterChange}
        filterTransact={filterTransact}
        employeeRef={employeeRef}
        HandleEmployeeChange={HandleEmployeeChange}
        employeeTransact={employeeTransact}
        HandleWhoStarted={HandleWhoStarted}
        HandleWhoClosed={HandleWhoClosed}
        GetCurrentsEmployee={GetCurrentsEmployee}
        HandleSelectedEmployee={HandleSelectedEmployee}
        whoStartedRef={whoStartedRef}
        whoClosedRef={whoClosedRef}
        HandleDefaultValueOnSelect={HandleDefaultValueOnSelect}
        HandleSelectBranch={HandleSelectBranch}
        operationRef={operationRef}
        HandleOperations={HandleOperations}
        selectOperationRef={selectOperationRef}
        ClearFilters={ClearFilters}
      />
    </>
  );
};

export default TransactionsIndex;
