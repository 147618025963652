import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetEcfTotal = ({ first_date, second_date, include_cc }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);
  const [searchNoCC, setSearchNoCC] = useState(false);
  const [total, setTotal] = useState([])

  const isNullValue = !first_date || !second_date ? true : false;

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  const endpoint = `/business/${businessId}/branch/${selectedBranch}/xml-header/firstdate/${first_date}/seconddate/${second_date}/total`;

  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });


  const endpoint_no_cc = `/business/${businessId}/branch/${selectedBranch}/xml-header/firstdate/${first_date}/seconddate/${second_date}/total/no-cc`;

  const { data: dataNoCC } = useGetData({
    url: endpoint_no_cc,
    isNullValue: isNullValue,
    search: searchNoCC,
  });

  function HandleSearch() {
    if (isNullValue) {
      return toast.error("Fecha inválida");
    }
    setSearch(true);
    setSearchNoCC(true);
  }

  useEffect(() => {
    if(!data ) return
    setSearch(false);
  }, [data]);

  useEffect(() => {
    if(!dataNoCC ) return
    setSearchNoCC(false);
  }, [dataNoCC]);

  useEffect(() => {
    if(include_cc) {
      setTotal(data)
    } else {
      setTotal(dataNoCC)
    }
  }, [data, dataNoCC, include_cc])


  return { total, loading, HandleSearch };
};

export default useGetEcfTotal;
