import React, { useEffect, useRef, useState } from "react";
import FiltersHeading from "./components/FilterHeading";
import useDateFilter from "../../../hooks/useDateFilter";
import TableContent from "./components/TableContent";
import useGetEcf from "./hooks/useGetEcf";
import Pagination from "../../../components/Pagination";
import useAuth from "../../../hooks/useAuth";
import useFilters from "./hooks/useFilters";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetTotal } from "./utils/useGetTotal";
import useGetEcfTotal from "./hooks/useGetEcfTotal";
import useGetEcfBySearch from "./hooks/useGetEcfByTransact";

const Index = () => {
  const limit = 300;
  const { auth } = useAuth();
  const { date, HandleDateChange } = useDateFilter("", "");

  const selectOperationRef = useRef(null);
  const inputOperationRef = useRef(null);
  const typeRef = useRef(null);

  const [resetPage, setResetPage] = useState(false);

  const [toggles, setToggles] = useState({
    include_cc: false
  })

  function HandleIncludeCCToggle() {
    setToggles({...toggles, include_cc: !toggles?.include_cc})
  }

  const {
    ecfData,
    loading,
    HandleSearch,
    HandlePage,
  } = useGetEcf({
    first_date: date.from,
    second_date: date.to,
    limit,
    include_cc: toggles?.include_cc
  });

  const {
    total: ecfTotal,
    loading: ecfTotalLoading,
    HandleSearch: HandleSearchTotal,
  } = useGetEcfTotal({
    first_date: date.from,
    second_date: date.to,
    include_cc: toggles?.include_cc
  });

  // const { data: searchData, loading: searchLoading } = useGetEcfBySearch();
  const { filters, match, clearFilters, HandleNCFType } = useFilters({
    dataArray: ecfData?.rows,
  });

  const filterHasData = filters?.length > 0

  const switchBetweenData =
    filters?.length > 0 ? filters : ecfData?.rows ? ecfData?.rows : ecfData;

  const somethingIsLoading = ecfTotalLoading || loading;

  const results = useGetTotal(switchBetweenData);

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  function HandleSearches() {
    HandleSearch();
    HandleSearchTotal();
  }


  function clearRef() {
    typeRef.current = "default";
    selectOperationRef.current = "default"
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
    HandleResetPage();
  }, [auth]);

  useEffect(() => {
    clearFilters()
    clearRef()
  }, [toggles?.include_cc])

  return (
    <section className="e_invoice-section">
      <FiltersHeading
        date={date}
        toggles={toggles}
        typeRef={typeRef}
        clearRef={clearRef}
        registerCount={ecfData?.count}
        inputOperationRef={inputOperationRef}
        clearFilters={clearFilters}
        selectOperationRef={selectOperationRef}
        HandleIncludeCCToggle={HandleIncludeCCToggle}
        HandleDateChange={HandleDateChange}
        HandleSearches={HandleSearches}
        HandleNCFType={HandleNCFType}
      />

      {switchBetweenData?.length > 0 && !somethingIsLoading && match && (
        <TableContent
          filterHasData={filterHasData}
          ecfData={switchBetweenData}
          results={results}
          ecfTotal={ecfTotal}
        />
      )}

      {(!(switchBetweenData.length > 0) && !somethingIsLoading) || (!match && !somethingIsLoading) ? (
        <div className="nothing-to-show">
          <FontAwesomeIcon className="icon" icon={faEyeSlash} />
          <h4>Nada por mostrar</h4>
        </div>
      ) : <></>}

      {somethingIsLoading && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}
      <Pagination
        itemsCount={ecfData?.count}
        itemsPerPage={limit}
        loading={loading}
        hasData={switchBetweenData?.length > 0 && match}
        resetPage={resetPage}
        HandlePage={HandlePage}
      />
    </section>
  );
};

export default Index;
