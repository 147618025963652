import { useState } from "react";

const defaultValue = {
  variacion: false,
};

const useToggles = () => {
  const [toggles, setToggles] = useState(defaultValue);

  function HandleToggle(name, value) {

    return setToggles({ ...toggles, [name]: value });
  }
  return { toggles, HandleToggle };
};

export default useToggles;
