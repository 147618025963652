import React, { useRef, useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const LoginIndex = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState(null);

  const { auth, setAuth } = useAuth();

  async function HandleLogin(e) {
    e.preventDefault();
    setErrMsg(null);
    if (usernameRef.current.value === "" || passwordRef.current.value === "")
      return setErrMsg("Campos faltantes");

    await axios
      .post("user/auth", {
        username: usernameRef.current.value.toUpperCase(),
        password: passwordRef.current.value,
      })
      .then(async (res) => {
        if ((res?.data?.active).toUpperCase() !== "S")
          return setErrMsg("Cuenta desactivada");

        if (res?.data?.multibusiness) {
          setAuth({
            username: usernameRef?.current?.value,
            expiration_date: res.data.expiration_date,
            business: res.data?.business,
            selectedBusinessOnlyId: res.data?.business[0]?.ID_NEGOCIO,
            selectedBusiness: JSON.stringify(res.data?.business[0]),
            selectedBranch: res.data?.business[0]?.sucursales[0]?.ID_SUCURSAL,
            isMultibusiness: res?.data?.multibusiness,
            token: res.data?.accessToken,
          });

          HandleSetCookie({
            username: usernameRef?.current?.value,
            business: res.data?.business,
            branches: res.data?.business[0]?.sucursales[0]?.ID_SUCURSAL,
            token: res.data?.accessToken,
            isMultibusiness: true,
          });

          navigate("/", { replace: true });

          return;
        }

        setAuth({
          username: usernameRef?.current?.value,
          expiration_date: res.data.expiration_date,
          business: res.data?.business,
          branches: res.data?.branches,
          selectedBranch: res.data?.branches[0]?.ID_SUCURSAL,
          token: res.data?.accessToken,
        });

        HandleSetCookie({
          username: usernameRef?.current?.value,
          business: res.data?.business,
          branches: res.data?.branches,
          token: res.data?.accessToken,
          isMultibusiness: false,
        });

        navigate("/", { replace: true });
      })
      .catch((error) => {
        if (!error) {
          setErrMsg("No server response");
        } else if (error?.response?.status === 401) {
          setErrMsg("Email o contraseñas erroneas");
        }
        else if (error?.response?.status === 403) {
          setErrMsg("Usuario desactivado");
        }
      });
  }

  function HandleSetCookie({
    username,
    business,
    branches,
    token,
    isMultibusiness,
  }) {
    Cookies.set("auth-username", `${username}`, {
      expires: 1,
    });
    Cookies.set("auth-business", `${JSON.stringify(business)}`, {
      expires: 1,
    });

    Cookies.set("auth-branches", `${isMultibusiness ? branches : JSON.stringify(branches)}`, {
      expires: 1,
    });

    Cookies.set("auth-token", `${token}`, {
      expires: 1,
    });
    Cookies.set("auth-isMultibusiness", `${isMultibusiness}`, {
      expires: 1,
    });
  }

  useEffect(() => {
    try {
      const usernameCookie = Cookies.get("auth-username");
      const businessCookie = Cookies.get("auth-business");
      const branchesCookie = Cookies.get("auth-branches");
      const tokenCookie = Cookies.get("auth-token");
      const isMultibusiness = Cookies.get("auth-isMultibusiness");

      if (!usernameCookie || !tokenCookie || !businessCookie || !branchesCookie)
        return;

      if (isMultibusiness === "true") {
        const convertBusiness = JSON.stringify(JSON.parse(businessCookie)[0]);

        setAuth({
          username: usernameCookie,
          business: JSON.parse(businessCookie),
          selectedBusinessOnlyId: JSON.parse(businessCookie)[0]?.ID_NEGOCIO,
          selectedBusiness: convertBusiness,
          selectedBranch: branchesCookie,
          isMultibusiness: true,
          token: tokenCookie,
        });
      } else {
        setAuth({
          username: usernameCookie,
          business: JSON.parse(businessCookie),
          branches: JSON.parse(branchesCookie),
          selectedBranch: JSON.parse(branchesCookie)[0]?.ID_SUCURSAL,
          token: tokenCookie,
        });
      }

      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!auth) return;
    navigate("/");
  }, []);

  return (
    <LoginForm
      usernameRef={usernameRef}
      passwordRef={passwordRef}
      errMsg={errMsg}
      HandleLogin={HandleLogin}
    />
  );
};

export default LoginIndex;
