
import { Bar } from "react-chartjs-2";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";

function BarSalesGraph({salesData, date}) {
  const getDays = () => {
    if (!salesData) return [];
    const days = salesData.map((data) => {
      const date = new Date(data?.opendate);
      const day = date.getUTCDate();
      return day;
    });
    return days;
  };
  
  const data = {
    labels: getDays(),
    datasets: [
      {
        label: "Ventas por día",
        data: salesData ? salesData.map((data) => data?.total) : [0],
        backgroundColor: "#84b6f4",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="bar-chart">
      <h4>
        Ventas Netas del {dayAndMonthFormat(date?.from)} al{" "}
        {dayAndMonthFormat(date?.to)}
      </h4>
      <Bar className=" chart" data={data} />
    </div>
  );
}

export default BarSalesGraph;
