import { useQuery } from "react-query";
import { Line } from "react-chartjs-2";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";

const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;

function LineClientsGraph(props) {
  const { data: dashboardData } = useQuery(
    "clientsData",
    () => {
      return fetch(
        `${BASE_URL}/business/${props.businessId}/branch/${props.selectedBranch}/sales/summary/firstdate/${props.from}/seconddate/${props.to}`
      ).then((res) => {
        return res.json();
      });
    },
    {
      enabled: true,
    }
  );

  const getDays = () => {
    if (!dashboardData) return [];
    const days = dashboardData.map((data) => {
      const date = new Date(data?.opendate);
      const day = date.getUTCDate();
      return day;
    });
    return days;
  };
  const data = {
    labels: getDays(),
    datasets: [
      {
        label: "Clientes por día",
        data: dashboardData ? dashboardData.map((data) => data?.numcust) : [0],
        backgroundColor: "#77dd77",
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="bar-chart">
      <h4>
        Clientes del {dayAndMonthFormat(props.from)} al {dayAndMonthFormat(props.to)}
      </h4>
      <Line className=" chart" data={data} />
    </div>
  );
}

export default LineClientsGraph;
