import React, { useEffect, useState, useRef } from "react";
import FiltersHeading from "./components/FilterHeading";
import TableContent from "./components/TableContent";
import TableContentSum from "./components/TableContentSum";
import TableContentCount from "./components/TableContentCount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getTotal } from "./utils/getTotal";
import Pagination from "../../../components/Pagination";
import useDateFilter from "../../../hooks/useDateFilter";
import useGetNcf from "./hooks/useGetNcf";
import useGetNcfByTransact from "./hooks/useGetNcfByTransact";
import useGetNcfSum from "./hooks/useGetNcfSum";
import useGetNcfTotal from "./hooks/useGetNcfTotal";
import useToggles from "./hooks/useToggles";
import useGetNcfCount from "./hooks/useGetNcfCount";
import useFilters from "./hooks/useFilters";
import useAuth from "../../../hooks/useAuth";

const Index = () => {
  const { auth } = useAuth();

  const [resetPage, setResetPage] = useState(false);

  const inputOperationRef = useRef(null);
  const selectOperationRef = useRef(null);
  const selectNCFRef = useRef(null);

  const limit = 300;

  function clearRefs() {
    inputOperationRef.current.value = "";
    selectOperationRef.current.value = "default";
    selectNCFRef.current.value = "default";
  }

  const { date, HandleDateChange } = useDateFilter("", "");

  const {
    data: ncfData,
    loading: ncfLoading,
    HandleSearch,
    HandlePage,
  } = useGetNcf({
    first_date: date.from,
    second_date: date.to,
    limit,
  });

  const { data: ncfTotalData, HandleSearch: HandleTotalSearch } =
    useGetNcfTotal({
      first_date: date.from,
      second_date: date.to,
    });

  const {
    data: transactData,
    loading: transactLoading,
    ResetValue: ResetTransact,
    clearTransact,
    HandleTransactId,
  } = useGetNcfByTransact(inputOperationRef);

  const {
    data: ncfSumData,
    loading: ncfSumLoading,
    HandleSearch: HandleSumSearch,
  } = useGetNcfSum({
    first_date: date.from,
    second_date: date.to,
  });

  const { data: ncfCountData } = useGetNcfCount();

  const switchBetweenData =
    transactData?.length > 0 ? transactData : ncfData?.rows;

  const {
    toggles,
    disableSummaryToggle,
    HandleSummaryToggle,
    HandleCountbookToggle,
  } = useToggles();

  const {
    filters,
    match,
    clearFilters,
    HandleFilterByOperation,
    HandleNCFType,
  } = useFilters({
    dataArray: ncfData?.rows,
    inputOperationRef,
    selectOperationRef,
    disableSummaryToggle,
    clearRefs,
    clearTransact,
    ResetTransact,
    HandleTransactId,
    HandleSummaryToggle,
  });

  const filtersHasData = filters.length > 0 ? filters : switchBetweenData;

  const manageTotal = filters.length > 0 ? filtersHasData : ncfTotalData;

  const summaryIsActive = toggles?.summary ? ncfSumData : manageTotal;

  const oneToggleIsActive = toggles?.summary || toggles?.countbook;

  const { total, availableNcf } = getTotal(summaryIsActive);

  const summaryHasData = filtersHasData?.length > 0 && toggles?.summary;

  function HandleResetPage() {
    return setResetPage(!resetPage);
  }

  function HandleSearches() {
    return (
      HandleSearch(), HandleTotalSearch(), HandleSumSearch(), HandleResetPage()
    );
  }

  useEffect(() => {
    const isNullValue = !date?.from || !date?.to ? true : false;
    if (isNullValue) return;
    HandleSearches();
    HandleResetPage();
  }, [auth]);

  return (
    <section className="ncf-section">
      <FiltersHeading
        inputOperationRef={inputOperationRef}
        selectOperationRef={selectOperationRef}
        selectNCFRef={selectNCFRef}
        date={date}
        toggles={toggles}
        registerCount={
          filtersHasData?.length > 0 ? ncfData?.count : filtersHasData?.length
        }
        clearFilters={clearFilters}
        HandleDateChange={HandleDateChange}
        HandleFilterByOperation={HandleFilterByOperation}
        HandleNCFType={HandleNCFType}
        HandleSearches={HandleSearches}
        HandleSummaryToggle={HandleSummaryToggle}
        HandleCountbookToggle={HandleCountbookToggle}
      />

      {toggles?.countbook &&
        !ncfLoading &&
        !transactLoading &&
        ncfCountData?.length > 0 && (
          <TableContentCount
            ncfCountData={ncfCountData}
            availableNcf={availableNcf}
          />
        )}

      {ncfSumData?.length > 0 &&
        toggles?.summary &&
        !toggles?.countbook &&
        !ncfSumLoading && (
          <TableContentSum ncfData={ncfSumData} total={total} />
        )}

      {filtersHasData?.length > 0 &&
        match &&
        !ncfLoading &&
        !transactLoading &&
        !toggles?.countbook &&
        !toggles?.summary && (
          <TableContent ncfData={filtersHasData} total={total} />
        )}

      <Pagination
        itemsCount={ncfData?.count}
        itemsPerPage={limit}
        loading={ncfLoading}
        hasData={filtersHasData?.length > 0 && match && !oneToggleIsActive}
        resetPage={resetPage}
        HandlePage={HandlePage}
      />

      {(ncfLoading || transactLoading || ncfSumLoading) && (
        <section className="nothing-to-show">
          <span className="loader"></span>
          <h4>Cargando información...</h4>
        </section>
      )}

      {!(filtersHasData?.length > 0 && match) &&
        !toggles?.countbook &&
        !ncfLoading &&
        !summaryHasData &&
        !transactLoading && (
          <div className="nothing-to-show">
            <FontAwesomeIcon className="icon" icon={faEyeSlash} />
            <h4>Nada por mostrar</h4>
          </div>
        )}
    </section>
  );
};

export default Index;
