import React from "react";

const ModalConfirmConteo = (props) => {
  return (
    <>
      <div className="background"></div>
      <section className="modal">
        <h2>Oops! El registro ya existe.</h2>
        <p>
          Si de igual forma desea proceder, se perderá el historial previo,
          conservandosé solamente el actual.
        </p>
        <div className="buttons-container">
          <button className="button-18 btn-red" onClick={() => {
            props.HandleToggle('ModalConfirmConteo', false)
          }}>
            Cancelar
          </button>
          <button onClick={props.handleSubmit} className="button-18">
            Guardar de todos modos
          </button>
        </div>
      </section>
    </>
  );
};

export default ModalConfirmConteo;
