import React from "react";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";
import { getPorcentage } from "../utils/getPorcentage";

const TableProductDetail = (props) => {
  return (
    <div className="container">
      <div className="table-content">
        <table className="table-header">
          <thead className="background-tr-1">
            <tr>
              <th className="description-width">Descripción</th>
              <th className="qty-width">Qty</th>
              <th className="qty-width">%Qty</th>
              <th className="total-width right">Total</th>
              <th className="total-width right">%Total</th>
            </tr>
          </thead>
        </table>
        {props?.salesProductData?.map((detail, index) => {
          return (
            <table key={index} className="table-body">
              <tbody>
                <tr className="category-title">
                  <td className="category">{detail?.category}</td>
                </tr>
                {detail?.products?.map((product) => {
                  if (!(product?.category === detail?.category)) return;
                  return (
                    <>
                      <tr>
                        <td className="description-width">
                          {product?.prodnum} - {product?.product}
                        </td>
                        <td className="qty-width center">
                          {product?.cantidad}
                        </td>
                        <td className="qty-width center">
                          {getPorcentage(
                            product?.cantidad,
                            props?.generalTotal?.cantidad
                          )}
                        </td>
                        <td className="right">
                          {formatToDecimal(product?.total)}
                        </td>
                        <td className="right">
                          {getPorcentage(
                            product?.total,
                            props?.generalTotal?.total
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
              <tfoot className="background-tr">
                <td className="description-width total">Total:</td>
                <td className="qty-width">{detail?.info?.qty}</td>
                <td className="qty-width">
                  {getPorcentage(detail?.info?.qty, props?.generalTotal?.cantidad)}
                </td>
                <td className="total-width right">
                  {formatToDecimal(detail?.info?.total)}
                </td>
                <td className="total-width right">
                  {getPorcentage(
                    detail?.info?.total,
                    props?.generalTotal?.total
                  )}
                </td>
              </tfoot>
            </table>
          );
        })}
        <table className="table-footer">
          <thead className="background-tr-1">
            <tr>
              <th className="description-width">Total General:</th>
              <th className="qty-width">
                {formatToDecimal(props?.generalTotal?.cantidad)}
              </th>
              <th className="qty-width"></th>
              <th className="total-width right">
                {formatToDecimal(props?.generalTotal?.total)}
              </th>
              <th className="total-width right"></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

// <table className="table-body">
//                 <tbody>
//                   <tr className="category-title">
//                     <td className="category">{detail?.category}</td>
//                   </tr>
//                   {detail?.products?.map((product) => {
//                     if (!(product?.category === detail?.category)) return;
//                     return (
//                       <>
//                         <tr>
//                           <td className="description-width">
//                             {product?.prodnum} - {product?.product}
//                           </td>
//                           <td className="qty-width center">
//                             {product?.cantidad}
//                           </td>
//                           <td className="qty-width center">
//                             {getPorcentage(
//                               product?.cantidad,
//                               props?.generalTotal?.qty
//                             )}
//                           </td>
//                           <td className="total-width right">
//                             {formatToDecimal(product?.total)}
//                           </td>
//                           <td className="total-width right">
//                             {getPorcentage(
//                               product?.total,
//                               props?.generalTotal?.total
//                             )}
//                           </td>
//                         </tr>
//                       </>
//                     );
//                   })}
//                 </tbody>
//                 {props.showCategoryTotal && (
//                   <tfoot className="background-tr">
//                     <td className="description-width total">Total:</td>
//                     <td className="qty-width">{detail?.info?.qty}</td>
//                     <td className="qty-width">
//                       {getPorcentage(
//                         detail?.info?.qty,
//                         props?.generalTotal?.qty
//                       )}
//                     </td>
//                     <td className="total-width right">
//                       {formatToDecimal(detail?.info?.total)}
//                     </td>
//                     <td className="total-width right">
//                       {getPorcentage(
//                         detail?.info?.total,
//                         props?.generalTotal?.total
//                       )}
//                     </td>
//                   </tfoot>
//                 )}
//               </table>

export default TableProductDetail;
