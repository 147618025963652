import { useState, useEffect } from "react";
import toast from "react-hot-toast";

const useFilters = ({
  dataArray,
  inputOperationRef,
  selectOperationRef,
  disableSummaryToggle,
  ResetTransact,
  clearRefs,
  clearTransact,
  HandleTransactId,
}) => {
  const [filters, setFilters] = useState([]);
  const [match, setMatch] = useState(true);

  function HandleFilterByOperation() {
    const inputValue = inputOperationRef.current.value;
    const selectValue = selectOperationRef.current.value;

    let filterArray = [];
    let pass = true;

    disableSummaryToggle();

    switch (selectValue) {
      case "RNC/CED":
        if (!dataArray) return toast.error("No hay data para filtrar..");
        filterArray = dataArray.filter((data) => {
          return data?.rnc.includes(inputValue);
        });
        break;

      case "Transact":
        HandleTransactId();
        break;

      default:
        pass = false;
        toast.error("Seleccione una operación");
        break;
    }

    if (filterArray.length === 0 && !pass) return setMatch(false);

    return setFilters(filterArray), setMatch(true);
  }

  function HandleNCFType(e) {
    if (!dataArray) return;

    disableSummaryToggle();

    const value = e.target.value;

    let filterArray = [];
    let defaultCase = false;

    switch (value) {
      case "B01":
        filterArray = dataArray.filter((data) => {
          return data?.ncf.includes("B01");
        });
        break;

      case "B02":
        filterArray = dataArray.filter((data) => {
          return data?.ncf.includes("B02");
        });
        break;
      case "B04":
        filterArray = dataArray.filter((data) => {
          return data?.ncf.includes("B04");
        });
        break;
      case "B14":
        filterArray = dataArray.filter((data) => {
          return data?.ncf.includes("B14");
        });
        break;
      case "B15":
        filterArray = dataArray.filter((data) => {
          return data?.ncf.includes("B15");
        });
        break;

      default:
        defaultCase = true;
        clearFilters();
        break;
    }

    if (filterArray.length === 0 && !defaultCase) return setMatch(false); //To show the no data available animation

    return setFilters(filterArray), setMatch(true);
  }

  function clearFilters() {
    clearRefs();
    setFilters([]);
    ResetTransact()
    setMatch(true)
  }

  useEffect(() => {
    clearTransact();
    clearRefs();
    clearFilters();
    disableSummaryToggle();
    ResetTransact()
  }, [dataArray]);

  return {
    filters,
    match,
    clearFilters,
    HandleFilterByOperation,
    HandleNCFType,
  };
};

export default useFilters;
