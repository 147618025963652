import React from "react";

const Conteo = (props) => {
 
  if (!props.data) return <></>;
  return (
    <section className="register-container">
      <div className="filters">
        <h2>Conteo de Inventario para {props.getBranchSelectedName}</h2>

        <div className="right-container">
        <div className="date">
            <label htmlFor="">Fecha: </label>
            <input
              className=""
              type="date"
              value={props.date}
              onChange={props.HandleDate}
              disabled={props.toggles.isEdit}
            />
          </div>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="description">Descripción</th>
              <th className="qty">Cantidad</th>
              <th>Unidad</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((d) => {
              return (
                <tr className={d.filtro === "1" ? "blue" : ""} key={d.id}>
                  <td className="left">{d.descript}</td>
                  <td className="td-qty">
                    <input
                      value={d.incomeQty}
                      onChange={(e) => {
                        props.HandleRecepcionQty(d.id, e.target.value);
                      }}
                      onClick={(e) => {
                        e.target.select()
                      }}
                      className="input-qty"
                      type="number"
                    />
                  </td>
                  <td>{d.unitdes}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="footer-btn">
        <button
          className="button-18 btn-red"
          onClick={() => {
            props.HandleToggle("registro", false);
            props.ResetArticlesData()
          }}
        >
          Cancelar
        </button>
        <button className="button-18" onClick={props.createOrUpdateConteo}>
          Aceptar
        </button>
      </div>
    </section>
  );
};

export default Conteo;
