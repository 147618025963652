import React, { useEffect } from "react";
import MenuForm from "./components/MenuForm";
import useDateFilter from "../../../hooks/useDateFilter";
import useSelectConteo from "./hooks/useSelectConteo";
import useToggles from "./hooks/useToggles";
import VariacionForm from "./components/VariacionForm";
import useGetInventoryHead from "../../Inventory2/hooks/useGetInventoryHead";

const Index = () => {
  const { select, HandleSelect } = useSelectConteo();

  const { toggles, HandleToggle } = useToggles();

  const { date: rangeDate, HandleDateChange: HandleRageDateChange } =
    useDateFilter("", "");

  const { data, HandleSearch } =
    useGetInventoryHead({
      first_date: rangeDate.from,
      second_date: rangeDate.to,
    });

  useEffect(() => {
    HandleSearch();
  }, [rangeDate]);

  return (
    <section className="inventory-variacion-section">
      {!toggles.variacion && (
        <MenuForm
          select={select}
          HandleSelect={HandleSelect}
          rangeDate={rangeDate}
          HandleRageDateChange={HandleRageDateChange}
          data={data}
          HandleToggle={HandleToggle}
        />
      )}

      {toggles.variacion && (
        <VariacionForm select={select} HandleToggle={HandleToggle}/>
      )}
    </section>
  );
};

export default Index;
