import { useRef, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  BranchSelect,
  BusinessSelect,
} from "../../../../components/Selects/Selects";

const FiltersHeading = (props) => {
  const { auth } = useAuth();
  const businessName = auth?.business?.NOMBRE_NEGOCIO;
  const isMultibusiness = auth?.isMultibusiness;

  const transactRef = useRef(null);

  const [collapse, setCollapse] = useState(true);

  function HandleToggle() {
    return setCollapse(!collapse);
  }

  return (
    <section className="filters-heading">
      <div className="table-heading">
        <div className="filters">
          <h2>Filtros</h2>
          <div>
            <div className="filter date-grid">
              <div>
                <div className="date-filter">
                  <label className="date-label" htmlFor="">
                    Desde:{" "}
                  </label>
                  <input
                    type="date"
                    value={props.date?.from}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "from",
                      });
                    }}
                  />
                </div>
                <div className="date-filter">
                  <label htmlFor="">Hasta: </label>
                  <input
                    type="date"
                    value={props.date?.to}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "to",
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <select
                  onChange={(e) => {
                    props.HandleDateChange(e, {
                      property: "from",
                      isSelect: true,
                    });
                  }}
                >
                  <option value="Sin Filtro">Sin filtro</option>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">Una semana</option>
                  <option value="Mes">Un mes</option>
                  <option value="Año">Un año</option>
                </select>
                <button
                  className="btn-search"
                  onClick={() => {
                    props.HandleSearches();
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
            <div className="clear-filters">
              <label htmlFor="">Limpiar filtros:</label>
              <button className="" onClick={props.clearFilters}></button>
            </div>
          </div>
          <div>
            <div className="filter">
              <div className="search-filter">
                <label htmlFor="">Por Tipo de NCF:</label>
                <select
                  ref={props.selectNCFRef}
                  onChange={props.HandleNCFType}
                  name=""
                  id=""
                >
                  <option value="default">Seleccionar NCF</option>
                  <option value="B01">B01</option>
                  <option value="B02">B02</option>
                  <option value="B04">B04</option>
                  <option value="B14">B14</option>
                  <option value="B15">B15</option>
                </select>
              </div>
              <div className="operation-filter">
                <select ref={props.selectOperationRef} name="" id="">
                  <option value="default">Buscar por: </option>
                  <option value="RNC/CED">RNC o Cédula</option>
                  <option value="Transact">Transact #</option>
                </select>
                <input
                  type="text"
                  placeholder="valor"
                  ref={props.inputOperationRef}
                />
                <button
                  className="btn-search"
                  onClick={props.HandleFilterByOperation}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
              <div className="toggles-grid">
                <div className="summary-filter">
                  <label className="title" htmlFor="">
                    Resumir:
                  </label>
                  <div className="toggle">
                    <input
                      onChange={props.HandleSummaryToggle}
                      checked={props.toggles?.summary}
                      type="checkbox"
                      id="switch"
                    />
                    <label for="switch">Toggle</label>
                  </div>
                </div>
                <div className="summary-filter">
                  <label className="title" htmlFor="">
                    Countbook:
                  </label>
                  <div className="toggle">
                    <input
                      onChange={props.HandleCountbookToggle}
                      checked={props.toggles?.countbook}
                      type="checkbox"
                      id="switch2"
                    />
                    <label for="switch2">Toggle</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="business">
          <div className="line"></div>
          {isMultibusiness ? (
            <>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar negocio: </label>
                <BusinessSelect />
              </div>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          ) : (
            <>
              <h2>{businessName}</h2>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          )}

          <div className="">
            <label htmlFor="">
              Total de registros:{" "}
              {props.registerCount ? props.registerCount : 0}
            </label>
          </div>
        </div>
      </div>
      <div className={collapse ? "collapsed-heading" : "uncollapsed-heading"}>
        {collapse ? (
          <FontAwesomeIcon
            icon={faBars}
            className="icon-bars"
            onClick={HandleToggle}
          />
        ) : (
          <>
            <div className="container">
              <div>
                <div className="filter date-grid">
                  <div>
                    <div className="date-filter">
                      <label className="date-label" htmlFor="">
                        Desde:{" "}
                      </label>
                      <input
                        type="date"
                        value={props.date?.from}
                        onChange={(e) => {
                          props.HandleDateChange(e, {
                            property: "from",
                          });
                        }}
                      />
                    </div>
                    <div className="date-filter">
                      <label htmlFor="">Hasta: </label>
                      <input
                        type="date"
                        value={props.date?.to}
                        onChange={(e) => {
                          props.HandleDateChange(e, {
                            property: "to",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <select
                      onChange={(e) => {
                        props.HandleDateChange(e, {
                          property: "from",
                          isSelect: true,
                        });
                      }}
                    >
                      <option value="Sin Filtro">Sin filtro</option>
                      <option value="Hoy">Hoy</option>
                      <option value="Ayer">Ayer</option>
                      <option value="Semana">Una semana</option>
                      <option value="Mes">Un mes</option>
                      <option value="Año">Un año</option>
                    </select>
                    <button
                      className="btn-search"
                      onClick={() => {
                        props.HandleSearches();
                      }}
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </div>
                </div>
                <div className="clear-filters">
                  <label htmlFor="">Limpiar filtros:</label>
                  <button className="" onClick={props.clearFilters}></button>
                </div>
              </div>
              <div className="filter">
                <div className="search-filter">
                  <label htmlFor="">Por Tipo de NCF:</label>
                  <select
                    ref={props.selectNCFRef}
                    onChange={props.HandleNCFType}
                    name=""
                    id=""
                  >
                    <option value="default">Seleccionar NCF</option>
                    <option value="B01">B01</option>
                    <option value="B02">B02</option>
                    <option value="B04">B04</option>
                    <option value="B14">B14</option>
                    <option value="B15">B15</option>
                  </select>
                </div>
                <div className="operation-filter">
                  <select ref={props.selectOperationRef} name="" id="">
                    <option value="default">Buscar por: </option>
                    <option value="RNC/CED">RNC o Cédula</option>
                    <option value="Transact">Transact #</option>
                  </select>
                  <input
                    type="text"
                    placeholder="valor"
                    ref={props.inputOperationRef}
                  />
                  <button
                    className="btn-search"
                    onClick={props.HandleFilterByOperation}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                </div>
                <div className="toggles-grid">
                  <div className="summary-filter">
                    <label className="title" htmlFor="">
                      Resumir:
                    </label>
                    <div className="toggle">
                      <input
                        onChange={props.HandleSummaryToggle}
                        checked={props.toggles?.summary}
                        type="checkbox"
                        id="switch"
                      />
                      <label for="switch">Toggle</label>
                    </div>
                  </div>
                  <div className="summary-filter">
                    <label className="title" htmlFor="">
                      Countbook:
                    </label>
                    <div className="toggle">
                      <input
                        onChange={props.HandleCountbookToggle}
                        checked={props.toggles?.countbook}
                        type="checkbox"
                        id="switch2"
                      />
                      <label for="switch2">Toggle</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              onClick={HandleToggle}
              icon={faXmark}
              className="icon-xmark"
            />
          </>
        )}
      </div>
    </section>
  );
};

export default FiltersHeading;
