import useGetData from "../../../../../../../hooks/useGetData";
import useAuth from "../../../../../../../hooks/useAuth";

const useGetDetailOrder = (transactId) => {
  const { auth } = useAuth();
  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;
  const selectedBranch = auth?.selectedBranch;

  const isNullValue = transactId === null || transactId === "" ? true : false

  const endpoint = `/business/${businessId}/branch/${selectedBranch}/orders/transact/${transactId}`;
  
  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue
  });
  
  return { data, loading };
};

export default useGetDetailOrder;
