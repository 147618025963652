import { useRef, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  BranchSelect,
  BusinessSelect,
} from "../../../../components/Selects/Selects";
import {
  getEmployeeWhoClosed,
  getEmployeeWhoStarted,
} from "../utils/getEmployees";

const FiltersHeading = (props) => {
  const { auth } = useAuth();
  const businessName = auth?.business?.NOMBRE_NEGOCIO;
  const isMultibusiness = auth?.isMultibusiness;

  const employeeWhoStarted = getEmployeeWhoStarted(props.switchBetweenData);
  const employeeWhoClosed = getEmployeeWhoClosed(props.switchBetweenData);

  const [collapse, setCollapse] = useState(true);

  function HandleToggle() {
    return setCollapse(!collapse);
  }

  return (
    <section className="filters-heading">
      <div className="table-heading">
        <div className="filters">
          <h2>Filtros</h2>
          <div>
            <div className="filter date-grid">
              <div>
                <div className="date-filter">
                  <label className="date-label" htmlFor="">
                    Desde:{" "}
                  </label>
                  <input
                    type="date"
                    value={props.date?.from}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "from",
                      });
                    }}
                  />
                </div>
                <div className="date-filter">
                  <label htmlFor="">Hasta: </label>
                  <input
                    type="date"
                    value={props.date?.to}
                    onChange={(e) => {
                      props.HandleDateChange(e, {
                        property: "to",
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <select
                  onChange={(e) => {
                    props.HandleDateChange(e, {
                      property: "from",
                      isSelect: true,
                    });
                  }}
                >
                  <option value="Sin Filtro">Sin filtro</option>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">Una semana</option>
                  <option value="Mes">Un mes</option>
                  <option value="Año">Un año</option>
                </select>
                <button className="btn-search" onClick={props.HandleSearch}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
            <div className="filter">
              <div className="operation-filter">
                <select ref={props.selectOperationRef} name="" id="">
                  <option value="default">Seleccione Operación</option>
                  <option value="Greater Than">Mayor que</option>
                  <option value="Less Than">Menor que</option>
                  <option value="Equal To">Igual que</option>
                </select>
                <input
                  type="text"
                  placeholder="0.00"
                  ref={props.inputOperationRef}
                />
                <button
                  className="btn-search"
                  onClick={props.HandleFilterByOperation}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
              <div className="clear-filters">
                <label htmlFor="">Limpiar filtros:</label>
                <button className="" onClick={props.clearFilters}></button>
              </div>
            </div>
          </div>
          <div>
            <div className="filter">
              <div className="method-filter">
                <label htmlFor="">Quién inicia: </label>
                <select
                  ref={props.whoStartedRef}
                  onChange={props.HandleSelectEmployee}
                  name=""
                  id=""
                >
                  <option value="default">- Select type -</option>
                  {employeeWhoStarted?.map((employee, index) => {
                    return (
                      <option key={index} value={employee}>
                        {employee}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="method-filter">
                <label htmlFor="">Quién termina: </label>
                <select
                  ref={props.whoClosedRef}
                  onChange={props.HandleSelectEmployee}
                  name=""
                  id=""
                >
                  <option value="default">- Select type -</option>
                  {employeeWhoClosed?.map((employee, index) => {
                    return (
                      <option key={index} value={employee}>
                        {employee}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="filter">
              <div className="search-filter">
                <label htmlFor="">Por transacción:</label>
                <input
                  type="text"
                  placeholder="transact #"
                  ref={props.transactRef}
                />
                <button
                  className="btn-search"
                  onClick={() => {
                    props.HandleTransactId(props.transactRef.current.value);
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="business">
          <div className="line"></div>
          {isMultibusiness ? (
            <>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar negocio: </label>
                <BusinessSelect />
              </div>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          ) : (
            <>
              <h2>{businessName}</h2>
              <div className="multiple-branch">
                <label htmlFor="">Cambiar sucursal: </label>
                <BranchSelect />
              </div>
            </>
          )}
          <div className="">
            <label htmlFor="">
              Total de registros:{" "}
              {props.registerCount ? props.registerCount : 0}
            </label>
          </div>
        </div>
      </div>
      <div className={collapse ? "collapsed-heading" : "uncollapsed-heading"}>
        {collapse ? (
          <FontAwesomeIcon
            icon={faBars}
            className="icon-bars"
            onClick={HandleToggle}
          />
        ) : (
          <>
            <div className="container">
              <div className="filters">
                <div>
                  <div className="filter date-grid">
                    <div>
                      <div className="date-filter">
                        <label className="date-label" htmlFor="">
                          Desde:{" "}
                        </label>
                        <input
                          type="date"
                          value={props.date?.from}
                          onChange={(e) => {
                            props.HandleDateChange(e, {
                              property: "from",
                            });
                          }}
                        />
                      </div>
                      <div className="date-filter">
                        <label htmlFor="">Hasta: </label>
                        <input
                          type="date"
                          value={props.date?.to}
                          onChange={(e) => {
                            props.HandleDateChange(e, {
                              property: "to",
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          props.HandleDateChange(e, {
                            property: "from",
                            isSelect: true,
                          });
                        }}
                      >
                        <option value="Sin Filtro">Sin filtro</option>
                        <option value="Hoy">Hoy</option>
                        <option value="Ayer">Ayer</option>
                        <option value="Semana">Una semana</option>
                        <option value="Mes">Un mes</option>
                        <option value="Año">Un año</option>
                      </select>
                      <button
                        className="btn-search"
                        onClick={props.HandleSearch}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </div>
                  </div>
                  <div className="filter">
                    <div className="operation-filter">
                      <select ref={props.selectOperationRef} name="" id="">
                        <option value="default">Seleccione Operación</option>
                        <option value="Greater Than">Mayor que</option>
                        <option value="Less Than">Menor que</option>
                        <option value="Equal To">Igual que</option>
                      </select>
                      <input
                        type="text"
                        placeholder="0.00"
                        ref={props.inputOperationRef}
                      />
                      <button
                        className="btn-search"
                        onClick={props.HandleFilterByOperation}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="filter">
                    <div className="method-filter">
                      <label htmlFor="">Quién inicia: </label>
                      <select
                        ref={props.whoStartedRef}
                        onChange={props.HandleSelectEmployee}
                        name=""
                        id=""
                      >
                        <option value="default">- Select type -</option>
                        {employeeWhoStarted?.map((employee, index) => {
                          return (
                            <option key={index} value={employee}>
                              {employee}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="method-filter">
                      <label htmlFor="">Quién termina: </label>
                      <select
                        ref={props.whoClosedRef}
                        onChange={props.HandleSelectEmployee}
                        name=""
                        id=""
                      >
                        <option value="default">- Select type -</option>
                        {employeeWhoClosed?.map((employee, index) => {
                          return (
                            <option key={index} value={employee}>
                              {employee}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="filter">
                    <div className="search-filter">
                      <label htmlFor="">Por transacción:</label>
                      <input
                        type="text"
                        placeholder="transact #"
                        ref={props.transactRef}
                      />
                      <button
                        className="btn-search"
                        onClick={() => {
                          props.HandleTransactId(props.transactRef);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="business">
                <div className="line"></div>
                <h2>{businessName}</h2>
                <div className="multiple-branch">
                  <label htmlFor="">Cambiar sucursal: </label>
                  <BranchSelect />
                </div>
              </div>
            </div>
            <div className="clear-btn">
              <button onClick={props.clearFilters}>Limpiar filtros</button>
            </div>
            <FontAwesomeIcon
              onClick={HandleToggle}
              icon={faXmark}
              className="icon-xmark"
            />
          </>
        )}
      </div>
    </section>
  );
};

export default FiltersHeading;
