import { useState } from "react";

const useToggles = () => {
  const [toggles, setToggles] = useState({
    summary: false,
  });

  function HandleToggle(name) {
    return setToggles({...toggles, [name]: !toggles.summary });
  }

  return { toggles, HandleToggle };
};

export default useToggles;
