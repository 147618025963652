import { useState } from "react";

const useToggles = () => {
  const [toggles, setToggles] = useState({
    detail: false,
  });

  function HandleDetailToggle() {
    return setToggles({ ...toggles, detail: !toggles?.detail });
  }

  return { toggles, HandleDetailToggle };
};

export default useToggles;
