import React from "react";
import { formatDate } from "../../Transactions/utils/formatDate";
import { formatToDecimal } from "../../../../utils/formatToDecimal/formatToDecimal";

const TableContent = (props) => {
  const ncfData = props?.ncfData;

  return (
    <>
      <section className="ncf-section">
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th className="numeration-transact">No.</th>
                <th className="rnc-width">RNC/CED</th>
                {/* <th className="type-width">Tipo</th> */}
                <th className="ncf-width">NCF</th>
                <th className="ncf-width">NCF Ref</th>
                <th className="opendate-width">F. Comp</th>
                {/* <th className="refcomp-width">RefComp</th> */}
                <th className="nettotal-width">Neto</th>
                <th className="itbis-width">ITBIS</th>
                <th className="itbis-width">Ley</th>
                <th className="cash-width">Efectivo</th>
                <th className="cash-width">Cheque</th>
                <th className="cash-width">TC o ATH</th>
                <th className="cash-width">Credito</th>
                <th className="cash-width">Total</th>
                <th className="fiscalname-width">Razón Social</th>
                <th className="transact-width">Transact</th>
              </tr>
            </thead>
            <tbody>
              {ncfData?.map((data, index) => {
                return (
                  <tr
                    onClick={() => {
                      // props.HandleSelectedTransact(props?.ncfData, index);
                      // props.HandleDetailOrderToggle();
                    }}
                    className={data?.finaltotal !== 0 ? "" : " red"}
                    value={data?.transact}
                    key={index}
                  >
                    <td className="numeration-transact">
                      {(index + 1)}
                    </td>
                    <td className="rnc-width">{data?.rnc}</td>
                    {/* <td className="type-width">{data?.tipo}</td> */}
                    <td className="ncf-width">{data?.ncf}</td>
                    <td className="ncf-width">{data?.ncfref}</td>
                    <td className="opendate-width">
                      {formatDate(data?.opendate)}
                    </td>
                    {/* <td className="refcomp-width">
                    </td> */}
                    <td className="nettotal-width">
                      {formatToDecimal(data?.nettotal)}
                    </td>
                    <td className="itbis-width">
                      {formatToDecimal(data?.tax1)}
                    </td>
                    <td className="itbis-width">
                      {formatToDecimal(data?.tax5)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.efectivo)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.cheque)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.tarjeta)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.credito)}
                    </td>
                    <td className="cash-width">
                      {formatToDecimal(data?.finaltotal)}
                    </td>
                    <td className="fiscalname-width">{data?.fiscalname}</td>
                    <td className="transact-width">{data?.transact}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className=""></th>
                <th className="center">Total</th>
                <th className="nettotal-width">
                  {formatToDecimal(props.total?.nettotal)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.itbis)}
                </th>
                <th className="itbis-width">
                  {formatToDecimal(props.total?.tips)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.cash)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.cheque)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.card)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.credit)}
                </th>
                <th className="cash-width">
                  {formatToDecimal(props.total?.finaltotal)}
                </th>
                <th className=""></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </>
  );
};

export default TableContent;
