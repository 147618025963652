import { useState } from "react";

const defaultValue = {
  conteo: false,
  recepcion: false,
  isEdit: false,
  modalConfirmConteo: false,
  modalConfirmRecepcion: false
};

const useToggles = () => {
  const [toggles, setToggles] = useState(defaultValue);

  function HandleToggle(name, value, isEdit) {    
    if (name === "modalConfirmConteo") {
      return setToggles({
        ...defaultValue,
        conteo: true,
        modalConfirmConteo: true,
      });
    }       
    if (name === "modalConfirmRecepcion") {
      return setToggles({
        ...defaultValue,
        recepcion: true,
        modalConfirmRecepcion: true,
      });
    }       
    if (isEdit) {
      return setToggles({
        ...defaultValue,
        isEdit: true,
        [name]: value,
      });
    }

    return setToggles({ ...defaultValue, [name]: value });
  }

  function ResetToggles() {
    return setToggles(defaultValue)
  }
  return { toggles, HandleToggle, ResetToggles };
};

export default useToggles;
