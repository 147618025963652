import { useQuery } from "react-query";
import { Bar } from "react-chartjs-2";
import { dayAndMonthFormat } from "../../../../utils/dateFormat/dateFormat";
import useAuth from "../../../../hooks/useAuth";

const BASE_URL = process.env.REACT_APP_SERVER_DOMAIN;

function BarSalesCategoriesGraph(props) {
  const { auth } = useAuth();
   
  const token = auth?.token
  
  const endpoint = `${BASE_URL}/business/${props.businessId}/branch/${props.selectedBranch}/transactions/summary/places/firstdate/${props.from}/seconddate/${props.to}`

  const { data: placesData } = useQuery("placesData", () => {
    return fetch(
      endpoint,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      return res.json();
    });
  });

  if(!placesData) return []
  if(!placesData.length > 0) return []

  const data = {
    labels: placesData ? placesData.map((data) => data?.descript1) : [0],
    datasets: [
      {
        label: "Secciones",
        data: placesData ? placesData.map((data) => data?.nettotal) : [0],
        backgroundColor: "#84b6f4",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="bar-chart">
      <h4>
        Ventas del {dayAndMonthFormat(props.from)} al{" "}
        {dayAndMonthFormat(props.to)}
      </h4>
      <Bar className="chart" data={data} />
    </div>
  );
}

export default BarSalesCategoriesGraph;
