import useGetData from "../../../../hooks/useGetData";
import useAuth from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useGetInventoryConsume = ({ first_date, second_date, limit }) => {
  const { auth } = useAuth();
  const [search, setSearch] = useState(false);
  const [page, setPage] = useState(0);

  const isNullValue = !first_date || !second_date ? true : false;

  const businessId = auth?.isMultibusiness
    ? auth?.selectedBusinessOnlyId
    : auth?.business?.ID_NEGOCIO;

  const selectedBranch = auth?.selectedBranch;
  const endpoint = `/business/${businessId}/branch/${selectedBranch}/inventory/consume/firstdate/${first_date}/seconddate/${second_date}/page/${page}/limit/${limit}`;

  const { data, loading } = useGetData({
    url: endpoint,
    isNullValue: isNullValue,
    search: search,
  });
  
  function HandlePage(page) {
    return setPage(page), setSearch(true);
  }

  function HandleSearch() {
    if (isNullValue) {
      return toast.error("Fecha inválida");
    }
    return setSearch(true);
  }

  useEffect(() => {
    return setSearch(false);
  }, [data]);
  return { data, loading, HandleSearch, HandlePage };
};

export default useGetInventoryConsume;
